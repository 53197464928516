import styled from "styled-components";

import { Props } from "./Tag";

export const SelectTag = styled.select<Props>`
  background-color: ${({ colorTheme, theme }) => theme[colorTheme]};
  border-color: ${({ colorTheme, theme }) => theme[colorTheme]};
  color: ${({ theme }) => theme.white};
  display: flex;
  align-items: center;
  text-align: center;
  padding: 4px 8px;
  font-size: ${({ theme }) => theme.smallestFontSize};
  text-transform: uppercase;
  border-radius: ${({ theme }) => theme.borderRounded};
  width: fit-content;
  font-weight: 600;
  outline: none;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
`;
