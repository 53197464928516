import { FC } from "react";
import { useFormikContext } from "formik";
import styled from "styled-components";

import { Optional } from "@utils/types";
import { Checkbox } from "./Checkbox";

const Label = styled.label`
  font-size: ${({ theme }) => theme.smallFontSize};
  font-family: ${({ theme }) => theme.fontFamily};
  fotn-weight: 400;
`;

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  flex: 1 0 40%;
  margin: ${({ theme }) => theme.smallestMargin};

  label {
    margin-right: ${({ theme }) => theme.doubleMargin};
  }
`;

interface Props {
  name: string;
  options: {
    label: string;
    value: Optional<string | number | readonly string[]>;
  }[];
  id?: string;
}

export const CheckboxGroupField: FC<Props> = ({ name, options }) => {
  const { setFieldValue, values, setFieldTouched } = useFormikContext<any>();
  const value = values[name] ?? [];

  return (
    <Container>
      {options.map((option, index) => (
        <Item key={index}>
          <Checkbox
            checked={value.includes(option.value)}
            onChange={() => {
              setFieldTouched(name, true);
              if (value.includes(option.value)) {
                const newValue = value.filter(
                  (v: string | number) => v !== option.value
                );
                setFieldValue(name, newValue);
              } else {
                setFieldValue(name, [...value, option.value]);
              }
            }}
          />
          <Label children={option.label} />
        </Item>
      ))}
    </Container>
  );
};
