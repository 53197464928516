import { useEffect, useMemo, useState } from "react";

const SESSION_ITEM_NAME = "fullSelectedItems";

export function useFullStoredSelection<T extends { id: number }>(items: T[]) {
  const [selectedItems, setSelectedItems] = useState<T[]>(
    JSON.parse(sessionStorage.getItem(SESSION_ITEM_NAME) ?? "[]")
  );

  useEffect(() => {
    return () => {
      sessionStorage.removeItem(SESSION_ITEM_NAME);
    };
  }, []);

  useEffect(() => {
    sessionStorage.setItem(SESSION_ITEM_NAME, JSON.stringify(selectedItems));
  }, [selectedItems]);

  const allItemsSelected = useMemo(() => {
    return (
      items.length > 0 &&
      items.every((item) =>
        selectedItems.find((selectedItem) => selectedItem.id === item.id)
      )
    );
  }, [selectedItems, items]);

  return {
    selectedItems,
    setSelectedItems,
    allItemsSelected,
  };
}
