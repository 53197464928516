import CryptoJS from "crypto-js";
import Base64 from "crypto-js/enc-base64";
import sha256 from "crypto-js/sha256";

type WordArray = CryptoJS.lib.WordArray;

/**
 * Returns URL Safe b64 encoded string from an int8Array.
 * @param inputArr
 */
const urlEncodeArr = (inputArr: Uint32Array) => {
  return base64EncArr(inputArr)
    .replace(/=/g, "")
    .replace(/\+/g, "-")
    .replace(/\//g, "_");
};

/**
 * base64 url encode a string
 */
const base64URL = (value: WordArray) =>
  value
    .toString(Base64)
    .replace(/=/g, "")
    .replace(/\+/g, "-")
    .replace(/\//g, "_") as string;

/**
 * Base64 encode byte array
 * @param aBytes
 */
const base64EncArr = (aBytes: Uint32Array) => {
  const eqLen = (3 - (aBytes.length % 3)) % 3;
  let sB64Enc = "";
  for (
    let nMod3, nLen = aBytes.length, nUint24 = 0, nIdx = 0;
    nIdx < nLen;
    nIdx++
  ) {
    nMod3 = nIdx % 3;
    nUint24 |= aBytes[nIdx] << ((16 >>> nMod3) & 24);
    if (nMod3 === 2 || aBytes.length - nIdx === 1) {
      sB64Enc += String.fromCharCode(
        uint6ToB64((nUint24 >>> 18) & 63),
        uint6ToB64((nUint24 >>> 12) & 63),
        uint6ToB64((nUint24 >>> 6) & 63),
        uint6ToB64(nUint24 & 63)
      );
      nUint24 = 0;
    }
  }
  return eqLen === 0
    ? sB64Enc
    : sB64Enc.substring(0, sB64Enc.length - eqLen) + (eqLen === 1 ? "=" : "==");
};
/**
 * Base64 string to array encoding helper
 * @param nUint6
 */
const uint6ToB64 = (nUint6: number) => {
  return nUint6 < 26
    ? nUint6 + 65
    : nUint6 < 52
    ? nUint6 + 71
    : nUint6 < 62
    ? nUint6 - 4
    : nUint6 === 62
    ? 43
    : nUint6 === 63
    ? 47
    : 65;
};
/**
 * PKCEGenerator contains the functions to perform the Proof Key for Code Exchange by OAuth Public Clients.
 * See the RFC for more information: https://tools.ietf.org/html/rfc7636.
 */
export class PKCEGenerator {
  cryptoObj: Crypto;
  constructor() {
    this.cryptoObj = window.crypto;
  }
  /**
   * creates cryptographically random string
   */
  generateCodeVerifier = () => {
    const arrayBuffer = new Uint32Array(32);
    this.cryptoObj.getRandomValues(arrayBuffer);
    return urlEncodeArr(arrayBuffer);
  };
  generateCodeChallengeFromVerifier = (codeVerifier: string) =>
    base64URL(sha256(codeVerifier));
  /**
   * Generates PKCE Codes
   */
  generateCodes = () => {
    const codeVerifier = this.generateCodeVerifier();
    const codeChallenge = this.generateCodeChallengeFromVerifier(codeVerifier);
    return {
      codeVerifier,
      codeChallenge,
    };
  };
}
