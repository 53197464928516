import { ButtonHTMLAttributes } from "react";
import styled from "styled-components";

import { WithChildren } from "@utils";
import { Icon } from "../Icon";

export interface FileUploadProps
  extends WithChildren,
    Omit<ButtonHTMLAttributes<unknown>, "children"> {
  placeholder?: string;
  fileName?: string;
  onReset?: () => void;
}

const Container = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.darkestBlue};
  border: 1px solid ${({ theme }) => theme.secondaryColor};
  padding: ${({ theme }) => `${theme.doublePadding} ${theme.oneSixthPadding}`};
  border-radius: ${({ theme }) => theme.borderRadius};
  color: ${({ theme }) => theme.bodyColor};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.fontSize};
  width: 100%;
  box-sizing: border-box;

  ${({ disabled }) =>
    disabled &&
    `
    cursor: not-allowed;

    label, svg {
      cursor: not-allowed;
    }
  `}
`;

const ResetButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0 !important;

  svg {
    width: ${({ theme }) => theme.baseSize};
    fill: ${({ theme }) => theme.bodyColor};
    margin-left: 10px;
  }
`;

const UploadLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    width: ${({ theme }) => theme.baseSize};
    fill: ${({ theme }) => theme.bodyColor};
    color: ${({ theme }) => theme.bodyColor};
    margin-right: 10px;
  }
`;

export function ButtonFileUpload({
  placeholder,
  fileName,
  onReset,
  children,
  className,
  disabled,
}: FileUploadProps) {
  return (
    <Container className={className} disabled={disabled}>
      {!fileName && (
        <UploadLabel>
          <Icon.Paperclip />
          <span style={{ display: "none" }}>{children}</span>
        </UploadLabel>
      )}

      {fileName ? (
        <UploadLabel>
          <span>{fileName}</span>
          <ResetButton onClick={onReset} data-testid="Close">
            <Icon.Times />
          </ResetButton>
        </UploadLabel>
      ) : (
        placeholder && <span>{placeholder}</span>
      )}
    </Container>
  );
}
