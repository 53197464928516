import { FC, HTMLProps } from "react";
import { useField } from "formik";
import styled from "styled-components";

import { Asterisk } from "./Asterisk";
import { FormError } from "./FormError";
import { Label } from "./Label";
import { TextArea } from "./TextArea";

interface Props extends HTMLProps<HTMLTextAreaElement> {
  name: string;
  label?: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${({ theme }) => theme.smallestPadding};
  width: 100%;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TextAreaField: FC<Props> = ({
  name,
  label,
  id,
  placeholder,
  required,
  disabled,
}) => {
  const [field] = useField(name);

  return (
    <Container>
      {label && (
        <LabelContainer>
          <Label className="mb-6" htmlFor={id ?? name} children={label} />
          {required && <Asterisk />}
        </LabelContainer>
      )}
      <TextArea
        id={id ?? name}
        {...field}
        placeholder={placeholder}
        disabled={disabled}
      />
      <FormError name={name} />
    </Container>
  );
};
