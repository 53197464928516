import { FC, useCallback, useEffect, useState } from "react";
import { Form as BaseForm, Formik } from "formik";
import styled from "styled-components";

import { API } from "@api";
import { Accordion, Button, ButtonTheme, SelectField } from "@components";
import { DateField } from "@components/DateField";
import { useI18n } from "@i18n";
import {
  DisposalRequestStatus,
  getCityName,
  getColorAndLabelFromDisposalRequestStatus,
  ICity,
  ICountry,
} from "@utils";

export interface Props {
  initialValues: IRequestsFilters;
  onClose: () => void;
  onApply: (values: IRequestsFilters) => void;
  onClear: () => void;
  shouldFilterByStatus?: boolean;
}

export interface IRequestsFilters {
  status?: DisposalRequestStatus[];
  startDate?: string;
  endDate?: string;
  country?: number;
  location?: number;
}

const Form = styled(BaseForm)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .main {
    overflow: scroll;
  }
`;

const Message = styled.span`
  margin-bottom: ${({ theme }) => theme.smallestMargin};
  color: ${({ theme }) => theme.lighterGrey};
  font-size: ${({ theme }) => theme.smallFontSize};
`;

export const FilterRequestsForm: FC<Props> = ({
  initialValues,
  onClose,
  onClear,
  onApply,
  shouldFilterByStatus,
}) => {
  const { t } = useI18n();

  const [cities, setCities] = useState<ICity[]>([]);
  const [countries, setCountries] = useState<ICountry[]>([]);

  const isCitiesFieldDisabled = useCallback(
    (values: IRequestsFilters) =>
      !Boolean(values.country) || cities.length === 0,
    [cities]
  );

  useEffect(() => {
    async function getCountries() {
      try {
        const countries = await API.users.countries();
        setCountries(countries);
      } catch (_) {
        setCountries([]);
      }
    }

    getCountries();
  }, []);

  const handleChange = async (value: number) => {
    try {
      const cities = await API.users.citiesByCountry(value);
      setCities(cities);
    } catch (_) {
      setCities([]);
    }
  };

  const handleSubmit = useCallback(
    (values) => {
      onApply(values);
      onClose();
    },
    [onApply, onClose]
  );

  return (
    <Formik<IRequestsFilters>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        handleSubmit,
        handleChange: onChange,
        values,
        setFieldValue,
        resetForm,
        setValues,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="header">
            <span>{t("filters.by")}</span>
            <span className="close" onClick={onClose}>
              &#10005;
            </span>
          </div>
          <div className="main">
            {shouldFilterByStatus && (
              <Accordion title={t("filters.disposalRequests.status")}>
                <SelectField
                  name="status"
                  placeholder={t("filters.disposalRequests.status")}
                  options={Object.values(DisposalRequestStatus)
                    .filter((value) => typeof value !== "string")
                    .map((status) => ({
                      label: t(
                        getColorAndLabelFromDisposalRequestStatus(
                          status as DisposalRequestStatus
                        ).label
                      ),
                      value: status,
                    }))}
                  multiple
                />
              </Accordion>
            )}
            <Accordion title={t("filters.disposalRequests.date")}>
              <DateField
                name="startDate"
                label={t("filters.disposalRequests.dateFrom")}
                placeholder={t("filters.disposalRequests.dateFrom")}
              />
              <DateField
                name="endDate"
                label={t("filters.disposalRequests.dateTo")}
                placeholder={t("filters.disposalRequests.dateTo")}
              />
            </Accordion>
            <Accordion title={t("filters.disposalRequests.country")}>
              <SelectField
                name="country"
                placeholder={t("filters.disposalRequests.country")}
                options={countries.map((country) => ({
                  label: country.name,
                  value: country.geoname_id,
                }))}
                onChange={(event) => {
                  setFieldValue("location", "");
                  onChange(event);
                  handleChange(Number(event.target.value));
                }}
              />
            </Accordion>
            <Accordion title={t("filters.disposalRequests.city")}>
              {isCitiesFieldDisabled(values) && (
                <Message>{t("filters.disposalRequests.selectCountry")}</Message>
              )}
              <SelectField
                name="location"
                placeholder={t("filters.disposalRequests.city")}
                options={cities.map((city) => ({
                  label: getCityName(city),
                  value: city.geoname_id,
                }))}
                disabled={isCitiesFieldDisabled(values)}
              />
            </Accordion>
          </div>
          <div className="footer">
            <Button
              type="button"
              className="bordered"
              theme={ButtonTheme.Secondary}
              onClick={() => {
                const defaultValues = {
                  country: undefined,
                  date: undefined,
                  location: undefined,
                  status: undefined,
                };
                resetForm();
                setValues(defaultValues);
                onApply(defaultValues);
                onClose();
                onClear();
              }}
            >
              {t<string>("filters.clear")}
            </Button>
            <Button
              type="submit"
              theme={ButtonTheme.Secondary}
              onClick={() => null}
            >
              {t<string>("filters.apply")}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
