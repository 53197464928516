export function removeEmptyValues(obj: Record<string, any>) {
  Object.entries(obj).forEach(([key, value]) => {
    if (typeof value !== "number" && typeof value !== "boolean" && !value)
      delete obj[key];
    if (value && typeof value === "object") {
      if (Array.isArray(value)) return;
      if (Object.keys(value).length === 0) {
        delete obj[key];
      } else {
        removeEmptyValues(value);
      }
    }
  });
}
