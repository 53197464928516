import React, { ReactNode, useMemo } from "react";
import styled from "styled-components";

import { Loader } from "@components";
import { useI18n } from "@i18n";
import { DraggableRow } from "./DraggableRow";

interface IHeader {
  element: ReactNode | string;
  visible?: boolean;
  align?: "center" | "end" | "start";
  fixed?: boolean;
}
export type TableTheme = "white" | "blue" | "dark";
export interface Props<T extends { id: number }> {
  headers: IHeader[];
  rows: T[];
  itemSelector: (row: T, column: typeof Column) => ReactNode;
  groupSelector?: (row: T, column: typeof Column) => ReactNode;
  isGroupVisible?: Record<number, boolean>;
  theme?: TableTheme;
  loading?: boolean;
  withoutMargin?: boolean;
  draggable?: boolean;
  onDragEnd?: (item: T) => void;
  lastColumnsSticky?: boolean;
}
interface StyledTable {
  tableTheme: TableTheme;
  lastColumnsSticky?: boolean;
}

const OverflowX = styled.div<{
  tableTheme: TableTheme;
  withoutMargin?: boolean;
}>`
  overflow-x: scroll;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.casalColor};
  margin-bottom: ${({ theme }) => theme.tripleMargin};
  box-sizing: border-box;

  ${({ tableTheme }) => tableTheme === "white" && "border:0"};

  ${({ withoutMargin }) =>
    withoutMargin &&
    `
    margin: 0;
  `}
`;

const TableWrapper = styled.table<StyledTable>`
  color: ${({ theme, tableTheme }) =>
    tableTheme === "white" ? theme.lighterBlack : theme.bodyColor};
  width: 100%;
  background-color: ${({ theme, tableTheme }) => {
    switch (tableTheme) {
      case "dark":
        return theme.tertiaryBlue;
      case "blue":
        return theme.lightestBlue;
      case "white":
        return "white";
    }
  }};
  border-collapse: collapse;
  border-spacing: 0;

  td {
    font-size: ${({ theme, tableTheme }) =>
      tableTheme === "white" ? theme.smallFontSize : theme.fontSize};
  }
  tr {
    th:last-child {
      position: ${({ lastColumnsSticky }) => lastColumnsSticky && "sticky"};
      right: ${({ lastColumnsSticky }) => lastColumnsSticky && 0};
      background-color: ${({ lastColumnsSticky }) =>
        lastColumnsSticky && "#012030"};
    }
    th:nth-last-child(2) {
      position: ${({ lastColumnsSticky }) => lastColumnsSticky && "sticky"};
      right: ${({ lastColumnsSticky }) => lastColumnsSticky && "49px"};
      background-color: ${({ lastColumnsSticky }) =>
        lastColumnsSticky && "#012030"};
    }
    td:last-child {
      position: ${({ lastColumnsSticky }) => lastColumnsSticky && "sticky"};
      right: ${({ lastColumnsSticky }) => lastColumnsSticky && 0};
      background-color: ${({ lastColumnsSticky }) =>
        lastColumnsSticky && "#285489"};
    }
    td:nth-last-child(2) {
      position: ${({ lastColumnsSticky }) => lastColumnsSticky && "sticky"};
      right: ${({ lastColumnsSticky }) => lastColumnsSticky && "49px"};
      background-color: ${({ lastColumnsSticky }) =>
        lastColumnsSticky && "#285489"};
    }
  }
`;

const Header = styled.thead<StyledTable>`
  font-size: ${({ theme }) => theme.smallestFontSize};
  font-weight: 400;
  background-color: ${({ theme, tableTheme }) =>
    tableTheme === "white" ? theme.lightestGrey : theme.secondaryBlue};
  padding: ${({ theme }) => theme.doublePadding};
  text-transform: uppercase;
`;

const RowHeader = styled.th<{
  fixed?: boolean;
  textAlign?: "center" | "end" | "start";
}>`
  padding: ${({ theme }) => theme.doublePadding};
  text-align: left;

  ${({ textAlign }) => textAlign && `text-align: ${textAlign}`};

  ${({ fixed, theme }) =>
    fixed &&
    `
    background-color: ${theme.secondaryBlue};
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    right: 0;
    z-index: 2;
    box-shadow: inset 2px 0 0 ${theme.casalColor};
  `}
`;

export const Row = styled.tr<StyledTable & { draggable?: boolean }>`
  background-color: ${({ theme, tableTheme }) => {
    switch (tableTheme) {
      case "dark":
        return theme.tertiaryBlue;
      case "blue":
        return theme.lightestBlue;
      case "white":
        return "white";
    }
  }};
  border-top: 1px solid
    ${({ theme, tableTheme }) => {
      switch (tableTheme) {
        case "dark":
          return theme.casalColor;
        case "blue":
          return theme.darkBlue;
        case "white":
          return theme.lighterGrey;
      }
    }};

  ${({ draggable }) => draggable && "cursor: pointer"}
`;

const NoItems = styled(Row)`
  justify-content: center;

  td {
    text-align: center;
    padding: ${({ theme }) => theme.doublePadding};
  }
`;

const Column = styled.td<{
  fixed?: boolean;
  textAlign?: "center" | "end" | "start";
  direction?: "row" | "column";
  full?: boolean;
  wrapColumn?: boolean;
}>`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: ${({ theme }) => theme.doublePadding};
  width: ${({ full }) => (full ? "100%" : "auto")};

  ${({ textAlign }) => textAlign && `text-align: ${textAlign}`};

  ${({ direction }) =>
    direction === "row" &&
    `
    display: flex;
    align-items:center;
  `}

  ${({ fixed, theme }) =>
    fixed &&
    `
    background-color: ${theme.tertiaryBlue};
    position: -webkit-sticky;
    position: sticky;
    right: 0;
    z-index: 2;
    box-shadow: inset 2px 0 0 ${theme.casalColor};
  `}

  ${({ wrapColumn }) =>
    wrapColumn &&
    `max-width: 350px;
     white-space: normal;
    `}
`;

const ColumnGroup = styled.td<{
  align?: "center" | "end" | "start";
  full?: boolean;
}>`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: ${({ theme }) => theme.doublePadding};
  width: ${({ full }) => (full ? "100%" : "auto")};
  background-color: ${({ theme }) => theme.secondaryBlue};
`;

export function Table<T extends { id: number }>({
  headers,
  rows,
  itemSelector,
  groupSelector,
  isGroupVisible,
  theme = "dark",
  loading,
  withoutMargin,
  draggable,
  onDragEnd,
  lastColumnsSticky,
}: Props<T>) {
  const { t } = useI18n();

  const visibleHeaders = useMemo(
    () => headers.filter((header) => header.visible !== false),
    [headers]
  );

  return (
    <OverflowX withoutMargin={withoutMargin} tableTheme={theme}>
      <TableWrapper tableTheme={theme} lastColumnsSticky={lastColumnsSticky}>
        <Header tableTheme={theme}>
          <tr>
            {visibleHeaders.map((header, index) => (
              <RowHeader
                key={index}
                fixed={header.fixed}
                textAlign={header.align}
              >
                {header.element}
              </RowHeader>
            ))}
          </tr>
        </Header>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan={visibleHeaders.length}>
                <Loader size="big" data-testid="Loader" />
              </td>
            </tr>
          ) : (
            <>
              {rows.map((row) => (
                <React.Fragment key={row.id}>
                  {draggable ? (
                    <DraggableRow<T> item={row} onDragEnd={onDragEnd}>
                      {itemSelector(row, Column)}
                    </DraggableRow>
                  ) : (
                    <Row tableTheme={theme}>{itemSelector(row, Column)}</Row>
                  )}
                  {groupSelector && isGroupVisible?.[row.id] && (
                    <Row tableTheme={theme}>
                      {groupSelector(row, ColumnGroup)}
                    </Row>
                  )}
                </React.Fragment>
              ))}
              {rows.length === 0 && (
                <NoItems tableTheme={theme}>
                  <td colSpan={visibleHeaders.length}>{t("noItems")}</td>
                </NoItems>
              )}
            </>
          )}
        </tbody>
      </TableWrapper>
    </OverflowX>
  );
}
