import { FC } from "react";
import { ThemeProvider } from "styled-components";

const BASE_SIZE = "16px";

export const DEFAULT_THEME = {
  fontFamily: "Open Sans",
  borderRadius: "4px",
  borderRounded: "999px",
  borderRadiusHalf: "50%",
  headerHeight: "52px",
  navHeight: "40px",
  baseSize: BASE_SIZE,
  fontSize: BASE_SIZE,
  smallFontSize: "14px",
  smallestFontSize: "12px",
  mediumFontSize: "18px",
  mediumBigFontSize: "20px",
  bigFontSize: "22px",
  biggerFontSize: "26px",
  smallestMargin: "6px",
  smallMargin: "8px",
  doubleMargin: "12px",
  mediumMargin: "14px",
  mediumBigMargin: "20px",
  oneSixthMargin: "16px",
  tripleMargin: "24px",
  bigMargin: "40px",
  biggerMargin: "64px",
  smallestPadding: "6px",
  smallPadding: "8px",
  doublePadding: "12px",
  mediumPadding: "14px",
  mediumBigPadding: "20px",
  oneSixthPadding: "16px",
  triplePadding: "24px",
  bigPadding: "40px",
  biggerPadding: "62px",
  bodyColor: "white",
  primaryColor: "#2AA277",
  secondaryColor: "#158EC2",
  tertiaryColor: "#A45206",
  quaternaryColor: "#7F043F",
  casalColor: "#305d75",
  darkBlue: "#023050",
  opacityBlue: "rgba(6, 39, 56, 0.7)",
  darkestBlue: "#062738",
  primaryBlue: "#0A3F5A",
  secondaryBlue: "#012030",
  tertiaryBlue: "#073349",
  quaternaryBlue: "#095D93",
  lightestBlue: "#285489",
  white: "#FFFFFF",
  grey: "#96A1A8",
  lighterGrey: "#C3D1DA",
  lightestGrey: "#EFF2F3",
  green: "#20992E",
  yellow: "#C39109",
  red: "#C10000",
  black: "#000",
  orange: "#E2730C",
  lightBlue: "#0063F6",
  lighterBlack: "#202B30",
};

export type IContextTheme = {
  contextTheme: {
    color: string;
    activeTabColor: string;
  };
};
export type ITheme = typeof DEFAULT_THEME & IContextTheme;

const Theme: FC<IContextTheme> = ({ children, contextTheme }) => (
  <ThemeProvider theme={{ ...DEFAULT_THEME, contextTheme }}>
    {children}
  </ThemeProvider>
);

export default Theme;
