import Axios from "axios";
import qs from "qs";

import {
  FileType,
  IDisposalRequest,
  IDisposalRequestWithHistory,
  IPaginatedResponse,
} from "@utils";
import { CreateDisposalRequestDto } from "./dto/CreateDisposalRequestDto.interface";
import * as files from "./files";

export async function create({
  file,
  description,
  city,
}: CreateDisposalRequestDto) {
  const dataToUpload = await files.upload(FileType.DisposalRequest);

  await files.awsUpload(dataToUpload, file);

  const response = await Axios.post<IDisposalRequest>(
    "/api/disposal-requests/",
    {
      description: description.trim() !== "" ? description : undefined,
      url: dataToUpload.url,
      key: dataToUpload.fields.key,
      location_geoname_id: city,
    }
  );

  return response.data;
}

export async function get(
  page: number,
  status?: string,
  startDate?: string,
  endDate?: string,
  country?: number,
  location?: number
) {
  const response = await Axios.get<IPaginatedResponse<IDisposalRequest>>(
    "/api/disposal-requests/",
    {
      params: {
        page,
        status: status
          ? status.split(",").map((value) => Number(value))
          : undefined,
        start_date: startDate,
        end_date: endDate,
        country,
        location,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }
  );
  return response.data;
}

export async function byId(id: number) {
  const response = await Axios.get<IDisposalRequestWithHistory>(
    `/api/disposal-requests/${id}/`
  );
  return response.data;
}

export async function updateFile(
  id: number,
  url: string,
  key: string,
  message?: string
) {
  const response = await Axios.put<IDisposalRequestWithHistory>(
    `/api/disposal-requests/${id}/update-file/`,
    {
      url,
      key,
      message,
    }
  );
  return response.data;
}

export async function approve(id: number, message?: string) {
  const response = await Axios.put<IDisposalRequestWithHistory>(
    `/api/disposal-requests/${id}/approve/`,
    { message }
  );
  return response.data;
}

export async function block(id: number, reason: string) {
  const response = await Axios.put<IDisposalRequestWithHistory>(
    `/api/disposal-requests/${id}/block/`,
    { reason }
  );
  return response.data;
}

export async function inProgress(id: number, message?: string) {
  const response = await Axios.put<IDisposalRequestWithHistory>(
    `/api/disposal-requests/${id}/in-progress/`,
    { message }
  );
  return response.data;
}
