import axios from "axios";
import jwt from "jsonwebtoken";

import { Nullable } from "@utils";
import { LOCAL_STORAGE_KEY as CONTEXT_LOCAL_STORAGE_KEY } from "./AuthenticationContext";
import { PKCEGenerator } from "./pkce";

/**
 * CLIENT_ID is the CIAM trusted ID
 */
const CLIENT_ID = process.env.REACT_APP_CIAM_CLIENT_KEY;

/**
 * REDIRECT_URI is the CIAM redirect uri once the login is completed
 */
export const REDIRECT_URI = process.env.REACT_APP_CIAM_REDIRECT_URL;

/**
 * REDIRECT_HOMEPAGE is the CIAM redirect homepage once the login is completed
 */

export const REDIRECT_HOMEPAGE = process.env.REACT_APP_FRONTEND_URL;

const BASE_URL = process.env.REACT_APP_CIAM_BASE_URL;

const LOCAL_STORAGE_KEY = "wfp-gas/ciam";

/**
 * authUrl is the endpoint where the user will perform the login
 */

const authUrl = `${BASE_URL}oauth2/authorize`;

const tokenUrl = `${BASE_URL}oauth2/token`;

const logoutUrl = `${BASE_URL}oidc/logout`;

export const getLoginURL = (codeChallenge: string) =>
  `${authUrl}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}` +
  `&redirect_homepage=${REDIRECT_HOMEPAGE}&scope=openid` +
  `&response_type=code&code_challenge_method=S256&code_challenge=${codeChallenge}`;

type Session = {
  codeVerifier?: string;
};

type TokenResponse = {
  refresh_token: string;
  id_token: string;
  email: string;
};

const getCurrentSession: () => Nullable<Session> = () =>
  JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) ?? "null");

const setCurrentSession = (session: Session) => {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(session));
};

const headers = {
  "Content-Type": "application/x-www-form-urlencoded",
};

const getDataFromJwt = (token: string) => jwt.decode(token, { json: true })!;

/**
 * retrieveTokens()
 * based on the passed-in url and codeVerifier
 * which are the parameters needed to close the PKCE flow
 * returns `access_token`.
 */

export const retrieveTokens = (code: string) => {
  const session = getCurrentSession() ?? {};
  const codeVerifier = session?.codeVerifier;

  if (!codeVerifier) throw new Error("Missing Verification Code");

  const params = new URLSearchParams();
  params.append("client_id", CLIENT_ID!);
  params.append("grant_type", "authorization_code");
  params.append("redirect_uri", REDIRECT_URI!);
  params.append("code", code);
  params.append("code_verifier", codeVerifier);

  return axios
    .post<TokenResponse>(tokenUrl, params, { headers })
    .then((response) => {
      const { refresh_token, id_token } = response.data;
      const { sub } = getDataFromJwt(id_token);

      if (!sub) {
        return clearAndRedirectToLogin(new Error("No valid token"));
      }

      return {
        id_token,
        refresh_token,
        email: sub,
      };
    })
    .catch((error) => {
      console.error(error);
      return clearAndRedirectToLogin(error);
    })
    .finally(() => {
      delete session.codeVerifier;
      setCurrentSession(session);
    });
};

/**
 * onCiamLogin()
 * starts login flow with CIAM by opening a new window where the user
 * will perform the login or registration.
 */
export const onCiamLogin = () => {
  const session = getCurrentSession() ?? {};

  const pkceObj = new PKCEGenerator();
  const { codeVerifier, codeChallenge } = pkceObj.generateCodes();

  session.codeVerifier = codeVerifier;
  setCurrentSession(session);

  const loginUrl = getLoginURL(codeChallenge);
  window.location.href = loginUrl;
};

export const clearAndRedirectToLogin = (error: Error) => {
  localStorage.removeItem(LOCAL_STORAGE_KEY);
  localStorage.removeItem(CONTEXT_LOCAL_STORAGE_KEY);
  window.location.href = "/";
  return Promise.reject(error);
};

/*
 * refreshAccessToken()
 * based on passed-in:
 * `refreshToken`: refresh token.
 *
 * performs access token refresh and request retry.
 */
export const refreshAccessToken = (
  refreshToken: string
): Promise<TokenResponse> => {
  const params = new URLSearchParams();
  params.append("client_id", CLIENT_ID!);
  params.append("grant_type", "refresh_token");
  params.append("refresh_token", refreshToken!);

  return axios
    .post(tokenUrl, params, { headers })
    .then((response) => {
      const { refresh_token, id_token } = response.data;
      const { sub } = getDataFromJwt(id_token);

      if (!sub) {
        return clearAndRedirectToLogin(new Error("No valid token"));
      }

      return {
        id_token,
        refresh_token,
        email: sub,
      };
    })
    .catch((error) => {
      return clearAndRedirectToLogin(error);
    });
};

export const logout = () => {
  window.location.href = `${logoutUrl}?post_logout_redirect_uri=${REDIRECT_HOMEPAGE}`;
};
