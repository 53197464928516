import Axios from "axios";

import { Nullable } from "@utils";

enum PageType {
  Home = "home.HomePage",
  Info = "info.InfoPage",
}

interface Page {
  id: number;
  meta: {
    type: PageType;
    detail_url: string;
    html_url: string;
    first_published_at: Nullable<string>;
    slug: string;
  };
  title: string;
}

interface Pages {
  items: Page[];
  meta: { total_count: number };
}

export async function whyToDisposeLayout() {
  try {
    const response = await Axios.get<Pages>(
      `${process.env.REACT_APP_CMS_URL}/api/v2/pages/`
    );

    const whyToDisposePage = response.data.items.find(
      (page) => page.meta.slug === "why-to-dispose"
    );

    const detailUrl = whyToDisposePage?.meta.detail_url.replace(
      "http://localhost",
      process.env.REACT_APP_CMS_URL ?? ""
    );

    const details = (await Axios.get(detailUrl ?? "")).data;
    const layout = await Axios.get<string>(
      `${process.env.REACT_APP_CMS_URL}/why-to-dispose/`
    );

    return {
      html: layout.data,
      heading: {
        buttonText: details.button_text,
        buttonUrl: details.button_url,
        contentTitle: details.content_title,
      },
    };
  } catch (error) {
    // TODO: Handle errors
  }
}
