import {
  Children,
  FC,
  MutableRefObject,
  ReactNode,
  RefObject,
  useMemo,
  useState,
} from "react";
import styled from "styled-components";

import { device } from "@styles/breakpoints";

export interface Props {
  headers: (string | { label: string; disabled: boolean })[];
  actions?: ReactNode;
  activeTabIndex?: number;
  width?: string;
  onTabClick?: (activeTabIndex: number) => void;
  tabsRef?: RefObject<any>;
}

const Container = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width || "auto"};
  height: 100%;
`;

const FullHeight = styled.div`
  height: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  @media ${device.laptopM} {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`;

const TabsContainer = styled.div`
  display: flex;

  @media ${device.mobileL} {
    flex-direction: column;
    width: 100%;
  }
`;

const ActionsContainer = styled(TabsContainer)`
  padding-bottom: ${({ theme }) => theme.smallPadding};
`;

const Header = styled.div<{ disabled?: boolean }>`
  padding: ${({ theme }) => `${theme.smallPadding} ${theme.triplePadding}`};
  font-weight: 600;
  color: ${({ theme }) => theme.grey};
  border-top-left-radius: ${({ theme }) => theme.borderRadius};
  border-top-right-radius: ${({ theme }) => theme.borderRadius};
  border: 1px solid ${({ theme }) => theme.casalColor};
  border-bottom: none;
  background-color: ${({ theme }) => theme.darkestBlue};
  cursor: pointer;

  @media ${device.mobileL} {
    border-radius: 0;

    &:first-child {
      border-top-left-radius: ${({ theme }) => theme.borderRadius};
      border-top-right-radius: ${({ theme }) => theme.borderRadius};
    }
  }

  &.active {
    background-color: ${({ theme }) => theme.contextTheme.activeTabColor};
    border-color: ${({ theme }) => theme.contextTheme.activeTabColor};
    color: white;
  }

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.8;
    cursor: not-allowed;
  `}
`;

export const Tabs: FC<Props> = ({
  headers,
  actions,
  children,
  activeTabIndex = 0,
  width,
  onTabClick,
  tabsRef,
}) => {
  const [activeIndex, setActiveIndex] = useState(activeTabIndex);

  const visibleChild = useMemo(
    () =>
      Children.toArray(children).filter((_, index) => index === activeIndex),
    [activeIndex, children]
  );

  return (
    <Container width={width}>
      <HeaderContainer>
        <TabsContainer ref={tabsRef}>
          {headers.map((header, index) => (
            <Header
              key={index}
              className={activeIndex === index ? "active" : ""}
              onClick={() => {
                if (typeof header !== "string" && header.disabled) return;
                setActiveIndex(index);
                onTabClick?.(index);
              }}
              disabled={typeof header === "string" ? false : header.disabled}
            >
              {typeof header === "string" ? header : header.label}
            </Header>
          ))}
        </TabsContainer>
        {actions && <ActionsContainer>{actions}</ActionsContainer>}
      </HeaderContainer>
      <FullHeight>{visibleChild}</FullHeight>
    </Container>
  );
};
