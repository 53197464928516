import { FC, useCallback } from "react";
import { Form as BaseForm, Formik } from "formik";
import styled from "styled-components";

import {
  Accordion,
  Button,
  ButtonTheme,
  NumberField,
  TextField,
} from "@components";
import { useI18n } from "@i18n";

export interface Props {
  initialValues: ILotsFilters;
  onClose: () => void;
  onApply: (values: ILotsFilters) => void;
  onClear: () => void;
}

export interface ILotsFilters {
  numberFrom?: string;
  numberTo?: string;
  reservePriceFrom?: number;
  reservePriceTo?: number;
  acquisitionValueFrom?: number;
  acquisitionValueTo?: number;
  marketValueFrom?: number;
  marketValueTo?: number;
}

const Form = styled(BaseForm)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .main {
    overflow: scroll;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > * {
    margin-right: ${({ theme }) => theme.smallMargin};

    :last-child {
      margin-right: 0;
    }
  }
`;

export const FilterLotsForm: FC<Props> = ({
  initialValues,
  onClose,
  onClear,
  onApply,
}) => {
  const { t } = useI18n();

  const handleSubmit = useCallback(
    (values) => {
      onApply(values);
      onClose();
    },
    [onApply, onClose]
  );

  return (
    <Formik<ILotsFilters>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ handleSubmit, resetForm, setValues }) => (
        <Form onSubmit={handleSubmit}>
          <div className="main">
            <Accordion
              title={t(
                "pages.auctionDetails.assetsAndLots.filters.lots.reservePrice"
              )}
            >
              <Row>
                <NumberField
                  name="reservePriceFrom"
                  placeholder={t(
                    "pages.auctionDetails.assetsAndLots.filters.lots.from"
                  )}
                />
                <NumberField
                  name="reservePriceTo"
                  placeholder={t(
                    "pages.auctionDetails.assetsAndLots.filters.lots.to"
                  )}
                />
              </Row>
            </Accordion>
            <Accordion
              title={t(
                "pages.auctionDetails.assetsAndLots.filters.lots.acquisitionValue"
              )}
            >
              <Row>
                <NumberField
                  name="acquisitionValueFrom"
                  placeholder={t(
                    "pages.auctionDetails.assetsAndLots.filters.lots.from"
                  )}
                />
                <NumberField
                  name="acquisitionValueTo"
                  placeholder={t(
                    "pages.auctionDetails.assetsAndLots.filters.lots.to"
                  )}
                />
              </Row>
            </Accordion>
            <Accordion
              title={t(
                "pages.auctionDetails.assetsAndLots.filters.lots.marketValue"
              )}
            >
              <Row>
                <NumberField
                  name="marketValueFrom"
                  placeholder={t(
                    "pages.auctionDetails.assetsAndLots.filters.lots.from"
                  )}
                />
                <NumberField
                  name="marketValueTo"
                  placeholder={t(
                    "pages.auctionDetails.assetsAndLots.filters.lots.to"
                  )}
                />
              </Row>
            </Accordion>
            <Accordion
              title={t(
                "pages.auctionDetails.assetsAndLots.filters.lots.number"
              )}
            >
              <Row>
                <TextField
                  name="numberFrom"
                  placeholder={t(
                    "pages.auctionDetails.assetsAndLots.filters.lots.from"
                  )}
                />
                <TextField
                  name="numberTo"
                  placeholder={t(
                    "pages.auctionDetails.assetsAndLots.filters.lots.to"
                  )}
                />
              </Row>
            </Accordion>
          </div>
          <div className="footer">
            <Button
              type="button"
              className="bordered"
              theme={ButtonTheme.Secondary}
              onClick={() => {
                const defaultValues: ILotsFilters = {
                  acquisitionValueFrom: undefined,
                  acquisitionValueTo: undefined,
                  marketValueFrom: undefined,
                  marketValueTo: undefined,
                  numberFrom: undefined,
                  numberTo: undefined,
                  reservePriceFrom: undefined,
                  reservePriceTo: undefined,
                };
                resetForm();
                setValues(defaultValues);
                onApply(defaultValues);
                onClose();
                onClear();
              }}
            >
              {t<string>("filters.clear")}
            </Button>
            <Button
              type="submit"
              theme={ButtonTheme.Secondary}
              onClick={() => null}
            >
              {t<string>("filters.apply")}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
