import { ComponentType } from "react";
import { useTranslation } from "react-i18next";
import { ErrorMessage as FormikErrorMessage } from "formik";
import styled from "styled-components";

import { ErrorMessage } from "@utils";

interface Props {
  name: string;
}

const Span = styled.span`
  margin-top: ${({ theme }) => theme.smallestPadding};
  display: inline-flex;
  align-items: center;
  color: ${({ theme }) => theme.red};
  font-size: ${({ theme }) => theme.smallFontSize};
`;

export function ErrorRenderer({ children }: { children: ErrorMessage }) {
  const { t } = useTranslation();

  return (
    <Span data-testid="FormError">
      {typeof children === "string"
        ? t(children)
        : t(children.key, children.variables)}
    </Span>
  );
}

export function FormError({ name }: Props) {
  return (
    <FormikErrorMessage
      name={name}
      component={ErrorRenderer as ComponentType}
    />
  );
}
