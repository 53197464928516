import { DEFAULT_PAGE_SIZE, Paginated } from "@utils";

export interface Data {
  id: number;
  date: Date;
  country: string;
  city: string;
  totalItems: number;
  totalLots: number;
  status: "done" | "todo";
}

const DATA: Data[] = [
  {
    id: 1,
    date: new Date("2021-10-22"),
    country: "1",
    city: "Saclepea",
    totalItems: 234,
    totalLots: 40,
    status: "todo",
  },
  {
    id: 2,
    date: new Date("2021-10-22"),
    country: "2",
    city: "Saclepea",
    totalItems: 234,
    totalLots: 40,
    status: "todo",
  },
  {
    id: 3,
    date: new Date("2021-10-22"),
    country: "3",
    city: "Saclepea",
    totalItems: 234,
    totalLots: 40,
    status: "todo",
  },
  {
    id: 4,
    date: new Date("2021-10-22"),
    country: "4",
    city: "Saclepea",
    totalItems: 234,
    totalLots: 40,
    status: "todo",
  },
  {
    id: 5,
    date: new Date("2021-10-22"),
    country: "5",
    city: "Saclepea",
    totalItems: 234,
    totalLots: 40,
    status: "done",
  },
  {
    id: 6,
    date: new Date("2021-10-22"),
    country: "6",
    city: "Saclepea",
    totalItems: 234,
    totalLots: 40,
    status: "done",
  },
  {
    id: 7,
    date: new Date("2021-10-22"),
    country: "7",
    city: "Saclepea",
    totalItems: 234,
    totalLots: 40,
    status: "done",
  },
  {
    id: 8,
    date: new Date("2021-10-22"),
    country: "8",
    city: "Saclepea",
    totalItems: 234,
    totalLots: 40,
    status: "done",
  },
  {
    id: 9,
    date: new Date("2021-10-22"),
    country: "9",
    city: "Saclepea",
    totalItems: 234,
    totalLots: 40,
    status: "done",
  },
  {
    id: 10,
    date: new Date("2021-10-22"),
    country: "10",
    city: "Saclepea",
    totalItems: 234,
    totalLots: 40,
    status: "done",
  },
  {
    id: 11,
    date: new Date("2021-10-22"),
    country: "11",
    city: "Saclepea",
    totalItems: 234,
    totalLots: 40,
    status: "done",
  },
  {
    id: 12,
    date: new Date("2021-10-22"),
    country: "12",
    city: "Saclepea",
    totalItems: 234,
    totalLots: 40,
    status: "done",
  },
  {
    id: 13,
    date: new Date("2021-10-22"),
    country: "13",
    city: "Saclepea",
    totalItems: 234,
    totalLots: 40,
    status: "done",
  },
  {
    id: 14,
    date: new Date("2021-10-22"),
    country: "14",
    city: "Saclepea",
    totalItems: 234,
    totalLots: 40,
    status: "done",
  },
  {
    id: 15,
    date: new Date("2021-09-22"),
    country: "Libia",
    city: "Saclepea",
    totalItems: 234,
    totalLots: 40,
    status: "todo",
  },
  {
    id: 16,
    date: new Date("2021-09-22"),
    country: "Libia",
    city: "Saclepea",
    totalItems: 234,
    totalLots: 40,
    status: "todo",
  },
];

export async function get(
  page: number,
  month: number,
  year: number
): Promise<Paginated<Data>> {
  return new Promise<Paginated<Data>>((resolve) => {
    const results = DATA.filter((result) => {
      const resultMonth = result.date.getMonth();
      const resultYear = result.date.getFullYear();
      return resultMonth === month && resultYear === year;
    });
    const totalPages = Math.ceil(results.length / DEFAULT_PAGE_SIZE);

    const paginatedResults = results
      .slice((page - 1) * totalPages)
      .slice(0, DEFAULT_PAGE_SIZE);

    resolve({
      pagination: {
        page: page ?? 1,
        documentsPerPage: DEFAULT_PAGE_SIZE,
        documentsCount: results.length,
        totalPages,
      },
      results: paginatedResults,
    });
  });
}
