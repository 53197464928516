import styled from "styled-components";

interface Props {
  textStyle?: "normal" | "big" | "small";
  withoutMargin?: boolean;
}

export const P = styled.p<Props>`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme, textStyle = "normal" }) =>
    textStyle === "normal"
      ? theme.fontSize
      : textStyle === "small"
      ? "14px"
      : "18px"};
  color: ${({ theme }) => theme.bodyColor};
  margin: 0 0
    ${({ theme, withoutMargin }) => (withoutMargin ? "0" : theme.tripleMargin)}
    0;

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.secondaryColor};
    font-weight: 600;
  }
`;
