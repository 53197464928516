import { FC } from "react";
import { useFormikContext } from "formik";
import styled from "styled-components";

import { Label } from "@components";
import { Optional } from "@utils/types";
import { Asterisk } from "./Asterisk";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${({ theme }) => theme.smallestPadding};
  width: 100%;
`;

const Selector = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.darkestBlue};
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: 1px;
  margin-top: ${({ theme }) => theme.smallestMargin};
`;

const ItemSelector = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  padding: ${({ theme }) => theme.smallestPadding};
  border-radius: ${({ theme }) => theme.borderRadius};

  &.active {
    background-color: ${({ theme }) => theme.contextTheme.color};
  }
`;

interface Props {
  name: string;
  options: {
    label: string;
    value: Optional<string | number | readonly string[]>;
  }[];
  disabled?: boolean;
  label?: string;
  id?: string;
  required?: boolean;
  onChange?: (currentValue: any) => void;
}

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonGroupField: FC<Props> = ({
  name,
  options,
  id,
  disabled,
  label,
  required,
  onChange,
}) => {
  const { setFieldValue, values, setFieldTouched } =
    useFormikContext<Record<string, any>>();
  const currentValue = values[name];

  return (
    <Container>
      {label && (
        <LabelContainer>
          <Label className="mb-6" htmlFor={id ?? name} children={label} />
          {required && <Asterisk />}
        </LabelContainer>
      )}
      <Selector>
        {options.map((option, index) => (
          <ItemSelector
            key={index}
            className={currentValue === option.value ? "active" : ""}
            onClick={() => {
              if (disabled) return;
              setFieldTouched(name, true);
              setFieldValue(name, option.value);
              onChange?.(option.value);
            }}
            disabled={disabled}
          >
            {option.label}
          </ItemSelector>
        ))}
      </Selector>
    </Container>
  );
};
