const fr = {
  dragHere: "Drag Items Here",
  or: "Or",
  errors: {
    generic: {
      title: "An error occur",
      description: "Please try again.",
    },
    email: {
      invalid: "Insérer un courriel valide",
      required: "L’adresse e-mail est obligatoire",
    },
    city: {
      required: "Une ville est requise",
    },
    country: {
      required: "Un pays est requis",
    },
    date: {
      required: "Une date est requise",
      later: "Must be a future date",
    },
    currency: {
      required: "Une devise est requise",
    },
    name: {
      required: "Un nom est requis",
    },
    surname: {
      required: "Un nom de famille est requis",
    },
    file: {
      required: "Un dossier est requis",
    },
    phone: {
      required: "A phone is required",
      format: "Invalid phone format - please follow +111111111111 format",
    },
    fullName: {
      required: "A full name is required",
    },
  },
  filters: {
    title: "Filtres",
    by: "Filtrer par",
    clear: "Effacer les filtres",
    apply: "Appliquer les filtres",
    assetsManagement: {
      family: "Asset Familty",
      approvalDate: "PSB Approval Date",
      approvalDateFrom: "Start Date",
      approvalDateTo: "End Date",
      acquisitionDate: "Acquisition Date",
      acquisitionDateFrom: "Start Date",
      acquisitionDateTo: "End Date",
      functionalLoc: "Functional Loc",
      brand: "Brand",
      model: "Model",
      stateOfUse: "State of Use",
    },
    disposalRequests: {
      city: "Ville",
      country: "Pays",
      selectCountry: "Sélectionnez un pays",
      date: "Date",
      dateFrom: "From",
      dateTo: "To",
      status: "Status",
    },
  },
  header: {
    digitalOffice: "Bureau Numérique",
    myDisposal: "Ma Disposition",
    publicArea: "Espace Public",
  },
  language: "Langue",
  languages: {
    english: "En",
    french: "Fr",
  },
  loading: "Loading...",
  logout: "Logout",
  menu: {
    home: "Public accueil",
    whyToDispose: "Pourquoi disposer",
    requestDisposal: "Demander l’élimination",
    feedback: "Feedback",
    auctionsList: "Liste des enchères",
    myRequests: "Mes demandes",
    digitalOfficeHome: "Bureau numérique à domicile",
    createAuction: "Créer une enchère",
    manageRequests: "Gérer les demandes",
    assetsManagement: "Gestion des biens",
    manageBidders: "Gérer les soumissionnaires",
  },
  auctionForm: {
    date: {
      label: "Date de la vente aux enchères *",
      placeholder: "Choisissez la date",
    },
    country: "Select country",
    city: "Select city",
    currency: {
      placeholder: "Select currency",
      label: "Auction currency *",
    },
    auctioneerFeeAndDeposit: "Commission et dépôt des commissaires-priseurs",

    fee: "Acutioneer fee",
    auctioneerFeeTooltip:
      "Veuillez attribuer des frais de commissaire-priseur à chaque famille d'actifs. Si une famille d'actifs n'utilise pas de frais de commissaire-priseur, veuillez le mettre à 0. Ceci peut être modifié à tout moment avant la date de la vente aux enchères en direct",
    paymentAccount: "Compte de paiement",
    paymentAccountTooltip:
      "Please enter bank details to be used in winner email notifications",
    paymentConditions: "Conditions de paiement",
    paymentConditionsTooltip:
      "Please enter payment conditions to be used in the Bill of Sales",
    emailNotification: {
      outbid: "Avertir automatiquement l'utilisateur en cas de surenchère",
      winner: "Informer automatiquement les gagnants",
    },
    emailNotificationTooltip: {
      outbid:
        "If selected, the users will receive email notifications once their bid has been outbid",
      winner:
        "If selected, the users with highest bids will receive email notifications with payment instructions after auction end",
    },
    deposit: "Dépôt",
    depositTooltip:
      "Veuillez attribuer des frais de dépôt à chaque famille d'actifs. Si une famille d'actifs n'utilise pas de frais de dépôt, veuillez le mettre à 0. Cela peut être modifié à tout moment avant la date de la vente aux enchères en direct.",
    location: "Location *",
    submit: "Créer",
    clear: "Clear form",
    cancel: "Cancel",
    update: "Save changes",
    agency: "Agence de vente aux enchères",
    sealedBid: "Offre scellée",
    public: "Public",
    supervisor: "UNHCR auction supervisor information",
    focalPoint: "Co focal point",
    representative: "GAS Team representative",
    fullName: "Nom complet",
    email: "Email",
    phone: "Numéro de téléphone au format (+111111111111)",
    errors: {
      conversionRate: {
        required: "A conversion rate is required",
        min: "Enter a value of at least 0.01",
        max: "The value may not exceed 15000",
        decimals: "The value must have 3 digits after decimal or less",
      },
      fee: {
        min: "Ensure this value is greater than or equal to 0.",
        max: "Ensure this value is less than or equal to 100.",
      },
      deposit: {
        min: "Ensure this value is greater than or equal to 0.",
      },
      unhcrServicesFee: {
        min: "Enter a value of at least 0.01",
        decimals: "The value must have 2 digits after decimal or less",
      },
      nonWfpLots: {
        min: "Enter a value of at least 1",
      },
      emailLanguage: {
        required: "Please select one email language",
      },
    },
  },
  lotForm: {
    title: "Create a lot",
    updateTitle: "Update lot",
    submit: "Create Lot",
    update: "Update Lot",
    cancel: "Cancel",
    lot: {
      label: "Lot #",
      placeholder: "Enter lot #",
    },
    name: {
      label: "Lot name",
      placeholder: "Enter lot name",
    },
    reservePrice: {
      label: "Reserve price",
      placeholder: "Enter reserve price",
    },
    errors: {
      lot: {
        required: "A lot number is required",
        number: "Enter an integer greater than 1",
      },
      name: {
        required: "A lot name is required",
      },
      reservePrice: {
        required: "A reserve price is required",
        min: "Enter a value of at least 0.01",
        max: "The value may not exceed 999999999.99",
        decimals: "The value must have 2 digits after decimal or less",
      },
    },
  },
  newDisposalForm: {
    submit: "Envoyer la demande",
    country: "Sélectionner un pays",
    city: "Sélectionner la ville",
    name: "Nom",
    surname: "Prénom",
    email: "Email",
    description: "Description (optionnelle)",
    auctionLocation: "Lieu de vente aux enchères",
    contactInformation: "Coordonnées",
  },
  noFileSelected: "Joindre la liste des articles",
  notFound: "404 Not Found",
  noItems: "Aucun élément trouvé",
  of: "des",
  ok: "OK",
  pages: {
    assetsManagement: {
      error: {
        title: "An error occur",
        description: "Please try again.",
      },
      unauthorized: {
        title: "Area Reserved",
        description:
          "You are not authorised to view Assets list.<br/>If you need to access our Disposal Services please contact us at <a href='mailto:global.asset@wfp.org'>global.asset@wfp.org</a>",
      },
      inventory: "Inventory",
      family: "Asset family",
      description: "Description",
      acquisitionDate: "Acquisition date",
      stateOfUse: "State of use",
      loc: "Functional loc",
      brand: "Brand",
      model: "Model",
      chassis: "Chassis",
      yearOfMake: "Year of make",
      psbDate: "PSB Date",
      selectAll: "Select All",
      edit: "Edit",
      emr: "EMR",
      gvlp: "GVLP #",
      amr: "AMR",
      mileage: "Mileage (KM)",
      acquisitionValue: "Acquisition value",
      licensePlate: "License plate",
      marketValue: "Market value",
      engine: "Engine #",
      equipCategory: "Equip category",
      drId: "DR ID",
      objectType: "Object type",
      titleOfOwnership: "Title of ownership",
      objectSubType: "Object sub-type",
      startUpDate: "Start-up date",
      purchaseDoc: "Purchase Doc",
      viewingLoc: "Viewing loc",
      addToAuction: "Add to auction",
      addToAuctionButton: "Add to auction",
      addToAuctionModal: {
        firstContent_one:
          "You about to add {{count}} asset to the auction. You always can edit assets on the “Auction assets“ page.",
        firstContent_other:
          "You about to add {{count}} assets to the auction. You always can edit assets on the “Auction assets“ page.",
        secondContent:
          "Please choose the auction to add the list of assets below.",
        auctionList: "Auction list",
        selectAnAuction: "Select an auction",
        cancel: "Cancel",
        ok: "Ok",
        retry: "Retry",
        success: {
          title: "Success",
          firstContent_one:
            "You have added {{count}} asset to the “{{auction}}”.",
          firstContent_other:
            "You have added {{count}} assets to the “{{auction}}”.",
          secondContent:
            "You always can edit assets on the “Auction assets“ page.",
        },
        error: {
          title: "Error",
          content: "An error occur",
        },
      },
    },
    auctionDetails: {
      title: "Auction Details",
      importTemplate: "Modèle d'importation",
      unauthorized: {
        title: "Area Reserved",
        description:
          "You are not authorised to view this section.<br/>If you need to access our Disposal Services please contact us at <a href='mailto:global.asset@wfp.org'>global.asset@wfp.org</a>",
      },
      assetsAndLots: {
        title: "Auction Assets and lots",
        descriptionAction:
          "Veuillez sélectionner les actifs pour les regrouper en lots qui participeront à la vente aux enchères.",
        inventory: "Inventory",
        objectSubType: "Object sub-type",
        description: "Description",
        stateOfUse: "State of use",
        assets: "Assets",
        removeAsset: "Remove asset",
        lot: "Lot #",
        lotName: "Lot name",
        reservePrice: "Reserve price",
        acquisitionValue: "Acquisition value",
        lots: "Lots",
        unpackageLots: "Unpackage lots",
        createLotWithItems_one: "Create a lot with selected item ({{count}})",
        createLotWithItems_other:
          "Create a lot with selected items ({{count}})",
        edit: "Edit",
        filters: {
          assets: {
            title: "Filter Assets",
          },
          lots: {
            title: "Filter Lots",
            reservePrice: "Reserve Price",
            acquisitionValue: "Acquisition Value",
            marketValue: "Market Value",
            number: "Lot #",
            from: "From",
            to: "To",
          },
        },
      },
      interimReport: "Interim Report",
    },
    auctionsList: {
      status: "Statut",
      type: "Taper",
      date: "Date",
      country: "Pays",
      city: "Ville",
      lots: "Nombre de lots",
      actions: "Actions",
      download: "Télécharger le rapport",
      viewDetail: "Aller au détail des enchères",
      online: "En ligne",
      error: {
        title: "Une erreur s'est produite",
        description: "Veuillez réessayer.",
      },
      unauthorized: {
        title: "Area Reserved",
        description:
          "You are not authorised to view Auctions list.<br/>If you need to access our Disposal Services please contact us at <a href='mailto:global.asset@wfp.org'>global.asset@wfp.org</a>",
      },
      statuses: {
        new: "New",
        assetsAdded: "Assets added",
        lotsCreated: "Lots created",
        underCdReview: "Under CD review",
        feedbackByCd: "Feedback by CD",
        approvedByCd: "Approved by CD",
        promotion: "Promotion",
        live: "Live",
        done: "Done",
        finalized: "Finalized",
      },
    },
    createAuction: {
      title: "Créer une nouvelle enchère",
      unauthorized: {
        title: "Zone réservée",
        description:
          "Vous n’êtes pas autorisé à créer des enchères.<br/>Si vous avez besoin d’accéder à nos services d’élimination, veuillez communiquer avec nous à <a href='mailto:global.asset@wfp.org'>global.asset@wfp.org</a>",
      },
      error: {
        title: "Une erreur se produit",
        description: "Veuillez réessayer.",
      },
    },
    home: {
      public: {
        title: "Découvrez la nouvelle élimination",
        description:
          "Bienvenue dans le Global Asset Disposal. L’outil final pour demander, gérer et analyser vos cessions et enchères.",
      },
      "my-disposal": {
        title: "Ma disposition",
        description:
          "Vérifiez vos demandes, suivez la mise en place des enchères, approuvez les enchères, examinez et exportez les résultats.",
        subTitle: "Liberia, Monrovia",
      },
      "digital-office": {
        title: "Global Asset Disposal",
        description:
          "Découvrez la nouvelle façon de faire votre processus d'élimination pour tous WFP Asset.",
      },
    },
    manageRequests: {
      status: "Status",
      statuses: {
        open: "Ouvert",
        closed: "Fermé",
        inProgress: "En cours",
        approved: "Approuvé",
        blocked: "Bloqué",
      },
      date: "Date",
      country: "Pays",
      city: "Ville",
      id: "ID de demande d’élimination",
      requestedBy: "Demande par",
      description: "Description",
      viewDetail: "Détails",
      current: "Demandes Actuelles",
      closed: "Demandes Complétées",
      error: {
        title: "An error occur",
        description: "Please retry later.",
      },
      unauthorized: {
        title: "Zone réservée",
        description:
          "Vous n’êtes pas autorisé à gérer les demandes.<br/>Si vous avez besoin d’accéder à nos services d’élimination, veuillez communiquer avec nous à <a href='mailto:global.asset@wfp.org'>global.asset@wfp.org</a>",
      },
      detail: {
        title: "Détails sur la demande d’élimination {{id}}",
        cancel: "Annuler",
        save: "Enregistrer les modifications",
        history: "Historique des modifications",
        message: "Message",
        communicationHistory: "Communication History",
        infoMessage:
          "We will send an email  to the request author every time you make any changes",
        downloadAssets: "Download Assets List",
        upload: "Upload",
        approve: "Approve",
      },
    },
    whyToDispose: {
      title: "Comment disposer des enchères",
      link: "Voir la documentation sur wfp.go",
      firstSection: {
        firstParagraph:
          "Mais je dois vous expliquer comment toute cette idée erronée de dénoncer le plaisir et de louer la douleur est née et je vais vous donner un compte rendu complet du système, et exposer les enseignements actuels du grand explorateur de la vérité, le maître-constructeur du bonheur humain. Une merveilleuse sérénité a pris possession de toute mon âme, comme ces doux matins de printemps que j’apprécie de tout mon cœur. Je suis seul, et je sens le charme de l’existence en cet endroit, qui a été créé pour le bonheur des âmes comme la mienne.",
        subTitle: "Une nouvelle vie pour vos actifs",
        secondParagraph:
          "Un matin, quand Gregor Samsa se réveilla de ses rêves troublés, il se trouva transformé dans son lit en une horrible vermine. Il était allongé sur son dos en armure, et s’il levait un peu la tête, il pouvait voir son ventre brun, légèrement bombé et divisé par des arcs en sections raides.<br/><br/>Mais je dois vous expliquer comment toute cette idée erronée de dénoncer le plaisir et de louer la douleur est née et je vais vous donner un compte rendu complet du système, et exposer les enseignements réels du grand explorateur de la vérité, le maître-constructeur du bonheur humain.<br/>Une merveilleuse sérénité a pris possession de toute mon âme, comme ces doux matins de printemps que j’apprécie de tout mon cœur. Je suis seul, et je sens le charme de l’existence en cet endroit, qui a été créé pour le bonheur des âmes comme la mienne.",
      },
      secondSection: {
        images:
          "Photo dans l’ordre: a) Disposition en Afghanistan b) Vente aux enchères en Irak c) Bien utilisé après vente aux enchères en Libye",
      },
    },
    requestDisposal: {
      title: "Nouvelle demande d’élimination",
      firstParagraph:
        "Vous pouvez créer votre propre élimination. Remplissez le formulaire, téléchargez la liste des articles et envoyez-le nous pour examiner et approuver votre enchère. Allez à la page Mon élimination pour vérifier l’état de votre demande d’élimination.",
      secondParagraph:
        "Nous créons le modèle de liste d’éléments pour faciliter le processus. Pour télécharger le modèle, appuyez sur le bouton ci-dessous.",
      link: "Télécharger le modèle de liste des éléments",
      confirm: {
        title: "Votre demande a été soumise",
        description:
          "L’équipe du SGA examinera votre demande.<br/>À la prochaine étape, veuillez cocher « Mon élimination » pour approuver et permettre à l’équipe du SGA de commencer votre enchère. Vous pouvez consulter les rapports après la fin de la vente aux enchères.",
      },
      error: {
        title:
          "Une erreur se produit en essayant de créer une nouvelle demande d’élimination.",
        description: "Veuillez réessayer plus tard.",
      },
      unauthorized: {
        title: "Zone réservée aux points focaux des actifs",
        description:
          "Vous n’êtes pas autorisé à demander l’élimination.<br/>Si vous avez besoin d’accéder à nos services d’élimination, veuillez communiquer avec nous à <a href='mailto:global.asset@wfp.org'>global.asset@wfp.org</a>",
      },
    },
    mangeBidder: {
      table: {
        name: "Nom",
        email: "Email",
        phone: "Téléphone",
        dateJoined: "Date d'adhésion",
        availableDeposits: "Dépôts disponibles",
        blockUser: "Bloquer un utilisateur",
        manageDeposits: "Gérer les dépôts",
      },
      modal: {
        titleBlock: "Bloquer un utilisateur {{email}}",
        titleUnblock: "Débloquer l'utilisateur {{email}}",
        unblockText: "L'utilisateur {{email}} sera débloqué",
      },
    },
  },
  inactive: {
    description:
      "There seems to be an issue with your account.<br/>Please contact us via <a href='mailto:email@domain.com'>email@domain.com</a>",
  },
  retry: "Réessayer",
};

export default fr;
