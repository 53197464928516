import styled from "styled-components";

export const H1 = styled.h1<{ withoutMargin?: boolean }>`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.biggerFontSize};
  color: ${({ theme }) => theme.bodyColor};
  margin: 0 0
    ${({ theme, withoutMargin }) => (withoutMargin ? "0" : theme.tripleMargin)}
    0;
  text-align: center;
  font-weight: 400;
`;
