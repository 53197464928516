import styled from "styled-components";

export interface Props {
  colorTheme: "green" | "yellow" | "red" | "orange" | "lightBlue" | "grey";
}

export const Tag = styled.span<Props>`
  color: ${({ theme }) => theme.white};
  display: flex;
  align-items: center;
  padding: 4px 8px;
  font-size: ${({ theme }) => theme.smallestFontSize};
  text-transform: uppercase;
  border-radius: ${({ theme }) => theme.borderRounded};
  background-color: ${({ colorTheme, theme }) => theme[colorTheme]};
  width: fit-content;
  font-weight: 600;
`;
