import styled from "styled-components";

export const Select = styled.select`
  background-color: ${({ theme }) => theme.darkestBlue};
  border: 1px solid ${({ theme }) => theme.secondaryBlue};
  outline: none;
  padding: ${({ theme }) => `${theme.doublePadding} ${theme.oneSixthPadding}`};
  border-radius: ${({ theme }) => theme.borderRadius};
  color: ${({ theme }) => theme.bodyColor};
  font-family: ${({ theme }) => theme.fontFamily};
  width: 100%;
  box-sizing: border-box;

  :disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  ::placeholder {
    color: ${({ theme }) => theme.grey};
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: ${({ theme }) => theme.grey};
  }

  ::-ms-input-placeholder {
    color: ${({ theme }) => theme.grey};
  }
`;
