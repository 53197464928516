import { Props as TagProps } from "@components/Tag";
import { AuctionStatus } from "./types";

export function getColorAndLabelFromAuctionStatus(status: AuctionStatus): {
  color: TagProps["colorTheme"];
  label: string;
} {
  switch (status) {
    case AuctionStatus.NEW:
      return {
        color: "orange",
        label: "pages.auctionsList.statuses.new",
      };
    case AuctionStatus.ASSETS_ADDED:
      return {
        color: "yellow",
        label: "pages.auctionsList.statuses.assetsAdded",
      };
    case AuctionStatus.LOTS_CREATED:
      return {
        color: "yellow",
        label: "pages.auctionsList.statuses.lotsCreated",
      };
    case AuctionStatus.UNDER_CD_REVIEW:
      return {
        color: "yellow",
        label: "pages.auctionsList.statuses.underCdReview",
      };
    case AuctionStatus.FEEDBACK_BY_CD:
      return {
        color: "red",
        label: "pages.auctionsList.statuses.feedbackByCd",
      };
    case AuctionStatus.APPROVED_BY_CD:
      return {
        color: "yellow",
        label: "pages.auctionsList.statuses.approvedByCd",
      };
    case AuctionStatus.LIVE:
      return {
        color: "orange",
        label: "pages.auctionsList.statuses.live",
      };
    case AuctionStatus.PROMOTION:
      return {
        color: "orange",
        label: "pages.auctionsList.statuses.promotion",
      };
    case AuctionStatus.DONE:
      return {
        color: "lightBlue",
        label: "pages.auctionsList.statuses.done",
      };
    case AuctionStatus.FINALIZED:
      return {
        color: "green",
        label: "pages.auctionsList.statuses.finalized",
      };
  }
}
