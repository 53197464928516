import { useDrag } from "react-dnd";

import { WithOptionalChildren } from "@utils";
import { Row, TableTheme } from "./Table";

interface Props<T extends { id: number }> extends WithOptionalChildren {
  item: T;
  onDragEnd?: (item: T) => void;
  theme?: TableTheme;
}

export function DraggableRow<T extends { id: number }>({
  theme = "dark",
  children,
  item,
  onDragEnd,
}: Props<T>) {
  const [{ didDrop }, drag] = useDrag({
    type: "Row",
    item: { name: "Row", type: "Row", item },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      didDrop: monitor.didDrop(),
    }),
    end: ({ item }) => {
      if (didDrop) {
        onDragEnd?.(item);
      }
    },
  });

  return (
    <Row tableTheme={theme} ref={drag} draggable>
      {children}
    </Row>
  );
}
