import { useCallback, useMemo } from "react";

import { makeInclusiveNumberRange, Pagination } from "@utils";

interface UsePagination {
  previousPage: number;
  nextPage: number;
  getPathForPage(page: number): string;
  isCurrentPage(page: number): boolean;
  visiblePages: number[];
}

export function usePagination(
  pagination: Pagination,
  windowSize: number,
  currentUrl: string,
  customPageName?: string
): UsePagination {
  const previousPage = useMemo(
    () => Math.max(1, pagination.page - 1),
    [pagination]
  );

  const nextPage = useMemo(
    () => Math.min(pagination.page + 1, pagination.totalPages),
    [pagination]
  );

  const getPathForPage = useCallback(
    (page: number) => {
      const newUrl = new URL(currentUrl);
      newUrl.searchParams.set(customPageName ?? "page", String(page));
      return `${newUrl.pathname}${newUrl.search}`;
    },
    [currentUrl, customPageName]
  );

  const isCurrentPage = useCallback(
    (page: number) => pagination.page === page,
    [pagination]
  );

  const visiblePages = useMemo(() => {
    const { page: currentPage, totalPages } = pagination;

    if (totalPages <= windowSize) {
      const lastPage = Math.min(totalPages, windowSize);
      return makeInclusiveNumberRange(1, lastPage);
    } else {
      if (currentPage <= Math.ceil(windowSize / 2)) {
        return makeInclusiveNumberRange(1, windowSize);
      } else if (currentPage >= totalPages - Math.floor(windowSize / 2)) {
        return makeInclusiveNumberRange(
          totalPages - windowSize + 1,
          totalPages
        );
      } else {
        return makeInclusiveNumberRange(
          currentPage - Math.floor(windowSize / 2),
          currentPage + Math.floor(windowSize / 2)
        );
      }
    }
  }, [pagination, windowSize]);

  return {
    previousPage,
    nextPage,
    getPathForPage,
    isCurrentPage,
    visiblePages,
  };
}
