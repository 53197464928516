import styled from "styled-components";

interface Props {
  size: "small" | "big";
  full?: boolean;
}

export const Loader = styled.div<Props>`
  border-radius: ${({ theme }) => theme.borderRadiusHalf};
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  margin: ${({ theme }) => theme.oneSixthPadding} auto;

  ${({ full }) =>
    full &&
    `
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  `}

  ${({ size, theme }) =>
    size === "small"
      ? `
        border: 2px solid ${theme.lighterGrey};
        border-top: 2px solid ${theme.secondaryBlue};
        width: 20px;
        height: 20px;
      `
      : `
        border: 6px solid ${theme.lighterGrey};
        border-top: 6px solid ${theme.secondaryBlue};
        width: 40px;
        height: 40px;
      `}

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
