import React, { FC, ReactNode } from "react";
import styled from "styled-components";

import { device } from "@styles/breakpoints";

const SectionHeader = styled.div<{ dark: boolean; alignStart?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ alignStart }) =>
    alignStart ? "flex-start" : "space-around"};

  @media ${device.tablet} {
    flex-direction: column;
    align-items: flex-start;
  }

  .item {
    display: flex;
    flex-direction: column;

    @media ${device.tablet} {
      margin-bottom: ${({ theme }) => theme.doubleMargin};
    }

    .title {
      color: ${({ theme, dark }) => (dark ? theme.lighterBlack : theme.grey)};
      font-size: ${({ theme }) => theme.smallestFontSize};
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: ${({ theme }) => theme.smallMargin};

      ${({ dark }) =>
        dark &&
        `
        font-weight: 400;
      `}
    }

    .content {
      color: ${({ theme, dark }) => (dark ? theme.lighterBlack : theme.grey)};
      font-size: ${({ theme }) => theme.smallFontSize};

      &.start {
        text-align: start;
      }
      &.end {
        text-align: end;
      }
      &.center {
        text-align: center;
      }

      @media ${device.tablet} {
        &.start {
          text-align: start;
        }
        &.end {
          text-align: start;
        }
        &.center {
          text-align: start;
        }
      }

      ${({ dark }) =>
        dark &&
        `
        font-weight: 600;
      `}
    }
  }
`;

const Separator = styled.div<{ dark: boolean; alignStart?: boolean }>`
  margin: 0
    ${({ theme, alignStart }) =>
      alignStart ? theme.tripleMargin : theme.smallestMargin};
  border-left: 1px solid
    ${({ theme, dark }) => (dark ? theme.lighterGrey : theme.casalColor)};
  height: 55px;

  @media ${device.tablet} {
    display: none;
  }
`;

interface Props {
  items: {
    title: string;
    content?: ReactNode;
    alignValue?: "start" | "end" | "center";
  }[];
  theme?: "dark" | "light";
  alignStart?: boolean;
}

export const SeparatedSection: FC<Props> = ({
  items,
  theme = "light",
  alignStart,
}) => {
  return (
    <SectionHeader dark={theme === "dark"} alignStart={alignStart}>
      {items.map(({ title, content, alignValue = "start" }, index) => (
        <React.Fragment key={index}>
          <div className="item">
            <span className="title">{title}</span>
            {content && (
              <span className={`content ${alignValue}`}>{content}</span>
            )}
          </div>

          {
            // It hides the Separator for the last element
            index !== items.length - 1 && (
              <Separator dark={theme === "dark"} alignStart={alignStart} />
            )
          }
        </React.Fragment>
      ))}
    </SectionHeader>
  );
};
