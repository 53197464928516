import { ButtonHTMLAttributes } from "react";
import styled from "styled-components";

import { IconByName, IconName } from "@components/Icon";
import { useI18n } from "@i18n";
import { device } from "@styles/breakpoints";
import { WithChildren } from "@utils";
import { ButtonFileUpload } from "./FileUpload";
import { Link } from "./Link";

export interface Props
  extends WithChildren,
    Omit<ButtonHTMLAttributes<unknown>, "children"> {
  onClick: () => void;
  theme?: ButtonTheme;
  rounded?: boolean;
  disabled?: boolean;
  icon?: IconName;
  iconPosition?: "right" | "left";
  bordered?: boolean;
  loading?: boolean;
}

export enum ButtonTheme {
  Primary = "primary",
  Secondary = "secondary",
  Tertiary = "tertiary",
  Quaternary = "quaternary",
  Blue = "blue",
}

const StyledButton = styled.button<{
  colorTheme: ButtonTheme;
  rounded: boolean;
  bordered: boolean;
}>`
  cursor: pointer;
  padding: ${({ theme, rounded }) =>
    rounded
      ? `${theme.smallestPadding} ${theme.triplePadding}`
      : `${theme.doublePadding} ${theme.triplePadding}`};
  color: ${({ theme }) => theme.bodyColor};
  border-radius: ${({ rounded, theme }) =>
    rounded ? `999px` : theme.borderRadius};
  border: none;
  font-size: ${({ theme }) => theme.fontSize};
  font-family: ${({ theme }) => theme.fontFamily};
  text-transform: capitalize;
  ${({ colorTheme, theme }) => {
    switch (colorTheme) {
      case ButtonTheme.Primary:
        return `
          background-color: ${theme.primaryColor}
        `;
      case ButtonTheme.Secondary:
        return `
          background-color: ${theme.secondaryColor}
        `;
      case ButtonTheme.Tertiary:
        return `
          background-color: ${theme.tertiaryColor}
        `;
      case ButtonTheme.Quaternary:
        return `
          background-color: ${theme.quaternaryColor}
        `;
      case ButtonTheme.Blue:
        return `
          background-color: ${theme.quaternaryBlue}
        `;
      default:
        return `
          background-color: ${theme.primaryColor}
        `;
    }
  }};

  ${({ theme, colorTheme, bordered }) => {
    if (bordered) {
      switch (colorTheme) {
        case ButtonTheme.Primary:
          return `
            background-color: transparent;
            color: ${theme.primaryColor};
            border: 1px solid ${theme.primaryColor};
          `;
        case ButtonTheme.Secondary:
          return `
            background-color: transparent;
            color: ${theme.secondaryColor};
            border: 1px solid ${theme.secondaryColor};
          `;
        case ButtonTheme.Tertiary:
          return `
            background-color: transparent;
            color: ${theme.tertiaryColor};
            border: 1px solid ${theme.tertiaryColor};
          `;
        case ButtonTheme.Quaternary:
          return `
            background-color: transparent;
            color: ${theme.quaternaryColor};
            border: 1px solid ${theme.quaternaryColor};
          `;
        default:
          return `
            border: 1px solid ${theme.primaryColor}
          `;
      }
    }
  }}}


  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }

  @media ${device.mobileL} {
     padding: ${({ theme, rounded }) =>
       rounded
         ? `${theme.smallestPadding} ${theme.triplePadding}`
         : `${theme.doublePadding} ${theme.triplePadding}`};
  }
`;

const Container = styled.div<{ iconPosition?: "right" | "left" }>`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: ${({ theme }) => theme.baseSize};
    fill: ${({ theme }) => theme.bodyColor};

    ${({ iconPosition }) =>
      iconPosition === "right" ? "margin-left: 10px" : "margin-right: 10px"}
  }
`;

export const SquaredButton = styled.button`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ theme }) => theme.secondaryColor};
  color: ${({ theme }) => theme.bodyColor};
  border: none;
  cursor: pointer;
  width: 25px;
  height: 25px;

  svg {
    fill: ${({ theme }) => theme.bodyColor};
  }
`;

export const ToggleButton = styled(SquaredButton)`
  background-color: ${({ theme }) => theme.darkestBlue};
`;

export function Button({
  theme = ButtonTheme.Primary,
  rounded = false,
  disabled,
  icon,
  iconPosition,
  children,
  bordered,
  loading,
  onClick,
  ...props
}: Props) {
  const { t } = useI18n();

  return (
    <StyledButton
      onClick={onClick}
      colorTheme={theme}
      rounded={rounded}
      disabled={disabled}
      bordered={bordered ?? false}
      data-testid={`Button-${theme}`}
      {...props}
    >
      <Container iconPosition={iconPosition}>
        {!loading ? (
          <>
            {(!iconPosition || iconPosition === "left") && icon && (
              <IconByName name={icon} />
            )}
            {children}
            {iconPosition === "right" && icon && <IconByName name={icon} />}
          </>
        ) : (
          t("loading")
        )}
      </Container>
    </StyledButton>
  );
}

Button.Link = Link;
Button.FileUpload = ButtonFileUpload;
