import React from "react";
import styled from "styled-components";

const Container = styled.div`
  z-index: 1;
  padding-left: 24px;
  max-width: 70%;

  a {
    color: #858bff;
  }
`;

export const TermsAndConditionsFr = () => {
  return (
    <Container>
      <h1>Conditions d’utilisation</h1>
      <h1>Les enchères en ligne du Programme alimentaire mondial</h1>
      <p>
        Les présentes conditions d’utilisation (« Conditions ») régissent
        l’utilisation et la participation à l’outil d’enchères en ligne du
        Programme alimentaire mondial (« Plateforme ») entre l’utilisateur de la
        Plateforme (« Utilisateur ») et le Programme alimentaire mondial (« PAM
        »). En accédant ou en utilisant la Plateforme et ses services, vous
        acceptez de respecter et d’être lié par les présentes Conditions.
      </p>

      <h2>1. Définitions</h2>
      <p>
        « Enchères » désigne le processus par lequel les Utilisateurs
        enchérissent sur les articles répertoriés sur la Plateforme.
      </p>
      <p>
        « Soumissionnaire » désigne un Utilisateur qui enchérit sur des Lots
        répertoriés sur la Plateforme, âgé de plus de 18 ans et capable de
        conclure des contrats juridiquement contraignants.
      </p>
      <p>
        « Étape d’augmentation » ou « étape d’enchère » fait référence à une
        augmentation minimale prédéfinie du montant de l’Offre que le
        Soumissionnaire peut effectuer pour placer une Enchère pour un Lot
        spécifique.
      </p>
      <p>
        « Lot » fait référence à un article individuel ou à un groupe d’articles
        proposé aux enchères par le PAM sur la Plateforme. Il englobe toutes les
        informations, y compris, mais sans s’y limiter, les descriptions
        textuelles, les images, les médias, les montants des enchères de départ,
        les prix de réserve, la durée et toutes les modalités ou conditions
        supplémentaires spécifiées par le PAM qui sont présentées sur la
        Plateforme en relation avec l’Enchère.
      </p>
      <p>
        « Offre » ou « Enchère » fait référence à une offre ou une proposition
        faite par un Soumissionnaire sur la Plateforme, exprimant son intention
        d’acheter un Lot mis aux enchères par le PAM.
      </p>
      <p>
        « Plateforme » fait référence à l’application mobile de l’outil
        d’enchères en ligne du Programme alimentaire mondial.
      </p>
      <p>
        « Prix de réserve » fait référence à un montant minimal d’enchère
        prédéfini et non divulgué pour lequel le PAM peut conclure la vente d’un
        Lot.
      </p>
      <p>
        « Exigences » fait référence à toute exigence légale ou réglementaire,
        ayant une implication financière ou non, y compris, mais sans s’y
        limiter, les licences, permis ou enregistrements auxquels peuvent être
        soumis certains articles mis aux enchères sur la Plateforme.
      </p>
      <p>
        « Utilisateur » désigne la personne physique ou morale utilisant la
        Plateforme.
      </p>
      <p>
        « Compte Utilisateur » désigne le compte appartenant à l’Utilisateur
        utilisé pour accéder à la Plateforme
      </p>
      <p>
        « Enchère gagnante » fait référence à l’Enchère identifiée par le PAM
        comme étant l’Enchère ayant remporté le Lot.
      </p>
      <p>
        « Soumissionnaire retenu » fait référence au Soumissionnaire qui a
        présenté l’Enchère gagnante acceptée par le PAM.
      </p>
      <p>
        « Marques du PAM » fait référence au nom, à l’emblème et à l’abréviation
        du Programme alimentaire mondial.
      </p>

      <h2>2. Utilisation de la plateforme</h2>
      <p>
        2.1. Règlement intérieur et Compte utilisateur : L’Utilisateur peut être
        amené à s’inscrire et/ou créer un Compte utilisateur pour accéder ou
        utiliser certaines rubriques de la Plateforme. L’Utilisateur ne peut
        jamais utiliser un autre Compte utilisateur sans autorisation. En
        s’inscrivant ou en créant un Compte utilisateur, l’Utilisateur déclare
        et garantit que (i) l’Utilisateur est majeur ou a obtenu le consentement
        légal de ses parents ou tuteurs et qu’il est pleinement capable et
        compétent pour respecter et se conformer aux présentes conditions ; et
        (ii) si l’Utilisateur représente une entité juridique, l’Utilisateur a
        le pouvoir de lier cette entité juridique aux présentes Conditions.
        L’Utilisateur est responsable de la confidentialité du mot de passe et
        des autres informations de connexion pertinentes et doit informer le PAM
        si, à tout moment, l’Utilisateur considère que son mot de passe ou ses
        informations de connexion ont été compromis et/ou sont utilisés à
        mauvais escient par quelqu’un d’autre et devra prendre les mesures
        requises par le PAM pour empêcher une telle utilisation abusive.
        L’Utilisateur est responsable de toutes les activités menées sous son
        Compte utilisateur.
      </p>
      <p>
        2.2. Le contenu tiers affiché sur le Site ne peut être utilisé que sous
        réserve du consentement de son propriétaire ou autrement autorisé par la
        loi applicable, selon le cas. Rien dans les présentes conditions
        d’utilisation ne sera considéré comme conférant des droits ou des
        avantages à des tiers sur un tel contenu.
      </p>
      <p>
        2.2. Suspension et résiliation : Le PAM se réserve le droit de suspendre
        ou de mettre fin à l’accès de l’Utilisateur à la Plateforme et ce à sa
        discrétion, y compris en cas de violation des présentes Conditions, en
        particulier dans le cas où l’Utilisateur place des offres sans intention
        d’acheter, ou ne parvient pas à collecter des Lots dans un délai défini
        ou pour toute autre raison. Les droits et obligations de l’Utilisateur
        résultant d’une Offre soumise concernant un Lot ne seront pas affectés
        par toute modification, suspension ou résiliation du Compte utilisateur.
      </p>
      <p>2.3. L’utilisation de la Plateforme est gratuite</p>
      <p>
        2.4. Conduite de l’Utilisateur : L’Utilisateur est seul responsable de
        son comportement sur la Plateforme.
      </p>
      <p>
        2.4.1 L’Utilisateur s’engage à utiliser la Plateforme uniquement pour
        envoyer et recevoir des informations, des contenus et des matériels
        appropriés et liés à l’utilisation de la Plateforme. À titre d’exemple
        et de manière non exhaustive, l’Utilisateur s’engage, lors de
        l’utilisation de la Plateforme, à ne pas :
        <ol type="a">
          <li>
            diffamer, abuser, harceler, traquer, menacer ou violer de quelque
            manière que ce soit les droits légaux (tels que les droits à la vie
            privée et à la publicité) d’autrui ;
          </li>
          <li>
            publier, poster, distribuer ou diffuser tout matériel ou information
            diffamatoire, contrefait, obscène, indécent ou illégal ;
          </li>
          <li>
            télécharger ou joindre des fichiers contenant des logiciels ou
            d’autres éléments protégés par les lois sur la propriété
            intellectuelle (ou par les droits à la vie privée et à la
            publicité), à moins que l’utilisateur ne possède ou ne contrôle les
            droits y afférents ou n’ait reçu tous les consentements requis par
            la loi ;
          </li>
          <li>
            télécharger ou joindre des fichiers contenant des virus, des
            fichiers corrompus ou tout autre logiciel ou programme similaire
            susceptible d’endommager le fonctionnement des systèmes du PAM ;
          </li>
          <li>contourner, désactiver ou interférer avec la Plateforme ;</li>
          <li>
            supprimer toute attribution d’auteur, mentions légales ou
            désignations ou étiquettes de propriété dans tout fichier ou contenu
            téléchargé ;
          </li>
          <li>
            falsifier l’origine ou la source d’un logiciel ou d’un autre élément
            contenu dans un fichier ou un contenu téléchargé ;
          </li>
          <li>
            prendre toute mesure susceptible de porter atteinte ou de diminuer
            les droits du PAM en vertu des présentes, ou la réputation associée
            à son nom ou au nom des Nations Unies ou de toute organisation du
            système des Nations Unies ; et
          </li>
        </ol>
      </p>
      <p>
        Le PAM se réserve le droit de refuser, à sa seule discrétion, tout accès
        utilisateur à la Plateforme ou à toute partie de celle-ci sans préavis
        et de suspendre, révoquer ou cesser de fournir des comptes utilisateurs
        si l’Utilisateur ne respecte pas les présentes Conditions d’utilisation
        et de supprimer, pour quelque raison que ce soit, sans préavis, toute
        information ou matériel fourni par l’Utilisateur.
      </p>

      <h2>3. Vente aux enchères et participation aux enchères</h2>
      <p>
        3.1. Appel d’offres du PAM : L’affichage ou la mise à disposition de
        Lots par le PAM sur la Plateforme ne constitue pas une offre
        contraignante ou exécutoire de la part du PAM ou un contrat entre le PAM
        et le Soumissionnaire. Il s’agit d’une demande d’offre non contraignante
        sans obligation pour le PAM d’accepter toute offre faite suite à l’appel
        d’offres, même si cette offre répond pleinement à toutes les exigences
        de l’appel d’offres. Concernant l’attribution du Lot, tous les Lots mis
        aux enchères sont soumis à un Prix de Réserve fixé par le PAM. Le PAM
        n’est pas tenu de divulguer le prix de réserve et se réserve le droit de
        rejeter toute offre et de s’abstenir d’attribuer un Lot si l’Offre ne
        respecte pas le Prix de réserve.
      </p>
      <p>
        3.2. Annulation des annonces : Le PAM se réserve le droit d’annuler à
        tout moment l’inscription et de retirer l’attribution en totalité ou en
        partie pour quelque raison que ce soit, y compris dans le cas où le
        Soumissionnaire retenu n’est pas en mesure de fournir le paiement
        intégral dans les 5 jours ouvrables suivant la fin de l’enchère.
      </p>
      <p>
        3.3. Offre du Soumissionnaire : Le Soumissionnaire accepte et reconnaît
        qu’une offre constitue un engagement juridiquement contraignant de sa
        part d’acheter le Lot, à condition que son offre soit identifiée par le
        PAM comme étant l’Enchère gagnante, comme déterminé par les règles et
        procédures de l’enchère menée sur la Plateforme. L’Offre faite par le
        Soumissionnaire doit indiquer le montant monétaire spécifique que le
        Soumissionnaire propose de payer pour l’article au PAM. Le
        Soumissionnaire est conscient et accepte que tous les frais
        supplémentaires, taxes et autres frais pertinents imposés par le
        gouvernement du Soumissionnaire retenu seront supportés uniquement par
        le Soumissionnaire et constitueront sa seule responsabilité.
      </p>

      <h2>4. Transactions</h2>
      <p>
        4.1. Instruction de paiement : Lors de l’attribution d’un Lot, le
        Soumissionnaire retenu sera informé au moyen d’un e-mail envoyé à
        l’adresse e-mail de l’Utilisateur, cet e-mail devra inclure les
        instructions de paiement pertinentes. Tout paiement au PAM doit être
        effectué dans les 5 jours à compter de la date d’attribution au moyen
        d’un virement bancaire. Tous les frais appliqués par la banque en
        relation avec ce transfert seront à la charge du Soumissionnaire.
      </p>
      <p>
        4.2. Livraison : Une fois le paiement reçu, le PAM informera le
        Soumissionnaire retenu des instructions de récupération. Par conséquent,
        le PAM rendra l’article disponible et accessible au Soumissionnaire
        retenu pour qu’il puisse le récupérer dans les locaux du PAM situés à
        Via Cesare Giulio Viola 68 Parco dei Medici 00148 - Rome - Italie, sur
        présentation d’une pièce d’identité officielle.
      </p>
      <p>
        4.3. Retours et remboursements : Le PAM n’accepte le retour d’aucun
        Article et n’est responsable d’aucun remboursement au Soumissionnaire
        retenu.
      </p>
      <p>
        4.4. Diligence raisonnable du Soumissionnaire : Le Soumissionnaire
        retenu reconnaît que certains articles mis aux enchères sur la
        Plateforme peuvent être soumis à des exigences légales ou
        réglementaires, y compris, entre autres, des licences, des permis ou des
        enregistrements (« Exigences ») qui doivent être obtenus pour la
        possession, l’utilisation ou la propriété légale de l’article. Il est de
        la seule responsabilité du Soumissionnaire retenu de rechercher et
        d’identifier toutes les exigences applicables associées à un article
        avant de faire une Offre. En participant à l’Enchère, le Soumissionnaire
        retenu confirme qu’il a effectué la diligence raisonnable nécessaire
        pour déterminer si des exigences s’appliquent à l’article pour lequel il
        a enchéri.
      </p>

      <h2>5. Responsabilité</h2>
      <p>
        5.1. Garantie limitée et clauses de non-responsabilité : Le PAM décline
        expressément toute responsabilité et informe par la présente que les
        articles mis aux enchères sont fournis « tels quels » et « tels que
        disponibles », sans aucune garantie expresse ou implicite d’aucune
        sorte. Cela inclut, entre autres, les garanties de qualité marchande,
        d’adéquation à un usage particulier ou de non-contrefaçon.
      </p>
      <p>
        Le PAM ne sera pas tenu responsable de l’état, de la qualité, de
        l’authenticité, de la légalité ou de la convenance des articles mis aux
        enchères. Le PAM ne sera pas responsable des dommages directs,
        indirects, accessoires, spéciaux ou punitifs, ni de toute perte de
        profits ou de revenus, qu’elle soit encourue directement ou
        indirectement, de la survaleur ou d'autres pertes intangibles, résultant
        de l’utilisation de l’un des articles acquis par l’Utilisateur et les
        éventuelles transactions effectuées sur la Plateforme. Lors de la
        livraison des articles mis aux enchères à l’Utilisateur, l’Utilisateur
        assumera seul toutes les responsabilités, risques et obligations
        concernant les articles.
      </p>
      <p>
        5.2. Avis de non-responsabilité de la plateforme : L’utilisation de la
        Plateforme et de toutes ses fonctionnalités est aux seuls risques de
        l’Utilisateur. La Plateforme, son contenu et ses fonctionnalités sont
        fournis « tels quels » et « tels que disponibles », sans garantie
        d’aucune sorte, expresse ou implicite, y compris, sans limitation, les
        garanties de qualité marchande, d’adéquation à un usage particulier et
        de non-contrefaçon. En particulier, le PAM ne fait aucune garantie ou
        déclaration quant à l’exactitude ou à l’exhaustivité des informations
        présentées sur la plateforme. Le PAM ajoute, modifie, améliore ou met à
        jour périodiquement la Plateforme, les documents et les fonctionnalités
        de la Plateforme sans préavis. L’Utilisateur reconnaît et accepte
        spécifiquement que le PAM n’est pas responsable de la conduite des
        Utilisateur et n’accepte aucune responsabilité en relation avec
        l’utilisation de la Plateforme.
      </p>
      <p>
        5.3. Comme Condition d’utilisation de la Plateforme, de tout matériel ou
        fonctionnalité du site, l’Utilisateur s’engage à indemniser le PAM, son
        personnel, ses fonctionnaires et ses employés de et contre toutes les
        actions, réclamations, pertes, dommages, responsabilités et dépenses (y
        compris les honoraires raisonnables d’avocats) découlant de
        l’utilisation de ce Site par l’Utilisateur, y compris, sans limitation,
        toute réclamation alléguant des faits qui, s’ils étaient avérés,
        constitueraient une violation par l’Utilisateur des présentes Conditions
        d’utilisation.
      </p>

      <h2>6. Protection des données</h2>
      <p>
        6.1. L’utilisation de la Plateforme n’oblige pas l’Utilisateur à fournir
        des données personnelles au PAM. Cependant, les Utilisateurs doivent
        s’inscrire sur la Plateforme pour faire une offre ou enchérir sur des
        articles ou des Lots du PAM. Le PAM, en tant que responsable du
        traitement, traitera (i) les informations d’identification et de contact
        de l’Utilisateur pour gérer son inscription à la Plateforme et, (ii) une
        fois qu’il aura fait une Offre ou une Enchère, pour l’informer sur le
        Statut de l’Enchère ; et (iii) les informations liées à une Offre ou une
        Enchère pour exécuter le processus d’enchère et décider de leur
        attribution. Toutes ces activités de traitement sont nécessaires pour
        participer au processus d’enchères du PAM via la Plateforme et seront
        effectuées conformément aux règles et réglementations du PAM.
      </p>
      <p>
        6.2. Les données personnelles de l’Utilisateur ne seront accessibles que
        par les personnes autorisées du PAM en cas de besoin. Aucun transfert de
        données personnelles à des tiers n’est envisagé.
      </p>
      <p>
        6.3. Des mesures de confidentialité et de sécurité appropriées sont
        mises en œuvre par le PAM pour protéger les données personnelles. Les
        données personnelles seront conservées aussi longtemps que nécessaire
        et, en tout état de cause, conformément aux règles et réglementations du
        PAM. Les droits en matière de protection des données peuvent être
        exercés à tout moment en écrivant à l’adresse
        support.auctiondigitaloffice@wfp.
      </p>

      <h2>7. Résolution des litiges</h2>
      <p>
        7.2. Les présentes Conditions d’utilisation et tout litige en découlant
        seront exclusivement régis par les principes généraux du droit
        international, à l’exclusion de tout système juridique national unique.
      </p>
      <p>
        7.3. Tout litige, controverse ou réclamation découlant de ou lié aux
        présentes Conditions d’utilisation, ou à la violation, la résiliation ou
        l’invalidité de celles-ci ou à l’utilisation des Sites, des éléments et
        des fonctionnalités du site par l’Utilisateur ou de tout contenu
        disponible sur ceux-ci et leurs conditions d’utilisation particulières
        sera réglé par arbitrage conformément au Règlement d’arbitrage de la
        CNUDCI alors en vigueur. Le tribunal arbitral, qui sera composé de 1
        (un) arbitre, sera habilité à ordonner : (i) le retour ou la destruction
        de marchandises ou de biens, corporels ou incorporels, ainsi que de
        toute information confidentielle fournie en vertu des présentes, (ii) la
        résiliation des présentes Conditions d’utilisation ou à demander que
        d’autres mesures de protection soient prises en ce qui concerne les
        marchandises, les services ou tout autre bien, corporel ou incorporel,
        ou toute information confidentielle fournie dans le cadre des présentes,
        selon le cas, conformément à l’autorité du tribunal arbitral en vertu de
        l’article 26 (« Mesures provisoires ») et de l’article 34 (« Forme et
        effet du jugement ») du Règlement d’arbitrage de la CNUDCI. Le tribunal
        arbitral n'est pas habilité à accorder des dommages- intérêts punitifs.
        Le PAM et l’Utilisateur seront liés par toute sentence arbitrale rendue
        à la suite d’un tel arbitrage comme décision finale d’un tel Différend.
        La langue de cette procédure sera l’anglais et le lieu sera Rome.
      </p>
      <p>
        7.4. Dans la mesure où il existe plusieurs versions linguistiques des
        présentes Conditions et s’il existe une incohérence entre les versions,
        la version anglaise prévaudra toujours.
      </p>

      <h2>8. Préservation des immunités</h2>
      <p>
        Rien dans les présentes Conditions d’utilisation n’impliquera une
        renonciation, expresse ou implicite, par le PAM, les Nations unies et
        l’Organisation des Nations Unies pour l’alimentation et l’agriculture
        (FAO), à tout privilège ou à toute immunité dont ils jouissent en vertu
        de la Convention de 1946 sur les privilèges et immunités des Nations
        unies, la Convention de 1947 sur les privilèges et immunités des
        institutions spécialisées,
      </p>
      <p>
        le droit international coutumier, d’autres accords internationaux ou
        nationaux pertinents et en vertu du droit national.
      </p>

      <h2>9. Divers</h2>
      <p>
        9.1. Modifications des Conditions d’utilisation : Le PAM se réserve le
        droit exclusif, à sa seule discrétion, de (i) modifier, limiter ou
        interrompre l’accès à la Plateforme à quelque titre que ce soit ; et
        (ii) modifier unilatéralement les présentes Conditions d’utilisation. Le
        PAM n’a aucune obligation de prendre en considération les besoins d’un
        Utilisateur à cet égard.
      </p>
      <p>
        9.2. Utilisation du Nom ou des Marques : Les Marques du PAM sont la
        propriété exclusive du PAM et sont protégées par le droit international.
        L’utilisation non autorisée des Marques du PAM est interdite. Les
        Marques du PAM ne peuvent être reproduites ou utilisées de quelque
        manière que ce soit sans l’autorisation écrite préalable du PAM. La
        mention ou l’omission de sociétés spécifiques, de leurs produits ou de
        leurs noms de marque sur la Plateforme n’implique aucune approbation ou
        jugement de la part du PAM.
      </p>
      <p>
        9.3. Divisibilité : Chaque disposition des présentes Conditions
        d’utilisation sera considérée comme divisible de telle sorte que si une
        disposition entre en conflit avec la loi applicable ou s’il est
        déterminé qu’une disposition du présent accord est inapplicable, les
        parties entendent que le présent accord soit appliqué comme si la
        disposition inapplicable n’était pas présente et que toute disposition
        partiellement valide et exécutoire soit appliquée dans la mesure où elle
        est exécutoire.
      </p>
      <p>
        9.4. Accord complet : Si le PAM accepte une Offre, en partie ou en
        totalité, et décide de sélectionner l’Offre gagnante, les présentes
        Conditions, ainsi que l’Offre du Soumissionnaire et l’e-mail de
        confirmation du PAM concernant la sélection réussie du Soumissionnaire,
        constitueront le contrat contraignant et exécutoire entre le PAM et le
        Soumissionnaire retenu. Dans la mesure où il existe plusieurs versions
        linguistiques des présentes Conditions et s’il existe une incohérence
        entre les versions, la version anglaise prévaudra toujours.
      </p>
      <p>
        9.5. Contact : Si vous avez des questions sur les présentes Conditions
        générales, la Plateforme et toute question relative à une vente aux
        enchères spécifique, veuillez contacter
        support.auctiondigitaloffice@wfp.org
      </p>
      <p>Dernière modification le 12 février 2024</p>
    </Container>
  );
};
