import { useCallback, useContext } from "react";

import { ToasterErrorContext } from "@contexts";
import { IError } from "@utils";

export type Props = IError;

export interface UseToast {
  (options: Props): void;
}

export function useErrorToast(): UseToast {
  const { open } = useContext(ToasterErrorContext);

  return useCallback((options: Props) => open(options), [open]);
}
