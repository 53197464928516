import { initReactI18next } from "react-i18next";
import i18n from "i18next";

import en from "./translations/en";
import fr from "./translations/fr";

export function initializeTranslations() {
  return i18n.use(initReactI18next).init({
    resources: {
      en: {
        translation: en,
      },
      fr: {
        translation: fr,
      },
    },
    lng: i18n.language,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });
}
