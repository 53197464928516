import { FC } from "react";
import styled from "styled-components";

import { THEME_FROM_CONTEXT, useApplication } from "@applications";
import Theme from "@styles/Theme";
import { Loader } from "./Loader";

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 99;

  &::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: ${({ theme }) => theme.opacityBlue};
    z-index: -1;
  }
`;

export const LoadingPage: FC = () => {
  const { context } = useApplication();
  const contextTheme = THEME_FROM_CONTEXT[context];

  return (
    <Theme {...contextTheme}>
      <Wrapper>
        <Loader size="big" />
      </Wrapper>
    </Theme>
  );
};
