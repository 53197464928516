import React from "react";
import styled from "styled-components";

const Container = styled.div`
  z-index: 1;
  padding-left: 24px;
  max-width: 70%;
`;

export const PrivacyPolicyRu = (): React.ReactElement => {
  return (
    <Container>
      <h2>WFP Online Auctions</h2>
      <h3>Политика конфиденциальности</h3>
      <p>
        Настоящая Политика конфиденциальности регулирует сбор, использование и
        раскрытие личной информации, предоставленной WFP Online Auctions.
      </p>

      <h3>Введение</h3>
      <p>
        WFP Online Auctions обязуются защищать конфиденциальность и
        конфиденциальность личной информации своих пользователей. Эта политика
        описывает как мы собираем, используем и защищаем информацию,
        предоставляемую пользователями при доступе и использовании мобильного
        приложения WFP Online Auctions.
      </p>

      <h3>Сбор и использование личной информации</h3>
      <p>
        WFP Online Auctions может собирать определенные личные данные (PII)
        пользователей для облегчения обработки заявок. Этот информация
        используется только для конкретной цели обеспечения участия в аукционе.
        Сбор персональных данных необходим для обеспечения эффективного
        предоставления услуг и соблюдения неявных обязательств. WFP Online
        auctions не используют персональные данные для каких-либо других целей.
      </p>

      <h3>Информация, не идентифицирующая личность</h3>
      <p>
        Получая доступ к онлайн-аукционам WFP, определенная информация, такая
        как Интернет, адреса протокола (IP), навигация внутри приложения,
        используемый браузер и затраченное время, а также другая подобная
        информация, будет храниться на наших серверах и в стороннем
        аналитическом программном обеспечении. Эта информация рассматривается
        конфиденциально. Сбор этих данных предназначен для лучшего понимания
        предпочтений пользователей WFP Online Auctions и улучшения их опыта
        использования приложения. Эта информация никогда не связывается с
        какой-либо личной информацией, которую пользователи предоставляют нам.
      </p>

      <h3>Меры безопасности</h3>
      <p>
        WFP Online Auctions принимают разумные меры безопасности для защиты
        конфиденциальность и целостности личной информации пользователей. Эти
        меры включают шифрование, безопасное хранение данных и ограниченный
        доступ к персональным данным.
      </p>

      <h3>Запрос на удаление данных</h3>
      <p>
        Пользователи могут запросить удаление своих личных данных, обратившись
        их менеджеру, который должен отправить электронное письмо на адрес
        support.auctiondigitaloffice@wfp.org c объяснением причины запроса на
        удаление данных. Это письмо будет инициировать процесс удаления данных.
        Обратите внимание, что некоторые данные могут быть сохраняются в течение
        ограниченного периода времени, как того требуют применимые законы или
        нормативно-правовые акты.
      </p>

      <h3>Раскрытие информации третьим лицам</h3>
      <p>
        WFP Online Auctions не раскрывают личную информацию третьим лицам и
        сторонам, за исключением случаев, когда это необходимо для оказания
        запрошенных услуг или требуется законом или судебными процессами.
      </p>

      <h3>Изменения в Политике конфиденциальности</h3>
      <p>
        Онлайн-аукционы WFP могут время от времени обновлять настоящую Политику
        конфиденциальности. Рекомендуется периодически просматривать эту
        политику, чтобы быть в курсе того, как защищается личная информация.
      </p>

      <h3>Разрешение спора</h3>
      <p>
        Любой спор, разногласие или претензия, возникающие из настоящей Политики
        Конфиденциальности подлежат урегулированию в арбитраже в соответствии с
        Арбитражным регламентом UNICITRAL, действующему на тот момент.
        Арбитражный суд не имеет полномочий присуждать штрафные санкции. ВПП и
        Пользователь будут связаны любым арбитражным решением, вынесенным в
        результате такого арбитража в качестве окончательного решения любого
        такого Спора. Любое такое разбирательство должно вестись на английском
        языке.
      </p>

      <h3>Привилегии и иммунитеты</h3>
      <p>
        Ничто в настоящей политике конфиденциальности или в отношении нее не
        должно быть считаться отказом от любых привилегий и иммунитетов ВПП
        согласно Конвенции о привилегиях и иммунитетах Объединенных Наций и
        Конвенции о привилегиях и иммунитетах специализированных Агентств.
      </p>

      <h3>Связаться с нами</h3>
      <p>
        Если у вас есть какие-либо вопросы или опасения по поводу настоящей
        политики конфиденциальности или обработки вашей личной информации,
        пожалуйста, свяжитесь с нами по адресу
        support.auctiondigitaloffice@wfp.org.
      </p>
    </Container>
  );
};
