import { FC } from "react";
import styled from "styled-components";

import { useI18n } from "@i18n";
import { IError } from "@utils";
import { Button as BaseButton } from "./Button";
import { H1 } from "./H1";
import { MainContainer } from "./MainContainer";
import { P } from "./P";

export interface Props {
  error: IError;
  onRetry?: () => void;
}

const Button = styled(BaseButton)`
  margin-top: ${({ theme }) => theme.tripleMargin};
  border-color: ${({ theme }) => theme.quaternaryColor};
  color: ${({ theme }) => theme.quaternaryColor};
`;

export const ErrorMessage: FC<Props> = ({
  error: { detail, errors },
  onRetry,
}) => {
  const { t } = useI18n();
  return (
    <MainContainer column>
      <H1 withoutMargin={!Boolean(errors)}>
        {detail || t("errors.generic.description")}
      </H1>
      {errors?.map((error, index) => (
        <P
          key={index}
          dangerouslySetInnerHTML={{
            __html: error.detail,
          }}
          withoutMargin
        />
      ))}
      {onRetry && (
        <Button type="button" onClick={onRetry} bordered>
          {t<string>("retry")}
        </Button>
      )}
    </MainContainer>
  );
};
