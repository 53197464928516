import { Icon } from "../Icon";
import { IconName } from "./IconName.type";

export interface Props {
  name: IconName;
}

export function IconByName({ name, ...props }: Props) {
  const Component = Icon[name];
  return <Component {...props} />;
}
