import { Nullable } from "./Nullable.type";

export enum DisposalRequestStatus {
  OPEN = 1,
  IN_PROGRESS = 2,
  BLOCKED = 3,
  APPROVED = 4,
  CLOSED = 5,
}

export interface IDisposalRequest {
  id: number;
  creation_time: string;
  status: DisposalRequestStatus;
  location: {
    country: {
      name: string;
    };
    geoname_id: number;
    name: string;
    subregion: {
      name: string;
    };
    region: {
      name: string;
    };
  };
  description: Nullable<string>;
  requested_by: { email: string; first_name?: string; last_name?: string };
}

export interface IDisposalRequestWithHistory extends IDisposalRequest {
  file_history: IFileHistory[];
}

export interface IFileHistory {
  obj_name: string;
  region: string;
  bucket: string;
  reason: string;
  creation_time: string;
}
