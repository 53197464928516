import { Agency, AssetFamily, OptionalNotNull } from "@utils";

interface PersonalData {
  fullName: string;
  email: string;
  phone: string;
}

export const INITIAL_VALUES = {
  isOnline: false,
  auctionImage: "",
  country: "",
  city: "",
  date: "",
  auctionEndDate: "",
  depositDate: "",
  timezone: "",
  currency: "",
  paymentAccount: "",
  paymentConditions:
    "The seller certifies to the purchaser that he is the owner of the item and has the authority to " +
    "sell it and transfer his property to the purchaser. \n" +
    "- In case of a banking transfer, the sale will only be valid and complete after receipt of proof of payment (copy " +
    "of bank transfer) of the total amount indicated above. \n" +
    "- A period of 5 (five) days is granted to the purchaser for the completion of customs formalities. After this period, " +
    "the purchaser will pay the costs of storage until the effective removal of the item.\n" +
    "- The seller certifies having received from the purchaser the payment requested in order to proceed with the release of the item.",
  conversionRate: "",
  requiresOnlineDeposits: false,
  auctioneerFees: [] as {
    assets: Array<AssetFamily>;
    fee: OptionalNotNull<number>;
  }[],
  deposits: [] as {
    assets: Array<AssetFamily>;
    deposit: OptionalNotNull<number>;
  }[],
  agency: Agency.UNHCR,
  sealedBid: false,
  supervisor: undefined as OptionalNotNull<PersonalData>,
  focalPoint: undefined as OptionalNotNull<PersonalData>,
  representative: undefined as OptionalNotNull<PersonalData>,
  unhcrServicesFee: undefined as OptionalNotNull<number>,
  nonWfpLots: undefined as OptionalNotNull<number>,
  auctioneerInvoice: undefined as OptionalNotNull<number>,
  sendEmailBidWasOutbid: false,
  sendEmailBidderWon: false,
  emailLanguage: "",
  enableBankTransfer: true,
  enableOnlinePayment: false,
};
