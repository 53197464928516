import { UserByEmail } from "@utils";

export const INITIAL_VALUES = (currentUser: UserByEmail) => ({
  country: currentUser.location?.country.geoname_id ?? "",
  city: currentUser.location?.geoname_id ?? "",
  name: currentUser.first_name,
  surname: currentUser.last_name,
  email: currentUser.email,
  file: "" as unknown as File,
  description: "",
});
