import { FC } from "react";
import styled from "styled-components";

import { THEME_FROM_CONTEXT, useApplication } from "@applications";
import { useAuthentication } from "@authentication";
import { Button, ButtonTheme, MainContainer, P } from "@components";
import { useI18n } from "@i18n";
import Theme from "@styles/Theme";

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 99;

  &::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: ${({ theme }) => theme.opacityBlue};
    z-index: -1;
  }
`;

export const InactiveUserPage: FC = () => {
  const { t } = useI18n();
  const { context } = useApplication();
  const { logout } = useAuthentication();
  const contextTheme = THEME_FROM_CONTEXT[context];

  return (
    <Theme {...contextTheme}>
      <Wrapper>
        <MainContainer column>
          <P
            dangerouslySetInnerHTML={{
              __html: t("inactive.description"),
            }}
          />
          <Button theme={ButtonTheme.Secondary} onClick={logout}>
            {t<string>("logout")}
          </Button>
        </MainContainer>
      </Wrapper>
    </Theme>
  );
};
