import { FC } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { useI18n } from "@i18n";
import { device } from "@styles/breakpoints";
import { IconByName, IconName } from "../Icon";

const Nav = styled.nav`
  display: flex;
  width: 100%;
  background-color: black;
  z-index: 1;
  height: ${({ theme }) => theme.navHeight};
`;

const Link = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.smallPadding};
  color: ${({ theme }) => theme.bodyColor};
  background-color: black;
  text-decoration: none;
  width: 100%;
  text-align: center;

  &.active {
    background-color: ${({ theme }) => theme.contextTheme.color};
  }
`;

const LinkItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: ${({ theme }) => theme.baseSize};
    fill: ${({ theme }) => theme.bodyColor};
    margin-right: 10px;
  }

  @media ${device.tablet} {
    span {
      display: none;
    }

    svg {
      margin-right: 0;
    }
  }
`;

export interface Props {
  links: {
    to: string;
    icon: IconName;
    i18nLabelKey: string;
  }[];
}

export const NavigatorMenu: FC<Props> = ({ links }) => {
  const { t } = useI18n();

  return (
    <Nav>
      {links.map(({ to, icon, i18nLabelKey }, index) => (
        <Link key={index} to={to} activeClassName="active" exact>
          <LinkItem>
            <IconByName name={icon} />
            <span>{t(i18nLabelKey)}</span>
          </LinkItem>
        </Link>
      ))}
    </Nav>
  );
};
