import {
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faCalendar,
  faChevronDown,
  faClipboard,
  faClipboardCheck,
  faDownload,
  faEdit,
  faFileAlt,
  faFolder,
  faGavel,
  faGripVertical,
  faHandshake,
  faHome,
  faInfoCircle,
  faLock,
  faMapMarkerAlt,
  faPaperclip,
  faSearch,
  faSignOutAlt,
  faSquare,
  faTimes,
  faTrash,
  faUpload,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName } from "./IconName.type";

function ChevronDown() {
  return <FontAwesomeIcon icon={faChevronDown} data-test-id="chevron-down" />;
}
function Edit() {
  return <FontAwesomeIcon icon={faEdit} data-testid="Edit" />;
}
function Times() {
  return <FontAwesomeIcon icon={faTimes} data-testid="Times" />;
}
function InfoCircle() {
  return <FontAwesomeIcon icon={faInfoCircle} data-testid="InfoCircle" />;
}
function Drag() {
  return <FontAwesomeIcon icon={faGripVertical} data-testid="Drag" />;
}
function LeftArrow() {
  return <FontAwesomeIcon icon={faArrowLeft} data-testid="LeftArrow" />;
}
function RightArrow() {
  return <FontAwesomeIcon icon={faArrowRight} data-testid="RightArrow" />;
}
function DownArrow() {
  return <FontAwesomeIcon icon={faArrowDown} data-testid="DownArrow" />;
}
function UpArrow() {
  return <FontAwesomeIcon icon={faArrowUp} data-testid="UpArrow" />;
}
function Home() {
  return <FontAwesomeIcon icon={faHome} data-testid="Home" />;
}
function Lock() {
  return <FontAwesomeIcon icon={faLock} data-testid="Lock" />;
}
function SignOut() {
  return <FontAwesomeIcon icon={faSignOutAlt} data-testid="SignOut" />;
}
function Hammer() {
  return <FontAwesomeIcon icon={faGavel} data-testid="Hammer" />;
}
function Handshake() {
  return <FontAwesomeIcon icon={faHandshake} data-testid="Handshake" />;
}
function ClipboardCheck() {
  return (
    <FontAwesomeIcon icon={faClipboardCheck} data-testid="ClipboardCheck" />
  );
}
function Clipboard() {
  return <FontAwesomeIcon icon={faClipboard} data-testid="Clipboard" />;
}
function File() {
  return <FontAwesomeIcon icon={faFileAlt} data-testid="File" />;
}
function Folder() {
  return <FontAwesomeIcon icon={faFolder} data-testid="Folder" />;
}
function Square() {
  return <FontAwesomeIcon icon={faSquare} data-testid="Square" />;
}
function Calendar() {
  return <FontAwesomeIcon icon={faCalendar} data-testid="Calendar" />;
}
function Paperclip() {
  return <FontAwesomeIcon icon={faPaperclip} data-testid="Paperclip" />;
}
function Download() {
  return <FontAwesomeIcon icon={faDownload} data-testid="Download" />;
}
function Trash() {
  return <FontAwesomeIcon icon={faTrash} data-testid="Trash" />;
}
function Upload() {
  return <FontAwesomeIcon icon={faUpload} data-testid="Upload" />;
}
function MapMarker() {
  return <FontAwesomeIcon icon={faMapMarkerAlt} data-testid="MapMarker" />;
}
function User() {
  return <FontAwesomeIcon icon={faUser} data-testid="User" />;
}
function Search() {
  return <FontAwesomeIcon icon={faSearch} data-testid="Search" />;
}

export const Icon = {
  Times,
  Download,
  Upload,
  Home,
  Calendar,
  Handshake,
  Square,
  LeftArrow,
  InfoCircle,
  Edit,
  Drag,
  MapMarker,
  RightArrow,
  DownArrow,
  UpArrow,
  Paperclip,
  Lock,
  SignOut,
  Hammer,
  ChevronDown,
  ClipboardCheck,
  Clipboard,
  File,
  Folder,
  Trash,
  User,
  Search,
};

export const IconNames = Object.keys(Icon) as IconName[];
