import { ApplicationContextValues } from "@applications";
import { IContextTheme } from "@styles/Theme";

type ThemeFromContext = { [key in ApplicationContextValues]: IContextTheme };

export const THEME_FROM_CONTEXT: ThemeFromContext = {
  [ApplicationContextValues.Public]: {
    contextTheme: {
      color: "#075287",
      activeTabColor: "#2AA277",
    },
  },
  [ApplicationContextValues.DigitalOffice]: {
    contextTheme: {
      color: "#3E6EA8",
      activeTabColor: "#2AA277",
    },
  },
};
