import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0px !important;
    padding: 0px !important;
    color: ${({ theme }) => theme.bodyColor};
    font-size: ${({ theme }) => theme.fontSize};
    font-family:  ${({ theme }) => theme.fontFamily};
    font-weight: 100;
  }
`;

export default GlobalStyle;
