import { FC, useState } from "react";
import styled from "styled-components";

import { Icon } from "@components";

export interface Props {
  title: string;
}

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.tertiaryBlue};
  border-bottom: 1px solid ${({ theme }) => theme.casalColor};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${({ theme }) => theme.smallFontSize};
  padding: ${({ theme }) => theme.mediumPadding};
  color: ${({ theme }) => theme.bodyColor};
  cursor: pointer;

  svg {
    width: 14px;
    fill: ${({ theme }) => theme.bodyColor};
  }
`;

const Content = styled.div`
  height: 0;
  display: none;
  padding: 0 12px 20px 12px;

  &.open {
    height: 100%;
    display: block;
  }
`;

export const Accordion: FC<Props> = ({ title, children }) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <Wrapper>
      <Header onClick={() => setOpen(!isOpen)}>
        <span>{title}</span>
        {isOpen ? <Icon.UpArrow /> : <Icon.DownArrow />}
      </Header>
      <Content className={isOpen ? "open" : ""} data-testid="Accordion-Content">
        {children}
      </Content>
    </Wrapper>
  );
};
