import { Optional } from "./types";

export function getValueWithPercentageOrEmpty(
  value: Optional<string | number>
) {
  if (
    value === null ||
    value === undefined ||
    (typeof value === "string" && value.trim() === "")
  )
    return "-";
  const formattedValue = Number(value).toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  return Number.isNaN(Number(value)) ? "-" : `${formattedValue} %`;
}
