export function getEnvironment(url?: string) {
  if (!url) {
    throw new Error("No url provided");
  }
  if (url.includes("dev")) {
    return "dev";
  }
  if (url.includes("beta")) {
    return "beta";
  }
  return "prod";
}
