import { FC, useCallback } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import styled from "styled-components";

const ImageGalleryContainer = styled.div`
  display: flex;
  padding: 16px 0;
  max-width: 300px;
  flex-wrap: wrap;
`;
const ImageItem = styled.img`
  width: 50px;
  height: 100%;
`;

const ImageDeleteButton = styled.div`
  position: absolute;
  right: -5px;
  top: -10px;
  cursor: pointer;
`;

const ImageItemContainer = styled.div`
  position: relative;
  width: 50px;
  margin: 5px;
`;

const IMAGE_TYPE = "image";

interface Props {
  imageUrls: string[];
  photo?: File[];
  handlePhotosChange?: (photo: File[]) => void;
  setImageUrls?: (images: string[]) => void;
}

const createUrl = (files: any): void => {
  let url: any = [];
  for (let file in files) {
    typeof files[file] === "object" &&
      url.push(URL.createObjectURL(files[file]));
  }
  return url;
};

export const ImageGallery: FC<Props> = ({
  imageUrls,
  photo,
  handlePhotosChange,
  setImageUrls,
}) => {
  const [, drop] = useDrop(() => ({ accept: IMAGE_TYPE }));

  const handleDelete = (index: number) => {
    if (photo) {
      const photosArray: File[] = [...photo];
      photosArray.splice(index, 1);
      handlePhotosChange?.(photosArray);
    }
  };

  const findImage = useCallback(
    (url: string) => {
      const image = imageUrls.filter((c) => c === url)[0];
      return {
        image,
        index: imageUrls.indexOf(image),
      };
    },
    [imageUrls]
  );

  const moveCard = useCallback(
    (url: string, atIndex: number) => {
      console.log("url", url);
      const { index } = findImage(url);
      if (photo) {
        const photosArray: File[] = [...photo];
        const photoByIndex = photo[index];
        photosArray.splice(index, 1);
        photosArray.splice(atIndex, 0, photoByIndex);

        handlePhotosChange?.(photosArray);
      }
    },
    [findImage, handlePhotosChange, photo]
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <ImageGalleryContainer ref={drop}>
        {imageUrls.map((item, index) => (
          <Image
            item={item}
            index={index}
            onDelete={() => handleDelete(index)}
            moveCard={moveCard}
            findImage={findImage}
          />
        ))}
      </ImageGalleryContainer>
    </DndProvider>
  );
};

interface ImageProps {
  item: string;
  index: number;
  onDelete: () => void;
  moveCard: any;
  findImage: (url: string) => { image: string; index: number };
}

const Image: FC<ImageProps> = ({ item, onDelete, moveCard, findImage }) => {
  const originalIndex = findImage(item).index;

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: IMAGE_TYPE,
      item: { item, originalIndex },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { item: image, originalIndex } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          moveCard(image, originalIndex);
        }
      },
    }),
    [item, originalIndex, moveCard]
  );

  const [, drop] = useDrop(
    () => ({
      accept: IMAGE_TYPE,
      drop({ item: image }: { item: string }) {
        if (image !== item) {
          const { index: overIndex } = findImage(item);
          moveCard(image, overIndex);
        }
      },
    }),
    [moveCard, findImage]
  );

  const opacity = isDragging ? 0 : 1;

  return (
    <ImageItemContainer
      ref={(node) => drag(drop(node))}
      key={item}
      style={{ opacity }}
    >
      <ImageDeleteButton onClick={onDelete}>x</ImageDeleteButton>
      <ImageItem src={item} />
    </ImageItemContainer>
  );
};
