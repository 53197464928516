import React from "react";
import styled from "styled-components";

const Container = styled.div`
  z-index: 1;
  padding-left: 24px;
  max-width: 70%;

  a {
    color: #858bff;
  }
`;

export const TermsAndConditionsRu = () => {
  return (
    <Container>
      <h1>Условия использования платформы WFP Online Auctions</h1>
      <p>
        Настоящие условия использования («Условия») регулируют использование
        инструмента WFP Online Auctions («Платформа») между пользователем
        Платформы («Пользователь») и Всемирной продовольственной программой
        («ВПП»). Получая доступ к Платформе и ее услугам или используя их, вы
        соглашаетесь соблюдать и быть связанным настоящими Условиями.
      </p>

      <h2>1. Определения</h2>
      <p>
        «Аукцион» означает процесс, посредством которого Пользователи делают
        ставки на товары перечисленные на Платформе.
      </p>
      <p>
        «Участник торгов» означает Пользователя, который размещает ставки на
        лоты, перечисленные на Платформе, достигшего 18 лет и способного
        заключать юридически обязательные контракты.
      </p>
      <p>
        «Шаг увеличения» или «Шаг назначения ставок» относится к заранее
        определенному минимальному увеличению суммы предложения, которое
        Участник торгов может сделать, чтобы подать заявку на конкретный лот.
        «Лот» относится к отдельному предмету или группе предметов, выставленных
        на аукцион ВПП на Платформе. Оно охватывает всю информацию, включая,
        помимо прочего, текстовые описания, изображения, средства массовой
        информации, начальные суммы ставок, резервные цены, продолжительность и
        любые дополнительные положения или условия, указанные ВПП и
        представленные на Платформой в отношении Аукциона.
      </p>
      <p>
        «Предложение» или «Ставка» относится к заявке или предложению,
        сделанному Участником торгов на Платформе для покупки лота на аукционе
        ВПП.
      </p>
      <p>
        «Платформа» относится к онлайн-инструменту аукционов Всемирной
        продовольственной программы.
      </p>
      <p>
        «Резервная цена» означает заранее определенную и нераскрытую минимальную
        ставку, за которую ВПП может забронировать лот.
      </p>
      <p>
        «Требования» относятся к любым юридическим или нормативным требованиям,
        включая но не ограничиваясь лицензиями, разрешениями или регистрациями,
        которым определенные Предметы, выставленные на аукционе на Платформе,
        могут подлежать.
      </p>
      <p>
        «Пользователь» означает физическое или юридическое лицо, использующее
        Платформу.
      </p>
      <p>
        «Учетная запись пользователя» означает учетную запись, принадлежащую
        Пользователю, используемую для получить доступ к платформе.
      </p>
      <p>
        «Выигравшая ставка относится к ставка, определенной ВПП как ставка,
        которой присуждается Лот.
      </p>
      <p>
        «Победитель торгов» относится к участнику торгов, который разместил
        Победившую ставку принятую ВПП.
      </p>
      <p>
        «Знаки ВПП ООН» относятся к названию, эмблеме и аббревиатуре Всемирной
        Продовольственной программы.
      </p>

      <h2>2. Использование платформы</h2>
      <p>
        2.1. Внутренние правила и Учетная запись Пользователя: На Пользователя
        распространяются все применимые правила и положения ВПП в отношении
        внутреннего использования Интернет-ресурсов ВПП. Для доступа к сервисам
        Платформы Пользователь должен использовать свой адрес электронной почты.
        Пользователь соглашается осуществлять доступ к Платформе только с
        использованием собственной учетной записи.
      </p>
      <p>
        2.2. Приостановление и прекращение действия: ВПП может оставить за собой
        право приостановить, или прекратить доступ Пользователя по своему
        усмотрению, в том числе за нарушения настоящих Условий, в частности, в
        случае, если Пользователь размещает Ставки без намерены приобрести и
        получить лоты в течение определенного времени или в силу любых других
        причин. Права и обязанности Пользователя, вытекающие из Ставки
        представленной в отношении Лота, остаются незатронутыми никакими
        изменениями, приостановкой или прекращением действия Учетной записи
        Пользователя.
      </p>

      <h2>3. Участие в торгах</h2>
      <p>
        3.1. Приглашение ВПП принять участие в торгах: демонстрация или
        предоставление ВПП товаров и Лотов на Платформе не являются обязательным
        или подлежащим исполнению предложением ВПП или контрактом между ВПП и
        Участником аукциона. Это не обязывает ВПП принимать любое сделанное
        предложение после Приглашения принять участие в торгах, даже если такое
        Предложение полностью соответствует всем требованиям приглашения к
        участию в торгах. Для всех лотов, выставленных на аукцион, действует
        резервная цена, установленная ВПП. ВПП не обязан раскрывать Резервную
        цену и оставляет за собой право отклонить любые заявки и воздержаться от
        присуждения лота, если заявка не соответствует требованиям Резервной
        цены.
      </p>
      <p>
        3.2. Отмена объявлений: ВПП оставляет за собой право отменить объявление
        в любое время и отозвать вознаграждение полностью или частично по любой
        причине, в том числе в случае, если Победитель торгов не может
        обеспечить полную оплату в течение 5 рабочих дней после окончания
        Аукциона.
      </p>
      <p>
        3.3. Предложение участника торгов: Участник торгов соглашается и
        признает, что предложение представляет собой юридически обязывающее
        обязательство со стороны Участника тендера приобрести Лот, если их
        заявка будет определена ВПП как Выигравшая заявка согласно правилам и
        процедурам аукциона, проводимого на Платформе. Предложение, сделанное
        Участником тендера, должно обозначать конкретную денежную сумму, которую
        Участник торгов предлагает оплатить за Объект. Ответственность за любые
        дополнительные сборы, взимаемые банком участника торгов, налоги, и любые
        другие связанные расходы несет исключительно Участник торгов.
      </p>

      <h2>4. Транзакции</h2>
      <p>
        4.1. Инструкции по оплате: После присуждения Победитель будет
        информирован посредством электронного письма на адрес электронной почты
        Пользователя, это электронной письмо будет включать соответствующие
        платежные инструкции. Использование Платформы бесплатно. Любой платеж в
        пользу ВПП должен быть произведен в течение 5 дней с момента даты
        присуждения посредством банковского перевода. Любые сборы, взимаемые
        банком в отношении этого перевода будет покрыт Участником аукциона.
      </p>
      <p>
        4.2. Доставка: После получения оплаты ВПП информирует Победителя торгов.
        Инструкций по получению. Таким образом, ВПП должна предоставить этот
        предмет и доступны победителю тендера для получения в помещении ВПП.
      </p>
      <p>
        4.3. Возврат и возмещение: ВПП не принимает возврат каких-либо товаров и
        не несет ответственности за возврат средств Победителю торгов.
      </p>
      <p>
        4.4. Комплексная проверка участника торгов: Победитель торгов признает,
        что определенные предметы, выставленные на аукционе на Платформе, могут
        подлежать правовым или нормативным требования, включая, помимо прочего,
        лицензии, разрешения или регистрации («Требования»), которые необходимо
        получить для законного приобретения, использования или владения
        предметом. Победитель торгов несет единоличную ответственность за
        исследование и определение любых применимых требований связанный с
        товаром до размещения ставки. Участвуя в Аукционе Победитель торгов
        подтверждает, что он провел необходимую комплексную проверку для
        определения того, применимы ли какие-либо Требования к предмету, на
        который они делают ставку или который они приобрели.
      </p>

      <h2>5. Обязанность</h2>
      <p>
        5.1. Ограниченная гарантия и отказ от ответственности: ВПП настоящим
        прямо отказывается от и сообщает, что предметы для аукциона
        предоставляются «как есть» и «по мере доступности», без каких-либо явных
        или подразумеваемых гарантий любого рода. Это включает, помимо прочего,
        гарантии товарной пригодности, пригодность для конкретной цели или
        отсутствие нарушений.
      </p>
      <p>
        ВПП не несет ответственности за состояние, качество, подлинность,
        законность или пригодность предметов, выставленных на аукцион. В полной
        мере в той степени, в которой это разрешено законом, ВПП не несет
        ответственности за любой прямой, косвенный, случайный, особый или
        штрафной ущерб, любую потерю прибыли или доходов, понесенную
        непосредственно или косвенно, или другие нематериальные убытки,
        возникающие в результате использования любых предметы, приобретенных
        Пользователем, и любые транзакции, совершенные на Платформе.
      </p>
      <p>
        5.2. Отказ от ответственности Платформы: Использование Платформы и любых
        ее функций осуществляется на собственный риск Пользователя. Платформа,
        ее содержание и функции предоставляются «как есть» и «по мере
        доступности», без гарантий любого рода, явного или подразумеваемого,
        включая, помимо прочего, гарантии товарного состояния, пригодности для
        определенной цели и ненарушение авторских прав. ВПП конкретно не дает
        никаких гарантий или заявлений относительно точности или полноты
        информации представленной на Платформе. ВПП периодически добавляет,
        изменяет, улучшает или обновляет Платформу, материалы и функции
        Платформы без уведомления Пользователей. Пользователь отдельно признает
        и соглашается с тем, что WFP не несет ответственность за поведение
        любого Пользователя.
      </p>

      <h2>6. Защита данных</h2>
      <p>
        6.1. Использование Платформы не требует от Пользователя предоставления
        персональных данных ВПП. Однако, пользователи должны зарегистрироваться
        на платформе для того, чтобы просматривать или делать ставки на товары
        или лоты ВПП. ВПП, как контролер данных, будет обрабатывать (i)
        идентификационные и контактные данные Пользователя для управления его
        регистрацией на Платформе и, как только он/она сделает Предложение или
        Ставку, проинформировать его/ее о статусе заявки; и (ii) информацию,
        связанную с Предложением или Ставкой для исполнение процедуры аукциона и
        принятия решение о распределении Лотов. Эта обработка необходима для
        участия в аукционе ВПП через Платформу и будет осуществляться в
        соответствии с правилами и положениями ВПП.
      </p>
      <p>
        6.2. Доступ к персональным данным пользователя будут иметь только
        уполномоченные лица ВПП на основе обязательных знаний. Передача
        персональных данных третьим лицам не предусмотрена.
      </p>
      <p>
        6.3. ВПП принимает соответствующие меры конфиденциальности и
        безопасности для защиты персональных данных. Персональные данные будут
        храниться до тех пор, пока это необходимо и, в любом случае, в
        соответствии с правилами и положениями ВПП. Права на защиту данных можно
        осуществить в любое время, обратившись в службу поддержки
        support.auctiondigitaloffice@wfp.
      </p>

      <h2>7. Разрешение спора</h2>
      <p>
        7.1. Настоящие Условия использования и любые споры, возникающие из них,
        подлежат разрешению согласно регулировкам исключительно международными
        принципами международного права, исключая какую-либо единую национальную
        правовую систему.
      </p>
      <p>
        7.2. Любой спор, разногласие или претензия, возникающие из или
        относящиеся к настоящим Условиям использования, а также нарушение,
        прекращение или недействительность настоящих Условий использования или
        на использование Сайтов, Материалов и Функций Сайта Пользователем или
        любой контент, доступный в нем, и их конкретные условия использования
        должны быть урегулировано арбитражем в соответствии с Арбитражным
        регламентом UNICITRAL. Состав арбитража в составе 1 (одного) арбитра,
        имеет право распорядиться: (i) о возврате или уничтожении товаров или
        любого имущества, материального или нематериального, или любой
        конфиденциальной информации, предоставленной по настоящему Соглашению;
        (ii) прекращение действия настоящего Соглашения; или (iii) принять любые
        другие защитные меры в отношении товаров, услуг или любого другого
        имущества, будь то материальное или нематериального имущество, или любой
        конфиденциальной информации, предоставленной в соответствии с настоящим
        Соглашением, как целесообразно, все в соответствии с полномочиями
        арбитражного суда в соответствии со статьей 26 («Временные меры») и
        статьей 34. («Форма и действие арбитражного решения») Арбитражного
        регламента UNICITRAL. Арбитражный суд не имеет полномочий присуждать
        штрафные санкции. ВПП и Пользователь обязаны соблюдать любое арбитражное
        решение, вынесенное в качестве результат такого арбитража как
        окончательное решение любого такого арбитраж. Языком любого такого
        разбирательства является английский, а Местом проведения будет Рим.
      </p>

      <h2>8. Сохранение иммунитета</h2>
      <p>
        Ничто в настоящих Условиях использования не подразумевает отказ, явный
        или подразумеваемый, ВПП, ООН и Продовольственной и сельскохозяйственной
        организации Организации Объединенных Наций от любых привилегий и
        иммунитетов, которыми они пользуются в соответствии с Конвенцией 1946
        года о привилегиях и иммунитетах Организации Объединенных Наций,
        Конвенция 1947 года о привилегиях и иммунитетах Специализированных
        агентств, обычного международное право, других соответствующие
        международных или национальных соглашениями и внутреннего
        законодательства.
      </p>

      <h2>9. Miscellaneous</h2>
      <p>
        9.1. Поправки к Условиям: ВПП оставляет за собой исключительное право по
        своему усмотрению (i) изменить, ограничить или прекратить доступ к
        Платформе в любом отношении; и (ii) изменить настоящие Условия
        использования в одностороннем порядке.
      </p>
      <p>
        9.2. Использование названия или маркировки: Знаки ВПП являются
        исключительной собственностью ВПП и защищены международным правом.
        Несанкционированное использование Символики ВПП запрещена. Символика ВПП
        не может быть воспроизведена или использована любым способом без
        предварительного письменного разрешения ВПП. Упоминание или отсутствие
        конкретных компаний, их продуктов или торговых марок в Платформе не
        подразумевает какого-либо одобрения или осуждения со стороны ВПП.
      </p>
      <p>
        9.3. Делимость: Каждое положение настоящих Условий считается отделимым,
        так что если какое-либо положение противоречит действующему
        законодательству или установлено, что какое-либо положение настоящего
        Соглашения не имеет исковой силы, стороны намереваются обеспечить
        соблюдение настоящего Соглашения, как если бы неисполнимое положение
        отсутствовало и какие-либо частично действительное и подлежащие
        исполнению положение должно применяться в той степени, в которой оно
        осуществимо.
      </p>
      <p>
        9.4. Полное соглашение: если ВПП примет Ставку частично или полностью, и
        примет решение о выборе Выигравшей ставки, настоящие Условия и Положения
        в сочетании с электронным письмом с подтверждением от ВПП о Выигравшей
        ставке является обязательным и подлежащим исполнению контрактом между
        ВПП и Победителем торгов. В той мере, в какой существует более одной
        языковой версии настоящих Условий, и существует какое-либо
        несоответствие между версиями, английская версия всегда должна
        преобладать.
      </p>
      <p>
        9.5. Контактное лицо: Если у вас возникли какие-либо вопросы по поводу
        настоящих условий, Платформы и любые вопросы, связанные с каким-либо
        конкретным аукционом, пожалуйста, свяжитесь с
        support.auctiondigitaloffice@wfp.org
      </p>
    </Container>
  );
};
