import {
  createContext,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Axios from "axios";

import { API } from "@api";
import { Nullable, UserByEmail } from "@utils";
import { logout } from "./ciam";

export const LOCAL_STORAGE_KEY = "wfp-gas/session";

export type Session = {
  user: UserByEmail;
  accessToken: string;
  refreshToken: string;
};

export interface IAuthenticationContext {
  signIn: (token: string, refreshToken: string, email: string) => Promise<void>;
  logout: () => Promise<void>;
  isAuthenticated: boolean;
  isUserInactive: boolean;
  session: Nullable<Session>;
  currentUser: Nullable<UserByEmail>;
}

const DEFAULT_CONTEXT_VALUE: IAuthenticationContext = {
  async signIn(_token: string, _email: string) {},
  async logout() {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
  },
  isAuthenticated: false,
  isUserInactive: false,
  session: null,
  currentUser: null,
};

export const AuthenticationContext = createContext(DEFAULT_CONTEXT_VALUE);
export const AuthenticationContextProvider = AuthenticationContext.Provider;

export const AuthenticationProvider: FC = ({ children }) => {
  const sessionStorated = localStorage.getItem(LOCAL_STORAGE_KEY);
  const sessionParsed: Nullable<Session> = sessionStorated
    ? JSON.parse(sessionStorated)
    : null;
  const [accessToken, setAccessToken] = useState<Nullable<string>>(null);
  const [isUserInactive, setUserInactive] = useState<boolean>(false);
  const [session, setSession] = useState<Nullable<Session>>(sessionParsed);
  const isAuthenticated = !!session?.accessToken;

  const setAuthenticationHeader = (token: string) => {
    Axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  };

  const removeAuthenticationHeader = () => {
    delete Axios.defaults.headers.common["Authorization"];
  };

  const setAuthenticatedUser = useCallback(
    async (token: string, refreshToken: string, email: string) => {
      try {
        const user = await API.users.byEmail(email);

        const session = {
          accessToken: token!,
          user,
          refreshToken,
        };
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(session));
        setSession(session || null);
        setUserInactive(false);
      } catch (error) {
        setSession(null);
        if ((error as Error).message.includes("401")) {
          setUserInactive(true);
        }
      }
    },
    []
  );

  const removeAuthenticatedUser = useCallback(() => {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
    setSession(null);
    removeAuthenticationHeader();
  }, []);

  useEffect(() => {
    const session = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (session && isAuthenticated) {
      const parsedSession: Session = JSON.parse(session);
      setSession(parsedSession);

      setAuthenticationHeader(parsedSession.accessToken);
    }
  }, [
    accessToken,
    setAuthenticatedUser,
    removeAuthenticatedUser,
    isAuthenticated,
  ]);

  const currentUser = useMemo(() => (session ? session.user : null), [session]);

  const value = useMemo(
    () => ({
      ...DEFAULT_CONTEXT_VALUE,
      signIn: async (token: string, refreshToken: string, email: string) => {
        try {
          setAuthenticationHeader(token);
          setAccessToken(token);
          return setAuthenticatedUser(token, refreshToken, email);
        } catch (error) {
          // TODO: Handle errors
        }
      },
      logout: async () => {
        try {
          removeAuthenticatedUser();
          logout();
        } catch (error) {
          // TODO: Handle errors
        }
      },
      session,
      isAuthenticated: Boolean(session),
      isUserInactive,
      currentUser,
    }),
    [
      session,
      currentUser,
      isUserInactive,
      setAuthenticatedUser,
      removeAuthenticatedUser,
    ]
  );

  return (
    <AuthenticationContextProvider value={value}>
      {children}
    </AuthenticationContextProvider>
  );
};
