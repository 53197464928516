import { FC, Suspense, useEffect } from "react";
import { Route, useHistory } from "react-router";
import styled from "styled-components";

import { ApplicationContextValues, useApplication } from "@applications";
import { Header, LoadingPage } from "@components";
import { ToasterErrorProvider } from "@contexts";
import GlobalStyle from "@styles/GlobalStyle";
import Theme from "@styles/Theme";
import { CONTEXT_LINK, getLinksAndRoutes, Routes } from "./routesFromContext";
import { THEME_FROM_CONTEXT } from "./themeFromContext";

const BackgroundImage = styled.img.attrs({ src: "/bg.jpeg" })`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const Container = styled.main`
  overflow: hidden;
  display: flex;
  width: 100%;
  flex-direction: column;
  min-height: 100vh;
  background-size: cover;
  z-index: -1;

  &::before {
    content: "";
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.6);
  }
`;

export const DefaultApplication: FC = () => {
  const history = useHistory();
  const { context, changeContext } = useApplication();
  const { links, routes } = getLinksAndRoutes(context);
  const contextTheme = THEME_FROM_CONTEXT[context];

  useEffect(() => {
    let currentRouteValidContexts: ApplicationContextValues[] = [];
    const splittedRoute = history.location.pathname.split("/").filter(Boolean);

    // It checks for routes like `/link/123`, this should be transformed into `/link/:id`
    if (splittedRoute.length > 1) {
      let fullPath = "";
      splittedRoute.forEach((path) => {
        if (Number.isNaN(Number(path))) {
          fullPath = `${fullPath}/${path}`;
        } else {
          fullPath = `${fullPath}/:id`;
        }
      });
      currentRouteValidContexts = CONTEXT_LINK[fullPath as Routes];
    } else {
      currentRouteValidContexts =
        CONTEXT_LINK[history.location.pathname as Routes];
    }

    if (!currentRouteValidContexts) return;

    if (currentRouteValidContexts.length === 1) {
      const [validContext] = currentRouteValidContexts;
      if (validContext !== context) {
        changeContext(validContext, false);
      }
    } else if (currentRouteValidContexts.length > 1) {
      if (!currentRouteValidContexts.includes(context)) {
        changeContext(currentRouteValidContexts[0], false);
      }
    }
  }, [history, changeContext, context]);

  return (
    <Theme {...contextTheme}>
      <GlobalStyle />
      <ToasterErrorProvider>
        <Container>
          <BackgroundImage />
          <Header {...{ links }} />
          <Suspense fallback={<LoadingPage />}>
            {routes.map(({ to, component }) => (
              <Route exact path={to} component={component} key={to} />
            ))}
          </Suspense>
        </Container>
      </ToasterErrorProvider>
    </Theme>
  );
};
