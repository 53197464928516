import { mixed, number, object, string } from "yup";

export const VALIDATION_SCHEMA = object({
  country: number().required("errors.country.required"),
  city: number().required("errors.city.required"),
  name: string().required("errors.name.required"),
  surname: string().required("errors.surname.required"),
  email: string()
    .email("errors.email.invalid")
    .required("errors.email.required"),
  description: string(),
  file: mixed().required("errors.file.required"),
});
