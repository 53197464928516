import { ComponentType } from "react";

import { useAuthentication } from "@authentication";
import {
  excludeFromChildren,
  findChildByType,
  Role,
  WithChildren,
} from "@utils";

export interface WithRoleProps extends WithChildren {
  role: Role;
}

export interface OneOfProps extends WithChildren {
  roles: Role[];
}

export function WithRole({ children, role }: WithRoleProps) {
  const { currentUser } = useAuthentication();

  const fallback = findChildByType(
    children,
    WithRole.Fallback as ComponentType
  );

  if (!currentUser || currentUser.role !== role) return <>{fallback}</>;

  const childrenWithoutFallback = excludeFromChildren(
    children,
    WithRole.Fallback as ComponentType
  );

  return <>{childrenWithoutFallback}</>;
}

WithRole.OneOf = function OneOf({ children, roles }: OneOfProps) {
  const { currentUser } = useAuthentication();

  const fallback = findChildByType(
    children,
    WithRole.Fallback as ComponentType
  );

  if (!currentUser || !roles.includes(currentUser.role)) return <>{fallback}</>;

  const childrenWithoutFallback = excludeFromChildren(
    children,
    WithRole.Fallback as ComponentType
  );

  return <>{childrenWithoutFallback}</>;
};

WithRole.Admin = function Editor({ children }: WithChildren) {
  return <WithRole role={Role.ADMIN}>{children}</WithRole>;
};

WithRole.Lpe = function Editor({ children }: WithChildren) {
  return <WithRole role={Role.LPE}>{children}</WithRole>;
};

WithRole.Employee = function Editor({ children }: WithChildren) {
  return <WithRole role={Role.EMPLOYEE}>{children}</WithRole>;
};

WithRole.GasTeam = function Editor({ children }: WithChildren) {
  return <WithRole role={Role.GAS_TM}>{children}</WithRole>;
};

WithRole.Fallback = function Fallback({ children }: WithChildren) {
  return <>{children}</>;
};
