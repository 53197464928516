import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { API } from "@api";
import { IAssetsFilters, ILotsFilters } from "@components";
import { useQuery } from "@hooks";
import { IAsset, ILot, Nullable, Pagination } from "@utils";

const PAGE_SIZE = 100;

export const ASSETS_PAGE_NAME = "assets-page";
export const LOTS_PAGE_NAME = "lots-page";

export const DEFAULT_LOTS_FILTERS: ILotsFilters = {
  acquisitionValueFrom: undefined,
  acquisitionValueTo: undefined,
  marketValueFrom: undefined,
  marketValueTo: undefined,
  numberFrom: undefined,
  numberTo: undefined,
  reservePriceFrom: undefined,
  reservePriceTo: undefined,
};

export const DEFAULT_ASSETS_FILTERS: IAssetsFilters = {
  acquisitionDateFrom: undefined,
  acquisitionDateTo: undefined,
  approvalDateFrom: undefined,
  approvalDateTo: undefined,
  brand: [],
  family: [],
  functionalLoc: [],
  model: [],
  stateOfUse: [],
};

export interface IAssetsAndLotsContext {
  assets: IAsset[];
  lots: ILot[];
  getAssetsById: () => Promise<void>;
  getLotsById: () => Promise<void>;
  loadingAssets: boolean;
  loadingLots: boolean;
  hasError: boolean;
  assetsFilters: Nullable<IAssetsFilters>;
  applyAssetsFilters: (values: IAssetsFilters) => void;
  lotsFilters: Nullable<ILotsFilters>;
  applyLotsFilters: (values: ILotsFilters) => void;
  initialLotsFiltersValues: ILotsFilters;
  initialAssetsFiltersValues: IAssetsFilters;
  assetsPage: number;
  lotsPage: number;
  assetsPagination: Pagination;
  lotsPagination: Pagination;
  selectedAssets: number[];
  selectedLots: number[];
  setSelectedAssets: Dispatch<SetStateAction<number[]>>;
  setSelectedLots: Dispatch<SetStateAction<number[]>>;
  allAssetsSelected: boolean;
  allLotsSelected: boolean;
}

const DEFAULT_CONTEXT_VALUE: IAssetsAndLotsContext = {
  assets: [],
  lots: [],
  getAssetsById: async () => {},
  getLotsById: async () => {},
  loadingAssets: false,
  loadingLots: false,
  hasError: false,
  assetsFilters: null,
  lotsFilters: null,
  initialLotsFiltersValues: DEFAULT_LOTS_FILTERS,
  initialAssetsFiltersValues: DEFAULT_ASSETS_FILTERS,
  applyAssetsFilters: (_values: IAssetsFilters) => {},
  applyLotsFilters: (_values: ILotsFilters) => {},
  assetsPage: 1,
  lotsPage: 1,
  assetsPagination: {
    documentsCount: 0,
    page: 1,
    totalPages: 0,
    documentsPerPage: 0,
  },
  lotsPagination: {
    documentsCount: 0,
    page: 1,
    totalPages: 0,
    documentsPerPage: 0,
  },
  selectedAssets: [],
  selectedLots: [],
  setSelectedAssets: () => {},
  setSelectedLots: () => {},
  allAssetsSelected: false,
  allLotsSelected: false,
};

export const AssetsAndLotsContext = createContext(DEFAULT_CONTEXT_VALUE);
export const AssetsAndLotsContextProvider = AssetsAndLotsContext.Provider;

interface Props {
  auctionId: number;
}

export const AssetsAndLotsProvider: FC<Props> = ({ auctionId, children }) => {
  const query = useQuery();

  const [assets, setAssets] = useState<IAsset[]>([]);
  const [loadingAssets, setLoadingAssets] = useState(false);
  const [lots, setLots] = useState<ILot[]>([]);
  const [loadingLots, setLoadingLots] = useState(false);
  const [hasError, setError] = useState<boolean>(false);

  const [assetsFilters, setAssetsFilters] =
    useState<Nullable<IAssetsFilters>>(null);
  const [lotsFilters, setLotsFilters] = useState<Nullable<ILotsFilters>>(null);

  const [assetsPagination, setAssetsPagination] = useState<Pagination>({
    documentsCount: 0,
    page: 1,
    totalPages: 0,
    documentsPerPage: 0,
  });
  const [lotsPagination, setLotsPagination] = useState<Pagination>({
    documentsCount: 0,
    page: 1,
    totalPages: 0,
    documentsPerPage: 0,
  });

  const [selectedAssets, setSelectedAssets] = useState<number[]>(
    JSON.parse(sessionStorage.getItem("selectedAssets") ?? "[]")
  );
  const [selectedLots, setSelectedLots] = useState<number[]>(
    JSON.parse(sessionStorage.getItem("selectedLots") ?? "[]")
  );

  useEffect(() => {
    return () => {
      sessionStorage.removeItem("selectedAssets");
      sessionStorage.removeItem("selectedLots");
    };
  }, []);

  useEffect(() => {
    sessionStorage.setItem("selectedAssets", JSON.stringify(selectedAssets));
  }, [selectedAssets]);

  useEffect(() => {
    sessionStorage.setItem("selectedLots", JSON.stringify(selectedLots));
  }, [selectedLots]);

  const allAssetsSelected = useMemo(() => {
    return (
      Boolean(assets.length > 0) &&
      assets.every((asset) => selectedAssets.includes(asset.id))
    );
  }, [selectedAssets, assets]);

  const allLotsSelected = useMemo(() => {
    return (
      Boolean(lots.length > 0) &&
      lots.every((lot) => selectedLots.includes(lot.id))
    );
  }, [selectedLots, lots]);

  const assetsPage = Number(query.get(ASSETS_PAGE_NAME) ?? 1);
  const lotsPage = Number(query.get(LOTS_PAGE_NAME) ?? 1);

  const initialLotsFiltersValues: ILotsFilters = useMemo(
    () => ({
      ...DEFAULT_LOTS_FILTERS,
      acquisitionValueFrom: lotsFilters?.acquisitionValueFrom,
      acquisitionValueTo: lotsFilters?.acquisitionValueTo,
      marketValueFrom: lotsFilters?.marketValueFrom,
      marketValueTo: lotsFilters?.marketValueTo,
      numberFrom: lotsFilters?.numberFrom,
      numberTo: lotsFilters?.numberTo,
      reservePriceFrom: lotsFilters?.reservePriceFrom,
      reservePriceTo: lotsFilters?.reservePriceTo,
    }),
    [lotsFilters]
  );

  const initialAssetsFiltersValues: IAssetsFilters = useMemo(
    () => ({
      ...DEFAULT_ASSETS_FILTERS,
      acquisitionDateFrom: assetsFilters?.acquisitionDateFrom,
      acquisitionDateTo: assetsFilters?.acquisitionDateTo,
      approvalDateFrom: assetsFilters?.approvalDateFrom,
      approvalDateTo: assetsFilters?.approvalDateTo,
      brand: assetsFilters?.brand,
      family: assetsFilters?.family,
      functionalLoc: assetsFilters?.functionalLoc,
      model: assetsFilters?.model,
      stateOfUse: assetsFilters?.stateOfUse,
    }),
    [assetsFilters]
  );

  const applyLotsFilters = useCallback((values: ILotsFilters) => {
    setLotsFilters(values);
  }, []);

  const applyAssetsFilters = useCallback((values: IAssetsFilters) => {
    setAssetsFilters(values);
  }, []);

  const getAssetsById = useCallback(async () => {
    setLoadingAssets(true);

    try {
      const assets = await API.auctions.assetsById(
        Number(auctionId),
        assetsPage ?? 1,
        assetsFilters?.acquisitionDateFrom,
        assetsFilters?.acquisitionDateTo,
        assetsFilters?.approvalDateFrom,
        assetsFilters?.approvalDateTo,
        assetsFilters?.brand,
        assetsFilters?.family,
        assetsFilters?.functionalLoc,
        assetsFilters?.model,
        assetsFilters?.stateOfUse,
        true
      );
      const totalPages = Math.ceil(assets.count / PAGE_SIZE);

      setAssets(assets.results);
      setAssetsPagination({
        documentsCount: assets.count,
        page: assetsPage,
        totalPages,
        documentsPerPage: PAGE_SIZE,
      });
      setLoadingAssets(false);
      setError(false);
    } catch (_) {
      setError(true);
      setLoadingAssets(false);
    }
  }, [auctionId, assetsFilters, assetsPage]);

  const getLotsById = useCallback(async () => {
    setLoadingLots(true);

    try {
      const lots = await API.auctions.lotsById(
        Number(auctionId),
        lotsPage ?? 1,
        lotsFilters?.numberFrom,
        lotsFilters?.numberTo,
        lotsFilters?.reservePriceFrom,
        lotsFilters?.reservePriceTo,
        lotsFilters?.acquisitionValueFrom,
        lotsFilters?.acquisitionValueTo,
        lotsFilters?.marketValueFrom,
        lotsFilters?.marketValueTo
      );
      const totalPages = Math.ceil(lots.count / PAGE_SIZE);

      setLots(lots.results);
      setLotsPagination({
        documentsCount: lots.count,
        page: lotsPage,
        totalPages,
        documentsPerPage: PAGE_SIZE,
      });
      setLoadingLots(false);
      setError(false);
    } catch (_) {
      setError(true);
      setLoadingLots(false);
    }
  }, [auctionId, lotsFilters, lotsPage]);

  useEffect(() => {
    getAssetsById();
    getLotsById();
  }, [getAssetsById, getLotsById]);

  const value = useMemo(
    () => ({
      ...DEFAULT_CONTEXT_VALUE,
      assets,
      lots,
      getAssetsById,
      getLotsById,
      loadingAssets,
      loadingLots,
      hasError,
      lotsFilters,
      applyLotsFilters,
      assetsFilters,
      applyAssetsFilters,
      initialAssetsFiltersValues,
      initialLotsFiltersValues,
      assetsPage,
      lotsPage,
      assetsPagination,
      lotsPagination,
      selectedAssets,
      selectedLots,
      setSelectedAssets,
      setSelectedLots,
      allAssetsSelected,
      allLotsSelected,
    }),
    [
      applyAssetsFilters,
      applyLotsFilters,
      assets,
      assetsFilters,
      assetsPage,
      assetsPagination,
      getAssetsById,
      getLotsById,
      hasError,
      initialAssetsFiltersValues,
      initialLotsFiltersValues,
      loadingAssets,
      loadingLots,
      lots,
      lotsFilters,
      lotsPage,
      lotsPagination,
      selectedAssets,
      selectedLots,
      setSelectedAssets,
      setSelectedLots,
      allAssetsSelected,
      allLotsSelected,
    ]
  );

  return (
    <AssetsAndLotsContextProvider value={value}>
      {children}
    </AssetsAndLotsContextProvider>
  );
};
