import { Props as TagProps } from "@components/Tag";
import { DisposalRequestStatus } from "@utils";

export function getColorAndLabelFromDisposalRequestStatus(
  status: DisposalRequestStatus
): {
  color: TagProps["colorTheme"];
  label: string;
} {
  switch (status) {
    case DisposalRequestStatus.OPEN:
      return {
        color: "orange",
        label: "pages.manageRequests.statuses.open",
      };
    case DisposalRequestStatus.APPROVED:
      return {
        color: "lightBlue",
        label: "pages.manageRequests.statuses.approved",
      };
    case DisposalRequestStatus.IN_PROGRESS:
      return {
        color: "yellow",
        label: "pages.manageRequests.statuses.inProgress",
      };
    case DisposalRequestStatus.BLOCKED:
      return {
        color: "grey",
        label: "pages.manageRequests.statuses.blocked",
      };
    case DisposalRequestStatus.CLOSED:
      return {
        color: "green",
        label: "pages.manageRequests.statuses.closed",
      };
  }
}
