import { ICity } from "./types";

export function getCityName(city: ICity) {
  let cityName = city.name;
  if (city.region && city.region.name.trim() !== "") {
    if (city.subregion && city.subregion.name.trim() !== "") {
      cityName = `${cityName}, ${city.subregion.name}`;
    }
    cityName = `${cityName}, ${city.region.name}`;
  }

  return cityName;
}
