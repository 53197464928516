import { number, object, string } from "yup";

export const MIN_LOT_VALUE = 1;
export const MIN_RESERVE_PRICE_VALUE = 0.01;
export const MAX_RESERVE_PRICE_VALUE = 999999999.99;

export const VALIDATION_SCHEMA = object({
  lot: number()
    .min(MIN_LOT_VALUE, "lotForm.errors.lot.number")
    .integer("lotForm.errors.lot.number")
    .required("lotForm.errors.lot.required"),
  reservePrice: number()
    .required("lotForm.errors.reservePrice.required")
    .positive("lotForm.errors.reservePrice.min")
    .max(MAX_RESERVE_PRICE_VALUE, "lotForm.errors.reservePrice.max")
    .test(
      "maxDigitsAfterDecimal",
      "lotForm.errors.reservePrice.decimals",
      (number) => /^\d+(\.\d{1,2})?$/.test(number?.toString() ?? "")
    ),
  name: string().required("lotForm.errors.name.required"),
});
