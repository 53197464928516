import styled from "styled-components";

export interface Props {
  active: boolean;
  disabled?: boolean;
}

export const Switch = styled.div<Props>`
  position: relative;
  width: 40px;
  height: 22px;
  border-radius: 11px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  background-color: ${({ theme, active }) =>
    active ? theme.secondaryColor : theme.darkestBlue};
  transition: background-color 0.5s;
  margin-top: ${({ theme }) => theme.smallestMargin};

  :disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  ::after {
    content: "";
    position: absolute;
    left: ${({ active }) => (active ? "calc(50% - 1px)" : "1px")};
    top: 1px;
    height: 0;
    width: 0;
    border: 10px solid white;
    border-radius: 11px;
    transition: left 0.5s;
  }
`;
