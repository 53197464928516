import { FC, useState } from "react";

import { Icon, ToggleButton } from "@components";

export function useGroupTable<T extends { id: number }>() {
  const [isGroupVisible, setGroupVisible] = useState<Record<number, boolean>>(
    {}
  );

  const setGroupVisibility = (item: T) => {
    const groupVisibility = { ...isGroupVisible };
    if (groupVisibility[item.id]) {
      groupVisibility[item.id] = false;
    } else {
      groupVisibility[item.id] = true;
    }
    setGroupVisible(groupVisibility);
  };

  const ToggleGroup: FC<{ item: T }> = ({ item }) => (
    <ToggleButton
      onClick={() => {
        setGroupVisibility(item);
      }}
    >
      {isGroupVisible[item.id] ? <Icon.UpArrow /> : <Icon.DownArrow />}
    </ToggleButton>
  );

  return { ToggleGroup, isGroupVisible, setGroupVisibility };
}
