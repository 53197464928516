import styled from "styled-components";

export const H2 = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme }) => theme.bigFontSize};
  color: ${({ theme }) => theme.bodyColor};
  margin: 0 0 ${({ theme }) => theme.tripleMargin} 0;
  text-align: center;
  font-weight: 400;
`;
