import styled from "styled-components";

export const Label = styled.label`
  font-size: ${({ theme }) => theme.smallFontSize};
  font-family: ${({ theme }) => theme.fontFamily};
  text-transform: uppercase;

  &.mb-6 {
    margin-bottom: ${({ theme }) => theme.smallestMargin};
  }

  &.mr-6 {
    margin-right: ${({ theme }) => theme.smallestMargin};
  }
`;
