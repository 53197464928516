import { createContext, FC, useEffect, useState } from "react";
import styled from "styled-components";

import { Button as BaseButton, ErrorMessage, Modal } from "@components";
import { useI18n } from "@i18n";
import { Error, IError } from "@utils";

interface IToasterErrorContextValue {
  visible: boolean;
  error: IError;
  open: (error: IError) => void;
  close: () => void;
}

export const ToasterErrorContext = createContext<IToasterErrorContextValue>({
  visible: false,
  error: { code: Error.Frontend, detail: "Generic error" },
  open: (_error: IError) => {},
  close: () => {},
});

const Button = styled(BaseButton)`
  border: 1px solid ${({ theme }) => theme.quaternaryColor};
  background-color: transparent;
  color: ${({ theme }) => theme.quaternaryColor};
`;

export const ToasterErrorProvider: FC = ({ children }) => {
  const { t } = useI18n();

  const [visible, setVisible] = useState(false);
  const [error, setError] = useState<IError>({
    code: Error.Frontend,
    detail: "Generic error",
  });
  const open = (error: IError) => {
    setError(error);
    setVisible(true);
  };
  const close = () => setVisible(false);

  useEffect(() => {
    if (visible) {
      setInterval(() => {
        close();
      }, 20000);
    }
  }, [visible]);

  return (
    <ToasterErrorContext.Provider value={{ visible, open, close, error }}>
      {children}
      {visible && (
        <Modal
          onClose={close}
          actions={<Button onClick={close}>{t<string>("cancel")}</Button>}
          title={t("errors.generic.title")}
        >
          <ErrorMessage error={error} />
        </Modal>
      )}
    </ToasterErrorContext.Provider>
  );
};
