import { FC, useMemo } from "react";
import styled from "styled-components";

import { Languages, useI18n } from "@i18n";

const Container = styled.div`
  display: flex;
  align-items: center;
  flex: 0;
`;

const Selector = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.darkBlue};
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: 1px;
  margin-left: 10px;
  height: 25px;
`;

const ItemSelector = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: ${({ theme }) => theme.smallestPadding};
  border-radius: ${({ theme }) => theme.borderRadius};
  width: 25px;

  &.active {
    background-color: ${({ theme }) => theme.contextTheme.color};
  }
`;

export const LanguageSelector: FC = () => {
  const { t, i18n, changeLanguage } = useI18n();
  const currentLanguage = useMemo(() => i18n.language, [i18n.language]);

  return (
    <Container>
      {t("language")}
      <Selector>
        <ItemSelector
          className={currentLanguage === Languages.English ? "active" : ""}
          onClick={() => changeLanguage(Languages.English)}
          data-testid="en"
        >
          {t("languages.english")}
        </ItemSelector>
        <ItemSelector
          className={currentLanguage === Languages.French ? "active" : ""}
          onClick={() => changeLanguage(Languages.French)}
          data-testid="fr"
        >
          {t("languages.french")}
        </ItemSelector>
      </Selector>
    </Container>
  );
};
