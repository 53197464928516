import { useEffect, useState } from "react";
import React from "react";
import { BrowserRouter, Switch, useLocation } from "react-router-dom";
import Axios, { AxiosError } from "axios";

import { ApplicationProvider, DefaultApplication } from "@applications";
import { useAuthentication } from "@authentication";
import {
  onCiamLogin,
  REDIRECT_HOMEPAGE,
  REDIRECT_URI,
  refreshAccessToken,
  retrieveTokens,
} from "@authentication/ciam";
import { InactiveUserPage, LoadingPage, ScrollTop } from "@components";
import { initializeTranslations, useI18n } from "@i18n";
import * as Sentry from "@sentry/react";
import { getEnvironment, IError } from "@utils";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [Sentry.replayIntegration()],
  environment: getEnvironment(process.env.REACT_APP_API_URL),
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

Axios.defaults.baseURL = process.env.REACT_APP_API_URL;

initializeTranslations();

function App() {
  const { signIn, session, isUserInactive, isAuthenticated } =
    useAuthentication();
  const [loading, setLoading] = useState<boolean>(false);
  const { i18n } = useI18n();

  Axios.interceptors.response.use(
    (response) => response,
    (error: AxiosError<IError>) => {
      if (error.response?.status === 401) {
        if (session?.refreshToken) {
          return refreshAccessToken(session.refreshToken).then(
            ({ id_token, refresh_token, email }) => {
              return signIn(id_token, refresh_token, email).then(() => {
                return Axios.request(error.config);
              });
            }
          );
        }
      }
      return Promise.reject(error);
    }
  );

  const acquireToken = () => {
    const currentUrl = window.location.href;
    if (currentUrl.startsWith(REDIRECT_URI!)) {
      const callbackUrl = new URL(window.location.href);
      const code = callbackUrl.searchParams.get("code");

      if (code) {
        setLoading(true);
        return retrieveTokens(code).then(
          ({ id_token, refresh_token, email }) => {
            return signIn(id_token, refresh_token, email).then(() => {
              window.location.href = REDIRECT_HOMEPAGE!;
              setLoading(false);
            });
          }
        );
      }
      throw new Error("No code in callback");
    }

    if (!isAuthenticated) {
      onCiamLogin();
    }
  };

  useEffect(() => {
    Axios.defaults.headers.common["Accept-Language"] = i18n.language;
  }, [i18n.language]);

  useEffect(() => {
    acquireToken();
  }, []);

  return (
    <BrowserRouter>
      <Switch>
        <ApplicationProvider>
          <ScrollTop>
            {isAuthenticated ? (
              <>
                {loading ? (
                  <LoadingPage />
                ) : (
                  <>
                    {isUserInactive ? (
                      <InactiveUserPage />
                    ) : (
                      <DefaultApplication />
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                <LoadingPage />
              </>
            )}
          </ScrollTop>
        </ApplicationProvider>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
