import { FC } from "react";
import styled from "styled-components";

import { H1, IconByName, IconName, P } from "@components";

export interface Props {
  title: string;
  description?: string;
  subTitle?: string;
  subTitleIcon?: IconName;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Description = styled(P)`
  max-width: 600px;
  margin-bottom: ${({ theme }) => theme.bigMargin};
`;

const Subtitle = styled.label`
  display: flex;
  font-size: ${({ theme }) => theme.mediumBigFontSize};
  align-items: center;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.tripleMargin};

  svg {
    width: ${({ theme }) => theme.baseSize};
    fill: ${({ theme }) => theme.bodyColor};
    margin-right: 10px;
  }
`;

export const WelcomeCard: FC<Props> = ({
  title,
  description,
  subTitle,
  subTitleIcon,
  children,
}) => {
  const SubTitle = () => (
    <>
      {subTitle && (
        <Subtitle>
          {subTitleIcon && <IconByName name={subTitleIcon} />}
          {subTitle}
        </Subtitle>
      )}
    </>
  );

  return (
    <Container>
      <H1>{title}</H1>
      <SubTitle />
      {description && <Description textStyle="big">{description}</Description>}
      {children}
    </Container>
  );
};
