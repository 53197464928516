import { FC, InputHTMLAttributes } from "react";
import { useField } from "formik";
import styled from "styled-components";

import { Asterisk } from "./Asterisk";
import { FormError } from "./FormError";
import { Label } from "./Label";
import { Switch } from "./Switch";

interface Props extends InputHTMLAttributes<HTMLDivElement> {
  name: string;
  label?: string;
  active?: boolean;
  resetForm?: () => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${({ theme }) => theme.smallestPadding};
  width: 100%;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SwitchField: FC<Props> = ({
  name,
  label,
  id,
  active = false,
  required,
  disabled,
  ...props
}) => {
  const [field, , { setValue }] = useField(name);

  return (
    <Container>
      {label && (
        <LabelContainer>
          <Label className="mb-6" htmlFor={id ?? name} children={label} />
          {required && <Asterisk />}
        </LabelContainer>
      )}
      <Switch
        id={id ?? name}
        active={field.value}
        data-testid="switch"
        data-teststate={field.value ? "active" : "inactive"}
        {...field}
        {...props}
        onClick={() => {
          if (disabled) return;
          setValue(!field.value);
          if (props.resetForm !== undefined) {
            props.resetForm();
            setValue(!field.value);
          }
        }}
        disabled={disabled}
      />
      <FormError name={name} />
    </Container>
  );
};
