import { createContext, FC, useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router";

import { getLinksAndRoutes } from "@applications/routesFromContext";
import { ApplicationContextValues } from "./ApplicationContext.enum";

export const LOCAL_STORAGE_KEY = "wfp-gas/application";

export interface IApplicationContext {
  context: ApplicationContextValues;
  changeContext: (
    context: ApplicationContextValues,
    shouldRedirect?: boolean
  ) => void;
  isPublic: boolean;
  isGAD: boolean;
}

const DEFAULT_CONTEXT_VALUE: IApplicationContext = {
  context: ApplicationContextValues.Public,
  changeContext: () => {},
  isPublic: true,
  isGAD: false,
};

export const ApplicationContext = createContext(DEFAULT_CONTEXT_VALUE);
export const ApplicationContextProvider = ApplicationContext.Provider;

export const ApplicationProvider: FC = ({ children }) => {
  const history = useHistory();
  const [context, setContext] = useState<ApplicationContextValues>(
    (localStorage.getItem(LOCAL_STORAGE_KEY) as ApplicationContextValues) ??
      ApplicationContextValues.Public
  );

  const changeContext = useCallback(
    (newContext: ApplicationContextValues, shouldRedirect: boolean = true) => {
      setContext(newContext);
      localStorage.setItem(LOCAL_STORAGE_KEY, newContext);

      if (shouldRedirect) {
        const firstRoute = getLinksAndRoutes(newContext).links[0].to;
        history.push(firstRoute);
      }
    },
    [setContext, history]
  );

  const isPublic = useMemo(
    () => context === ApplicationContextValues.Public,
    [context]
  );

  const isGAD = useMemo(
    () => context === ApplicationContextValues.DigitalOffice,
    [context]
  );

  const value = useMemo(
    () => ({
      ...DEFAULT_CONTEXT_VALUE,
      context,
      changeContext,
      isPublic,
      isGAD,
    }),
    [context, changeContext, isGAD, isPublic]
  );

  return (
    <ApplicationContextProvider value={value}>
      {children}
    </ApplicationContextProvider>
  );
};
