import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Form as BaseForm, Formik, FormikHelpers } from "formik";
import styled from "styled-components";

import { API } from "@api";
import { useI18n } from "@i18n";
import { device } from "@styles/breakpoints";
import { getCityName, ICity, ICountry, UserByEmail } from "@utils";
import { Button as BaseButton } from "../../Button";
import { FileField } from "../../FileField";
import { Label } from "../../Label";
import { SelectField } from "../../SelectField";
import { TextAreaField } from "../../TextAreaField";
import { TextField } from "../../TextField";
import { INITIAL_VALUES } from "./initialValues";
import { VALIDATION_SCHEMA } from "./validationSchema";

export type TNewDisposalValues = ReturnType<typeof INITIAL_VALUES>;

interface Props {
  currentUser: UserByEmail;
  loading?: boolean;
  onSubmit: (
    values: TNewDisposalValues,
    helpers: FormikHelpers<TNewDisposalValues>
  ) => Promise<void>;
}

const Form = styled(BaseForm)`
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.tripleMargin};

  & > *:not(:last-child) {
    margin-right: ${({ theme }) => theme.tripleMargin};
  }

  @media ${device.tablet} {
    flex-direction: column;

    & > *:not(:last-child) {
      margin-right: 0;
    }
  }
`;

const Button = styled(BaseButton)`
  width: 100%;
`;

export const NewDisposalForm: FC<Props> = ({
  currentUser,
  loading,
  onSubmit,
}) => {
  const { t } = useI18n();
  const [cities, setCities] = useState<ICity[]>([]);
  const [countries, setCountries] = useState<ICountry[]>([]);

  useEffect(() => {
    async function getCountries() {
      try {
        const countries = await API.users.countries();
        setCountries(countries);
      } catch (_) {
        setCountries([]);
      }
    }

    getCountries();
  }, []);

  useEffect(() => {
    if (currentUser.location) {
      getCitiesByCountry(currentUser.location.country.geoname_id);
    }
  }, [countries, currentUser]);

  async function getCitiesByCountry(countryId: number) {
    try {
      const cities = await API.users.citiesByCountry(countryId);
      setCities(cities);
    } catch (_) {
      setCities([]);
    }
  }

  const handleSubmit = useCallback(
    async (values, helpers) => {
      helpers.setSubmitting(true);
      await onSubmit(values, helpers);
      helpers.setSubmitting(false);
    },
    [onSubmit]
  );

  const handleChange = async (countryId: number) => {
    getCitiesByCountry(countryId);
  };

  const formInitialValues = useMemo(
    () => INITIAL_VALUES(currentUser),
    [currentUser]
  );

  return (
    <Formik<TNewDisposalValues>
      initialValues={formInitialValues}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={handleSubmit}
    >
      {({
        isValid,
        values,
        setFieldValue,
        handleSubmit,
        handleChange: onChange,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Label>{t("newDisposalForm.auctionLocation")}</Label>
          <Row>
            <SelectField
              name="country"
              placeholder={t("newDisposalForm.country")}
              options={countries.map((country) => ({
                label: country.name,
                value: country.geoname_id,
              }))}
              onChange={(event) => {
                setFieldValue("city", "");
                onChange(event);
                handleChange(Number(event.target.value));
              }}
            />
            <SelectField
              name="city"
              placeholder={t("newDisposalForm.city")}
              options={cities.map((city) => ({
                label: getCityName(city),
                value: city.geoname_id,
              }))}
              disabled={!Boolean(values.country) || cities.length === 0}
            />
          </Row>
          <Label>{t("newDisposalForm.contactInformation")}</Label>
          <Row>
            <TextField name="name" placeholder={t("newDisposalForm.name")} />
            <TextField
              name="surname"
              placeholder={t("newDisposalForm.surname")}
            />
          </Row>
          <Row>
            <TextField
              name="email"
              type="email"
              placeholder={t("newDisposalForm.email")}
            />
            <FileField name="file" />
          </Row>
          <Row>
            <TextAreaField
              name="description"
              placeholder={t("newDisposalForm.description")}
            />
          </Row>

          <Button
            type="submit"
            disabled={!isValid}
            loading={loading}
            onClick={() => null}
          >
            {t<string>("newDisposalForm.submit")}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
