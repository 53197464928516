import { FC, ReactNode, RefObject } from "react";
import styled from "styled-components";

const Window = styled.div<{ fixed?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  ${({ fixed }) =>
    fixed &&
    `
    position: relative;
    background: none;
    height: 100%;
  `}
`;

const Container = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.secondaryColor};
  margin: 0 auto;
  min-width: 50%;
  max-height: 90%;
  width: ${({ width }) => width || "auto"};
`;

const SpacedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.secondaryBlue};
  padding: ${({ theme }) => `${theme.oneSixthPadding} ${theme.triplePadding}`};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.tertiaryBlue};
  padding: ${({ theme }) => theme.triplePadding};
  overflow: scroll;
`;

const Title = styled.span`
  font-weight: 600;
  font-size: ${({ theme }) => theme.mediumBigFontSize};
`;

const Cross = styled.span`
  color: ${({ theme }) => theme.grey};
  cursor: pointer;
`;

export type Props = {
  title?: string;
  actions: ReactNode;
  onClose: () => void;
  primaryActions?: ReactNode;
  width?: string;
  fixed?: boolean;
  contentRef?: RefObject<any>;
};

export const Modal: FC<Props> = ({
  title,
  actions,
  children,
  onClose,
  primaryActions,
  width,
  fixed,
  contentRef,
}) => {
  return (
    <Window fixed={fixed}>
      <Container width={width}>
        {title && (
          <SpacedContainer>
            <Title>{title}</Title>
            {primaryActions ?? <Cross onClick={onClose}>&#10005;</Cross>}
          </SpacedContainer>
        )}
        <Content ref={contentRef}>{children}</Content>
        <SpacedContainer>{actions}</SpacedContainer>
      </Container>
    </Window>
  );
};
