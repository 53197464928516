import { FC, useCallback } from "react";
import { Form as BaseForm, Formik, FormikHelpers } from "formik";
import styled from "styled-components";

import { ErrorMessage, Modal, NumberField, TextField } from "@components";
import { useI18n } from "@i18n";
import { device } from "@styles/breakpoints";
import { IError, Nullable } from "@utils";
import { Button } from "../../Button";
import { INITIAL_VALUES, ONLINE_INITIAL_VALUES } from "./initialValues";
import {
  MAX_RESERVE_PRICE_VALUE,
  MIN_LOT_VALUE,
  MIN_RESERVE_PRICE_VALUE,
  VALIDATION_SCHEMA,
} from "./validationSchema";

export type TCreateLotFormValues = typeof INITIAL_VALUES;
export type TCreateOnlineLotFormValues = typeof ONLINE_INITIAL_VALUES;

type Props = {
  onClose: () => void;
  onRetry: () => void;
  error: Nullable<IError>;
  loading?: boolean;
  onSubmit: (
    values: TCreateLotFormValues & TCreateOnlineLotFormValues,
    helpers: FormikHelpers<TCreateLotFormValues>
  ) => Promise<void>;
};

const Form = styled(BaseForm)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: ${({ theme }) => theme.tripleMargin};

  & > *:not(:last-child) {
    margin-right: ${({ theme }) => theme.tripleMargin};
  }

  @media ${device.tablet} {
    flex-direction: column;

    & > *:not(:last-child) {
      margin-right: 0;
    }
  }
`;

export const CreateLotForm: FC<Props> = ({
  loading,
  error,
  onRetry,
  onSubmit,
  onClose,
}) => {
  const { t } = useI18n();

  const handleSubmit = useCallback(
    async (values, helpers) => {
      helpers.setSubmitting(true);
      await onSubmit(values, helpers);
      helpers.setSubmitting(false);
    },
    [onSubmit]
  );

  return (
    <Formik<TCreateLotFormValues>
      initialValues={INITIAL_VALUES}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ isValid, handleSubmit, setValues }) => (
        <Form
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
        >
          <Modal
            width="50%"
            title={t("lotForm.title")}
            actions={
              <>
                <Button
                  onClick={() => {
                    setValues(INITIAL_VALUES);
                    onClose();
                  }}
                  bordered
                >
                  {t<string>("lotForm.cancel")}
                </Button>
                {!error && (
                  <Button
                    type="submit"
                    disabled={!isValid}
                    onClick={() => null}
                    loading={loading}
                  >
                    {t<string>("lotForm.submit")}
                  </Button>
                )}
              </>
            }
            onClose={onClose}
          >
            {error ? (
              <ErrorMessage error={error} onRetry={onRetry} />
            ) : (
              <>
                <Row>
                  <NumberField
                    name="lot"
                    label={t("lotForm.lot.label")}
                    placeholder={t("lotForm.lot.placeholder")}
                    min={MIN_LOT_VALUE}
                    required
                  />
                  <NumberField
                    name="reservePrice"
                    label={t("lotForm.reservePrice.label")}
                    placeholder={t("lotForm.reservePrice.placeholder")}
                    min={MIN_RESERVE_PRICE_VALUE}
                    max={MAX_RESERVE_PRICE_VALUE}
                    step="0.01"
                  />
                </Row>
                <Row>
                  <TextField
                    name="name"
                    label={t("lotForm.name.label")}
                    placeholder={t("lotForm.name.placeholder")}
                    required
                  />
                </Row>
              </>
            )}
          </Modal>
        </Form>
      )}
    </Formik>
  );
};
