import styled from "styled-components";

import { WithChildren } from "@utils";
import { IconByName, IconName } from "../Icon";

export interface ButtonLinkProps extends WithChildren {
  to: string;
  icon: IconName;
  download?: boolean;
}

const StyledButton = styled.a`
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => `${theme.smallPadding} ${theme.triplePadding}`};
  background-color: ${({ theme }) => theme.darkestBlue};
  text-decoration: none;
  border: 1px solid ${({ theme }) => theme.secondaryColor};
  border-radius: ${({ theme }) => theme.borderRadius};
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: ${({ theme }) => theme.bodyColor};

  svg {
    width: ${({ theme }) => theme.baseSize};
    fill: ${({ theme }) => theme.bodyColor};
    margin-right: 10px;
  }
`;

export function Link({ children, to, icon, download }: ButtonLinkProps) {
  return (
    <StyledButton href={to} role="link" download={download}>
      <Container>
        <IconByName name={icon} />
        {children}
      </Container>
    </StyledButton>
  );
}
