import { FC, useEffect, useState } from "react";

import { formatDate, Nullable } from "@utils";

export interface Props {
  date: Date;
}

export const FormatDate: FC<Props> = ({ date }) => {
  const [value, setValue] = useState<Nullable<string>>(null);

  useEffect(() => {
    const formattedDate = formatDate(date);
    setValue(formattedDate);
  }, [date]);

  return <span>{value}</span>;
};
