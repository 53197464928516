import Axios from "axios";
import qs from "qs";

import { encodeMultipleFilters, IAsset, IPaginatedResponse } from "@utils";

export async function get(
  page: number,
  acquisitionDateFrom?: string,
  acquisitionDateTo?: string,
  approvalDateFrom?: string,
  approvalDateTo?: string,
  brand?: string[],
  countries?: string[],
  cities?: string[],
  family?: string[],
  functionalLoc?: string[],
  model?: string[],
  stateOfUse?: string[],
  unassociated?: boolean
): Promise<IPaginatedResponse<IAsset>> {
  const response = await Axios.get<IPaginatedResponse<IAsset>>("/api/assets/", {
    params: {
      page,
      acquisition_date_start: acquisitionDateFrom,
      acquisition_date_end: acquisitionDateTo,
      psb_approval_date_start: approvalDateFrom,
      psb_approval_date_end: approvalDateTo,
      brand: encodeMultipleFilters(brand),
      country: encodeMultipleFilters(countries),
      city: encodeMultipleFilters(cities),
      asset_family: encodeMultipleFilters(family),
      functional_loc: encodeMultipleFilters(functionalLoc),
      model: encodeMultipleFilters(model),
      user_status: encodeMultipleFilters(stateOfUse),
      unassociated,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
  return response.data;
}

export async function addToAuction(auctionId: number, assetsIds: number[]) {
  const response = await Axios.put(`/api/auctions/${auctionId}/assets/add/`, {
    asset_ids: assetsIds,
  });
  return response.data;
}

export interface IFamily {
  value: string;
  label: string;
}

export async function families() {
  const response = await Axios.get<IFamily[]>("/api/assets/families/");
  return response.data;
}

export interface IBrand {
  brand: string;
}

export async function brands(auctionId?: number) {
  const response = await Axios.get<IBrand[]>("/api/assets/brands/", {
    params: { auction: auctionId },
  });
  return response.data;
}

export interface ICountry {
  country: CountryData;
}

interface CountryData {
  geoname_id: number;
  name: string;
  code2: string;
}

export async function countries() {
  const response = await Axios.get<ICountry[]>("/api/assets/countries/");
  return response.data;
}

export interface ICity {
  city: {
    geoname_id: number;
    name: string;
    name_ascii: string;
    subregion: { name: string } | null;
    region: { name: string } | null;
    country: CountryData;
  };
}

export async function cities() {
  const response = await Axios.get<ICity[]>("/api/assets/cities/");
  return response.data;
}

export interface IModel {
  model: string;
}
export async function models(auctionId?: number) {
  const response = await Axios.get<IModel[]>("/api/assets/models/", {
    params: { auction: auctionId },
  });
  return response.data;
}

export interface IFunctionalLoc {
  functional_loc: string;
}
export async function functionalLocs(auctionId?: number) {
  const response = await Axios.get<IFunctionalLoc[]>(
    "/api/assets/functional-locs/",
    {
      params: { auction: auctionId },
    }
  );
  return response.data;
}

export interface IUserStatus {
  user_status: string;
}
export async function userStatuses(auctionId?: number) {
  const response = await Axios.get<IUserStatus[]>(
    "/api/assets/user-statuses/",
    {
      params: { auction: auctionId },
    }
  );
  return response.data;
}
