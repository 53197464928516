export enum Error {
  Backend = 1000,
  Frontend = 2000,
  EndUser = 3000,
  Validation = 4000,
  Template = 5000,
}

export interface IError {
  code?: number;
  detail?: string;
  errors?: {
    field?: string;
    detail: string;
    code?: number;
  }[];
}
