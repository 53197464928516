import { FC } from "react";
import styled from "styled-components";

import { ApplicationContextValues, useApplication } from "@applications";
import { useAuthentication } from "@authentication";
import { Icon } from "@components";
import { useI18n } from "@i18n";
import { device } from "@styles/breakpoints";
import { Button, ButtonTheme } from "./Button";
import { NavigatorMenu, Props as NavProps } from "./NavigatorMenu";

const Container = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${({ theme }) => theme.headerHeight};
  background-color: ${({ theme }) => theme.contextTheme.color};
  padding-left: ${({ theme }) => theme.doublePadding};
  z-index: 1;

  @media ${device.tablet} {
    flex-direction: column;
    align-items: flex-start;
    height: ${({ theme }) => `calc(${theme.headerHeight} * 2)`};
    padding: ${({ theme }) => theme.doublePadding};
  }
`;

const Logo = styled.img`
  height: calc(100% - 12px);

  @media ${device.tablet} {
    height: auto;
  }
`;

const PoweredBy = styled.img`
  height: 100%;

  @media ${device.tablet} {
    display: none;
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  & > *:not(:last-child) {
    margin-right: ${({ theme }) => theme.mediumBigMargin};
  }

  @media ${device.tablet} {
    height: auto;
    width: 100%;
    justify-content: space-between;

    & > *:not(:last-child) {
      margin: 0;
    }
  }
`;

const UserSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  font-size: ${({ theme }) => theme.smallFontSize};

  .logout {
    display: flex;
    align-items: center;
    font-weight: 600;
    cursor: pointer;

    svg {
      width: 14px;
      fill: ${({ theme }) => theme.bodyColor};
      margin-left: ${({ theme }) => theme.smallestMargin};
    }
  }
`;

export interface Props extends NavProps {}

export const Header: FC<Props> = ({ links }) => {
  const { isPublic, isGAD, changeContext } = useApplication();
  const { currentUser, isAuthenticated, logout } = useAuthentication();
  const { t } = useI18n();

  return (
    <>
      <Container>
        <Logo src="/logo.png" alt="WFP" />
        <Actions>
          {!isGAD && (
            <Button
              onClick={() => {
                changeContext(ApplicationContextValues.DigitalOffice);
              }}
              theme={ButtonTheme.Secondary}
              icon="Lock"
              rounded
            >
              {t<string>("header.digitalOffice")}
            </Button>
          )}
          {!isPublic && (
            <Button
              onClick={() => {
                changeContext(ApplicationContextValues.Public);
              }}
              theme={ButtonTheme.Blue}
              icon="Square"
              rounded
            >
              {t<string>("header.publicArea")}
            </Button>
          )}
          <UserSection>
            {isAuthenticated && (
              <>
                <span>
                  {currentUser!.first_name} {currentUser!.last_name}
                </span>
                <div className="logout" onClick={logout}>
                  <span>{t("logout")}</span>
                  <Icon.SignOut />
                </div>
              </>
            )}
          </UserSection>
          <PoweredBy src="/powered-by.png" alt="WFP" />
        </Actions>
      </Container>
      <NavigatorMenu links={links} />
    </>
  );
};
