import Axios from "axios";

interface IDeposit {
  id?: number;
  asset_family: string;
  amount: number;
  refunded: boolean;
  bidder_user: number;
  auction: number;
}

export async function getDeposits() {
  const response = await Axios.get("/api/deposits");
  return response.data;
}

export async function createDeposits(data: IDeposit[]) {
  const response = await Axios.post("/api/deposits/", data);
  return response.data;
}

export async function updateDeposits(data: IDeposit) {
  const id = data.id;
  const dataToSend = {
    asset_family: data.asset_family,
    amount: data.amount,
    refunded: data.refunded,
    bidder_user: data.bidder_user,
    auction: data.auction,
  };
  const response = await Axios.patch(`/api/deposits/${id}/`, dataToSend);
  return response.data;
}
