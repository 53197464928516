import { useMemo } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";

import { usePagination } from "@hooks";
import { useI18n } from "@i18n";
import { Pagination } from "@utils";
import { Icon } from "./Icon";

export interface Props {
  pagination: Pagination;
  currentUrl: string;
  customPageName?: string;
  "data-testid"?: string;
}

const Nav = styled.nav`
  display: flex;
  justify-content: center;
`;

const UL = styled.ul`
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
`;

const NavLink = styled.div`
  padding: ${({ theme }) => `${theme.doublePadding} ${theme.oneSixthPadding}`};
  color: ${({ theme }) => theme.bodyColor};
  background-color: ${({ theme }) => theme.tertiaryBlue};
  border: 1px solid ${({ theme }) => theme.casalColor};
  border-right: none;
  cursor: pointer;
  text-decoration: none;

  &.active {
    background-color: ${({ theme }) => theme.secondaryColor};
  }
`;

const LeftButton = styled.button`
  padding: ${({ theme }) => `${theme.doublePadding} ${theme.oneSixthPadding}`};
  background-color: ${({ theme }) => theme.tertiaryBlue};
  border: 1px solid ${({ theme }) => theme.casalColor};
  border-top-left-radius: ${({ theme }) => theme.borderRadius};
  border-bottom-left-radius: ${({ theme }) => theme.borderRadius};
  cursor: pointer;
  color: ${({ theme }) => theme.bodyColor};

  svg {
    width: ${({ theme }) => theme.baseSize};
    fill: ${({ theme }) => theme.bodyColor};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.darkBlue};
    border-color: ${({ theme }) => theme.secondaryBlue};
    cursor: not-allowed;
    color: #475056;

    svg {
      fill: #475056;
    }
  }
`;

const RightButton = styled.button`
  padding: ${({ theme }) => `${theme.doublePadding} ${theme.oneSixthPadding}`};
  background-color: ${({ theme }) => theme.tertiaryBlue};
  border: 1px solid ${({ theme }) => theme.casalColor};
  border-top-right-radius: ${({ theme }) => theme.borderRadius};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadius};
  cursor: pointer;
  color: ${({ theme }) => theme.bodyColor};

  svg {
    width: ${({ theme }) => theme.baseSize};
    fill: ${({ theme }) => theme.bodyColor};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.darkBlue};
    border-color: ${({ theme }) => theme.secondaryBlue};
    cursor: not-allowed;
    color: #475056;

    svg {
      fill: #475056;
    }
  }
`;

export function Paginator(props: Props) {
  const { pagination, currentUrl, customPageName } = props;

  const WINDOW_SIZE = 5;
  const history = useHistory();

  const {
    previousPage,
    nextPage,
    getPathForPage,
    isCurrentPage,
    visiblePages,
  } = usePagination(pagination, WINDOW_SIZE, currentUrl, customPageName);

  if (pagination.documentsCount === 0) return null;

  return (
    <Nav data-testid={props["data-testid"]}>
      <LeftButton
        onClick={() => history.push(getPathForPage(previousPage))}
        disabled={isCurrentPage(1)}
        data-testid="GoToPreviousPage"
      >
        <Icon.LeftArrow />
      </LeftButton>
      <UL>
        {visiblePages.map((currentPage) => (
          <NavLink
            role="link"
            onClick={() => history.push(getPathForPage(currentPage))}
            key={currentPage}
            className={isCurrentPage(currentPage) ? "active" : "not-active"}
          >
            {currentPage}
          </NavLink>
        ))}
      </UL>
      <RightButton
        onClick={() => history.push(getPathForPage(nextPage))}
        disabled={isCurrentPage(pagination.totalPages)}
        data-testid="GoToNextPage"
      >
        <Icon.RightArrow />
      </RightButton>
    </Nav>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

Paginator.WithTotalItems = function WithTotalItems(props: Props) {
  const { t } = useI18n();
  const {
    pagination: { documentsPerPage, documentsCount, page, totalPages },
  } = props;
  const items = page === totalPages ? documentsCount : page * documentsPerPage;

  return (
    <Container>
      <span>
        {items} {t("of")} {documentsCount}
      </span>
      <Paginator {...props} />
    </Container>
  );
};
