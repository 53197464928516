import getSymbolFromCurrency from "currency-symbol-map";

import { Optional } from "./types";

export function getValueWithCurrencyOrEmpty(
  value: Optional<string | number>,
  currencyCode: string
) {
  const currencySymbol = getSymbolFromCurrency(currencyCode);
  const formattedValue = Number(value).toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
  const valueWithCurrency = currencySymbol
    ? `${formattedValue} ${currencySymbol}`
    : formattedValue;

  if (
    value === null ||
    value === undefined ||
    (typeof value === "string" && value.trim() === "")
  )
    return "-";
  return Number.isNaN(Number(value)) ? "-" : valueWithCurrency;
}
