import { InputHTMLAttributes } from "react";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import styled from "styled-components";

import { Nullable } from "@utils";
import { Asterisk } from "./Asterisk";
import { Button } from "./Button";
import { FormError } from "./FormError";
import { Input } from "./Input";
import { Label } from "./Label";

export interface Props
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "type"> {
  name: string;
  label?: string;
  required?: boolean;
  buttonLabel?: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${({ theme }) => theme.smallestPadding};
  width: 100%;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
`;

export function FileField({
  name,
  label,
  buttonLabel,
  required,
  placeholder,
  id,
  ...props
}: Props) {
  const { t } = useTranslation();
  const { setFieldValue, values, setFieldTouched } = useFormikContext<any>();
  const selectedFile: Nullable<File> = values[name];

  return (
    <Container>
      {label && (
        <LabelContainer>
          <Label className="mb-6" htmlFor={id ?? name} children={label} />
          {required && <Asterisk />}
        </LabelContainer>
      )}
      <Button.FileUpload
        placeholder={placeholder ?? t("noFileSelected")}
        fileName={selectedFile?.name}
        onReset={() => {
          setFieldTouched(name, true);
          setFieldValue(name, null);
        }}
      >
        <Input
          type="file"
          id={id ?? name}
          name={name}
          onChange={(event) => {
            setFieldTouched(name, true);
            props.multiple
              ? setFieldValue(name, event.currentTarget.files)
              : setFieldValue(name, event.currentTarget.files?.[0]);
          }}
          {...props}
          data-testid="UploadInput"
        />
      </Button.FileUpload>
      <FormError name={name} />
    </Container>
  );
}
