import { useContext } from "react";

import {
  AuthenticationContext,
  IAuthenticationContext,
} from "../AuthenticationContext";

export function useAuthentication() {
  return useContext<IAuthenticationContext>(AuthenticationContext);
}
