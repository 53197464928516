import React, { useState } from "react";
import styled from "styled-components";

import { Icon } from "@components";

const Container = styled.div`
  width: 500px;
  height: 280px;
  position: relative;
  overflow: hidden;
`;

const Slide = styled.img`
  height: 100%;
  border-radius: 10px;
  display: block;
  margin: 0 auto;
`;

const StyledLeftArrow = styled.div`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translate(0, -50%);
  cursor: pointer;
`;

const StyledRightArrow = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(0, -50%);
  cursor: pointer;
`;

const StyledDotsContainer = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
`;
const StyledDot = styled.div<{ isSelected: boolean }>`
  margin: 0 3px;
  cursor: pointer;
  font-size: 28px;
  color: ${(props) => (props.isSelected ? "#2AA277" : "#D9D9D9")};
  line-height: 10px;
`;

interface Props {
  urls: string[];
}

export const ImageSlider = ({ urls }: Props): JSX.Element => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const selectSlide = (index: number): void => {
    setCurrentIndex(index);
  };
  const goPrev = (): void => {
    const isFirstSlide: boolean = currentIndex === 0;
    setCurrentIndex(isFirstSlide ? urls.length - 1 : currentIndex - 1);
  };
  const goNext = (): void => {
    const isLastSlide: boolean = currentIndex === urls.length - 1;
    setCurrentIndex(isLastSlide ? 0 : currentIndex + 1);
  };

  return (
    <Container>
      <StyledLeftArrow onClick={goPrev}>
        <Icon.LeftArrow />
      </StyledLeftArrow>
      <Slide src={urls[currentIndex]} />
      <StyledRightArrow onClick={goNext}>
        <Icon.RightArrow />
      </StyledRightArrow>
      <StyledDotsContainer>
        {urls.map((url, i) => {
          return (
            <StyledDot
              key={i}
              isSelected={currentIndex === i}
              onClick={() => selectSlide(i)}
            >
              •
            </StyledDot>
          );
        })}
      </StyledDotsContainer>
    </Container>
  );
};
