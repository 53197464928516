import { FC, SelectHTMLAttributes } from "react";
import { useField } from "formik";
import styled from "styled-components";

import { useI18n } from "@i18n";
import { OptionalNotNull } from "@utils/types";
import { Asterisk } from "./Asterisk";
import { FormError } from "./FormError";
import { Label } from "./Label";
import { Select } from "./Select";

export interface IOptionModel {
  label?: string;
  value: OptionalNotNull<string | number | readonly string[]>;
}

interface Props extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
  label?: string;
  options: IOptionModel[];
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${({ theme }) => theme.smallestPadding};
  width: 100%;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SelectField: FC<Props> = ({
  name,
  label,
  id,
  options,
  required,
  ...props
}) => {
  const [field] = useField(name);
  const { t } = useI18n();

  return (
    <Container>
      {label && (
        <LabelContainer>
          <Label className="mb-6" htmlFor={id ?? name} children={label} />
          {required && <Asterisk />}
        </LabelContainer>
      )}
      <Select
        id={id ?? name}
        data-cy={id ?? name}
        aria-label={id ?? name}
        {...field}
        {...props}
      >
        <option value={""}>
          {props.placeholder ? props.placeholder : t("selectAll")}
        </option>
        {options.map(({ value, label }, index) => (
          <option key={index} value={value}>
            {label}
          </option>
        ))}
      </Select>
      <FormError name={name} />
    </Container>
  );
};
