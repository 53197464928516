const en = {
  dragHere: "Drag Items Here",
  or: "Or",
  cancel: "Cancel",
  selectAssetFamily: "Select Asset Family",
  addDeposit: "Add Deposit",
  addFee: "Add Fee",
  removeDeposit: "Remove Deposit",
  removeFee: "Remove Fee",
  saveDeposit: "Save Deposit",
  saveFee: "Save Fee",
  errors: {
    generic: {
      title: "An error occur",
      description: "Please try again.",
    },
    email: {
      invalid: "Insert a valid e-mail",
      required: "The e-mail address is required",
    },
    city: {
      required: "A city is required",
    },
    country: {
      required: "A country is required",
    },
    date: {
      required: "A date is required",
      later: "Must be a future date",
    },
    currency: {
      required: "A currency is required",
    },
    name: {
      required: "A name is required",
    },
    surname: {
      required: "A surname is required",
    },
    file: {
      required: "A file is required",
    },
    phone: {
      required: "A phone is required",
      format: "Invalid phone format - please follow +111111111111 format",
    },
    fullName: {
      required: "A full name is required",
    },
  },
  filters: {
    title: "Filters",
    by: "Filter by",
    clear: "Clear filters",
    apply: "Apply filters",
    assetsManagement: {
      family: "Asset Familty",
      approvalDate: "PSB Approval Date",
      approvalDateFrom: "Start Date",
      approvalDateTo: "End Date",
      acquisitionDate: "Acquisition Date",
      acquisitionDateFrom: "Start Date",
      acquisitionDateTo: "End Date",
      functionalLoc: "Functional Loc",
      brand: "Brand",
      model: "Model",
      stateOfUse: "State of Use",
    },
    disposalRequests: {
      city: "City",
      country: "Country",
      selectCountry: "Please select a Country",
      date: "Date",
      dateFrom: "From",
      dateTo: "To",
      status: "Status",
    },
  },
  header: {
    digitalOffice: "Digital Office",
    myDisposal: "My Disposal",
    publicArea: "Public Area",
  },
  language: "Language",
  languages: {
    english: "En",
    french: "Fr",
  },
  loading: "Loading...",
  logout: "Logout",
  menu: {
    home: "Home",
    whyToDispose: "Why to dispose",
    requestDisposal: "Request disposal",
    feedback: "Feedback",
    auctionsList: "Auctions list",
    myRequests: "My requests",
    digitalOfficeHome: "Digital office home",
    createAuction: "Create auction",
    manageRequests: "Manage requests",
    assetsManagement: "Assets Management",
    manageBidders: "Manage Bidders",
  },
  auctionForm: {
    holdOnline: "Hold this auction online?",
    date: {
      label: "Auction date *",
      placeholder: "Pick the date",
    },
    auctionEndDate: {
      label: "Auction end date *",
      placeholder: "Pick the date",
    },
    depositDate: {
      label: "Deposit end date",
      placeholder: "Pick the date",
    },
    country: "Select country",
    city: "Select city",
    currency: {
      placeholder: "Select currency",
      label: "Auction currency *",
    },
    conversionRate: {
      placeholder: "Insert a conversion rate",
      label: "Conversion rate",
    },
    depositToggle: "Deposit",
    auctioneerFee: "Auctioneer fee",
    auctioneerFeeTooltip:
      "Please assign an Auctioneer Fee to each Asset Family. If an Asset Family doesn't use an Auctioneer Fee, please set it to 0. \nThis can be changed anytime before the Auction Live date",
    deposit: "Deposit",
    depositTooltip:
      "Please assign a Deposit Fee to each Asset Family. If an Asset Family doesn't use a Deposit Fee, please set it to 0. This can be changed anytime before the Auction Live date",
    fee: "Acutioneer fee",
    paymentAccount: "Payment account",
    paymentAccountTooltip:
      "Please enter bank details to be used in winner email notifications",
    paymentConditions: "Payment conditions",
    paymentConditionsTooltip:
      "Please enter payment conditions to be used in the Bill of Sales",
    emailNotification: {
      outbid: "Automatically notify user on outbid",
      winner: "Enable Automatic Winner Notifications",
    },
    emailNotificationTooltip: {
      outbid:
        "If selected, the users will receive email notifications once their bid has been outbid",
      winner:
        "If selected, the users with highest bids will receive email notifications with payment instructions after auction end",
    },
    emailLanguage: "Select email language",
    enableBankTransfer: "Enable bank transfer",
    enableOnlinePayment: "Enable card payment",
    location: "Location *",
    submit: "create",
    clear: "Clear form",
    cancel: "Cancel",
    update: "Save changes",
    agency: "Agency of auctioneer",
    sealedBid: "Sealed bid",
    public: "Public",
    supervisor: "UNHCR auction supervisor information",
    focalPoint: "Co focal point",
    representative: "GAS Team representative",
    fullName: "Full name",
    email: "Email",
    phone: "Phone number in format (+111111111111)",
    unhcrServicesFee: {
      label: "UNHCR Additional services (USD)",
      placeholder: "UNHCR services fee (USD)",
    },
    nonWfpLots: {
      label: "# OF NON-WFP LOTS",
      placeholder: "Lots number",
    },
    auctioneerInvoice: {
      label: "Auctioneer invoice (USD)",
      placeholder: "Auctioneer invoice (USD)",
    },
    errors: {
      conversionRate: {
        required: "A conversion rate is required",
        min: "Enter a value of at least 0.01",
        max: "The value may not exceed 15000",
        decimals: "The value must have 3 digits after decimal or less",
      },
      fee: {
        required: "An auctioneer fee is required",
        invalid: "You should assign a fee to each Asset Family",
        min: "Ensure the fee value is greater than or equal to 0.",
        max: "Ensure the fee value is less than or equal to 100.",
      },
      deposit: {
        required: "A deposit is required",
        invalid: "You should assign a deposit to each Asset Family",
        min: "Ensure the deposit value is greater than or equal to 0.",
        max: "Ensure the deposit value is less than or equal to 2147483647.",
      },
      unhcrServicesFee: {
        min: "Enter a value of at least 0.01",
        decimals: "The value must have 2 digits after decimal or less",
      },
      nonWfpLots: {
        min: "Enter a value of at least 1",
      },
      auctioneerInvoice: {
        min: "Enter a value of at least 0.01",
        decimals: "The value must have 2 digits after decimal or less",
      },
      emailLanguage: {
        required: "Please select one email language",
      },
    },
  },
  lotForm: {
    title: "Create a lot",
    updateTitle: "Update lot",
    addDetails: "Add details",
    submit: "Create Lot",
    update: "Update Lot",
    cancel: "Cancel",
    tabs: {
      general: "General Data",
      details: "Lot Details",
    },
    accordionTitles: {
      details: "Details",
      medias: "Medias",
      description: "Description",
    },
    detailsBlock: {
      totalAssets: "Total # of assets",
      totalAqcValue: "Total AQC value",
      totalMarketValue: "Total market value",
      lotNumber: "Lot #",
      startPrice: "Start price",
    },
    lot: {
      label: "Lot #",
      placeholder: "Enter lot #",
    },
    name: {
      label: "Lot name",
      placeholder: "Enter lot name",
    },
    reservePrice: {
      label: "Reserve price (USD)",
      placeholder: "Enter reserve price (USD)",
    },
    assetFamily: {
      label: "Asset family",
      placeholder: "Enter asset family",
    },
    startPrice: {
      label: "Start price ({{currency}})",
      placeholder: "Enter start price ({{currency}})",
    },
    minStep: {
      label: "Minimal step ({{currency}})",
      placeholder: "Enter minimal step ({{currency}})",
    },
    brand: {
      label: "Brand",
      placeholder: "Enter brand",
    },
    model: {
      label: "Model",
      placeholder: "Enter model",
    },
    country: {
      label: "Country",
      placeholder: "Enter country",
    },
    driverSide: {
      label: "Driver side",
      placeholder: "Enter driver side",
    },
    yearOfMake: {
      label: "Year of make",
      placeholder: "Enter year of make",
    },
    mileage: {
      label: "Mileage",
      placeholder: "Enter mileage",
    },
    engine: {
      label: "Engine",
      placeholder: "Enter engine",
    },
    transmission: {
      label: "Transmission",
      placeholder: "Enter transmission",
    },
    inspectionReport: {
      label: "Inspection report",
      placeholder: "Add inspection report",
    },
    description: {
      label: "Description",
      placeholder: "Add description",
    },
    photo: {
      label: "Upload photo",
      placeholder: "Upload photo",
    },
    video: {
      label: "Insert video url",
      placeholder: "Insert video url",
    },
    errors: {
      lot: {
        required: "A lot number is required",
        number: "Enter an integer greater than 1",
      },
      name: {
        required: "A lot name is required",
      },
      assetFamily: {
        required: "Asset family is required",
      },
      startPrice: {
        required: "Start price is required",
      },
      minStep: {
        required: "Minimal step is required",
      },
      reservePrice: {
        required: "A reserve price is required",
        min: "Enter a value of at least 0.01",
        max: "The value may not exceed 999999999.99",
        decimals: "The value must have 2 digits after decimal or less",
      },
    },
  },
  newDisposalForm: {
    submit: "Submit request",
    country: "Select country",
    city: "Select city",
    name: "Name",
    surname: "Surname",
    email: "Email",
    description: "Description (optional)",
    auctionLocation: "Auction Location",
    contactInformation: "Contact Information",
  },
  noFileSelected: "Attach the filled in Asset List Template",
  notFound: "404 Not Found",
  noItems: "No items found",
  of: "of",
  ok: "OK",
  pages: {
    assetsManagement: {
      error: {
        title: "An error occur",
        description: "Please try again.",
      },
      unauthorized: {
        title: "Area Reserved",
        description:
          "You are not authorised to view Assets list.<br/>If you need to access our Disposal Services please contact us at <a href='mailto:global.asset@wfp.org'>global.asset@wfp.org</a>",
      },
      inventory: "Inventory",
      country: "Country",
      city: "City",
      family: "Asset family",
      description: "Description",
      acquisitionDate: "Acquisition date",
      stateOfUse: "State of use",
      loc: "Functional loc",
      brand: "Brand",
      model: "Model",
      chassis: "Chassis",
      yearOfMake: "Year of make",
      psbDate: "PSB Date",
      selectAll: "Select All",
      edit: "Edit",
      emr: "EMR",
      gvlp: "GVLP #",
      amr: "AMR",
      mileage: "Mileage (KM)",
      acquisitionValue: "Acquisition value",
      licensePlate: "License plate",
      marketValue: "Market value",
      engine: "Engine #",
      equipCategory: "Equip category",
      drId: "DR ID",
      objectType: "Object type",
      titleOfOwnership: "Title of ownership",
      objectSubType: "Object sub-type",
      startUpDate: "Start-up date",
      purchaseDoc: "Purchase Doc",
      viewingLoc: "Viewing loc",
      addToAuction: "Add to auction",
      addToAuctionButton: "Add to auction",
      addToAuctionModal: {
        firstContent_one:
          "You about to add {{count}} asset to the auction. You always can edit assets on the “Auction assets“ page.",
        firstContent_other:
          "You about to add {{count}} assets to the auction. You always can edit assets on the “Auction assets“ page.",
        secondContent:
          "Please choose the auction to add the list of assets below.",
        auctionList: "Auction list",
        selectAnAuction: "Select an auction",
        cancel: "Cancel",
        proceed: "Proceed",
        warning:
          "These assets belong to different countries, are you sure you want to proceed?",
        ok: "Ok",
        retry: "Retry",
        success: {
          title: "Success",
          firstContent_one:
            "You have added {{count}} asset to the “{{auction}}”.",
          firstContent_other:
            "You have added {{count}} assets to the “{{auction}}”.",
          secondContent:
            "You always can edit assets on the “Auction assets“ page.",
        },
        error: {
          title: "Error",
          content: "An error occur",
        },
      },
    },
    auctionDetails: {
      title: "Auction Details",
      importTemplate: "Import template",
      import: "Import lots",
      export: "Export lots",
      finalize: {
        warning: {
          title: "Attention",
          description:
            "Are you sure you want to mark the report as final?<br/>This action is irreversible and all further changes to the report will be forbidden.",
        },
      },
      unauthorized: {
        title: "Area Reserved",
        description:
          "You are not authorised to view this section.<br/>If you need to access our Disposal Services please contact us at <a href='mailto:global.asset@wfp.org'>global.asset@wfp.org</a>",
      },
      error: {
        title: "Error",
        description: "The auction is finalized, this page is not available.",
      },
      assetsAndLots: {
        title: "Auction Assets and lots",
        descriptionActions:
          "Please select the Assets to group them into Lots that will be participating in the Auction.",
        inventory: "Inventory",
        objectSubType: "Object sub-type",
        description: "Description",
        stateOfUse: "State of use",
        assets: "Assets",
        removeAsset: "Remove asset",
        lot: "Lot #",
        lotName: "Lot name",
        reservePrice: "Reserve price",
        acquisitionValue: "Acquisition value",
        lots: "Lots",
        unpackageLots: "Unpackage lots",
        addToLot: "Add To This Lot",
        createLotWithItems_one: "Create a lot with selected item ({{count}})",
        createLotWithItems_other:
          "Create a lot with selected items ({{count}})",
        edit: "Edit",
        filters: {
          assets: {
            title: "Filter Assets",
          },
          lots: {
            title: "Filter Lots",
            reservePrice: "Reserve Price",
            acquisitionValue: "Acquisition Value",
            marketValue: "Market Value",
            number: "Lot #",
            from: "From",
            to: "To",
          },
        },
      },
      interimReport: {
        title: "Interim Report",
        exportWinnerEmails: "Export Winners Emails",
        winnerEmails: "Send Winners Emails",
        exportReport: "Export Report",
        exportBoS: "Export BoS",
        markAsFinal: "Mark Report As Final",
        backToBids: "Back to bids",
        new: {
          title: "New Interim Report",
          description:
            "1. downloads the prefilled template<br/>2. fills in the template and uploads it<br/>3. the report is generated<br/>4. the user can download the report and/or re-upload a more full transcribing to generate a new report<br/>5. the report can be marked as final, then the transcribing cannot be uploaded or downloaded again",
        },
        download: "Download transcribing template",
        upload: "Upload auction transcribing",
        view: {
          title: "Interim report view",
          totalAcquisition: "Total acquisition",
          totalReservePrice: "Total Reserve Price",
          totalAwarded: "Total Awarded",
          totalToAuctioneer: "Total To Auctioneer",
          totalTaxes: "Total Taxes",
          totalWfpNet: "Total WFP NET",
          totalFc: "Total FC",
          totalCo: "TOTAL CO",
          totalLots: "Total Lots",
          noReportGenerated: "No Interim Report Generated",
          generalInfo: {
            title: "General Info",
            location: "Location",
            date: "Date",
            supervisor: "UNHCR Auction Supervisor",
            representative: "GAS Team Representative",
            fee: "Auctioneer fee",
            share: "FC Share",
            currency: "Auction Currency",
            conversionRate: "Exchange Rate",
          },
          reportByCategory: {
            title: "Report by category",
            category: "Category",
            acquisitionValue: "Acquisition Value",
            totalAwarded: "Total Awarded",
            ratio: "%",
          },
          proceedsAllocation: {
            title: "Proceeds Allocation",
            salesSegregated: "Sales Segregated",
            otherRevenues: "Other Revenues",
            lv: "LV",
            truck: "Truck",
            generator: "Generator",
            ittc: "It & Tc",
            other: "Other",
            totalGross: "Total Gross Sales",
            auctioneerFee: "Auctioneer Fee",
            customDuties: "Customs Duties",
            retainedDeposit: "Retained Deposit",
            unhcrInvoice: "UNHCR’s Invoice:",
            additionalServices: "Additional Services",
            fee: "Fee",
            netProceeds: "NET proceeds to WFP",
            fixedAssets: "Fixed Assets",
            nonFixedAssets: "Non Fixed Assets",
            fcShare: "FC Share",
            globalFleetShare: "Global Fleet Share",
            net: "NET To Be Shared",
            gasShare: "GAS Share",
            coShare: "CO Share",
          },
        },
        tableHeaders: {
          lot: "LOT #",
          lotName: "Name",
          description: "Description",
          reservePrice: "Reserve Price (USD)",
          acquisitionValue: "Acquisition value (USD)",
          marketValue: "Market value (USD)",
          currentBid: "Current bid ({{currency}})",
          bidder: "Bidder",
          totalBids: "Total # of bids",
          lotPaid: "Lot paid?",
        },
        modal: {
          title: "Mark Lot #{{number}} as unpaid",
          titleUnpaid: "Mark lot #{{number}} as paid",
          text:
            "We recommend to consider blocking the bidder {{user}} to avoid further issues with unpaid " +
            "purchases. If the user is blocked, they will receive an automated notification and will not be able " +
            "to make bids under this account further.",
          textBlocked:
            "You have previously blocked the bidder. Would you like to unblock them?",
          checkboxLabel: "Block user {{user}}",
          checkboxLabelBlocked: "Unblock user {{user}}",
          inputLabel: "Add comment",
          inputPlaceholder:
            "This comment will not be visible to the user {{user}}",
          cancel: "Cancel",
          continue: "Continue",
        },
        winnersEmail: {
          title: "Emails sent",
          text: "The winner emails have been sent. You can find copies of the emails under noreply@auctiondigitaloffice.wfp.org.",
          close: "Close",
        },
        exportReportModal: {
          title: "Report is being generated",
          text:
            "This can take up to several minutes depending on the number of lots. Please don’t close the page. " +
            "Once the report is ready, it will be automatically downloaded to your device.",
          close: "Close",
        },
        confirmEmail: {
          title: "Send winner notifications",
          text:
            "Proceeding with this action will trigger emails with payment instructions being sent to all highest " +
            "bidders whose bids are currently marked as paid.\n" +
            "Do you want to proceed?",
          continue: "Continue",
          close: "Close",
        },
        deleteBid: {
          title: "Delete Bid for {{lotName}}",
          text:
            "Attention - this action will permanently delete the record of current highest bid. The bid will disappear" +
            "for the bidders. This action can not be reserved. Do you want to proceed?",
          cancel: "Cancel",
          continue: "Continue",
        },
        exportBosModal: {
          title: "Export Bill Of Sales",
          text: "Please select prefilled Bill of Sales language and content:",
          checkbox: "Prefill bid information",
          select: "Select BOS language",
          english: "English",
          french: "French",
          englishFrench: "English - French",
          englishPortuguese: "English - Portuguese",
        },
        selectWinnerModal: {
          title: "Select new winner for Lot {{lotName}}",
          table: {
            bidder: "Bidder",
            email: "Bidder email",
            amount: "Bid amount",
            winner: "Winner",
          },
        },
        lotPaidModal: {
          title: "Change Paid Status",
          text: "Do you want to set the bid of {{amount}} by {{bidder}} for {{lotName}} to {{value}}?",
        },
      },
    },
    auctionsList: {
      status: "Status",
      type: "Type",
      date: "Date",
      country: "Country",
      city: "City",
      lots: "Number of lots",
      actions: "Actions",
      download: "Download report",
      viewDetail: "Go to auction detail",
      online: "Online",
      error: {
        title: "An error occur",
        description: "Please try again.",
      },
      unauthorized: {
        title: "Area Reserved",
        description:
          "You are not authorised to view Auctions list.<br/>If you need to access our Disposal Services please contact us at <a href='mailto:global.asset@wfp.org'>global.asset@wfp.org</a>",
      },
      statuses: {
        new: "New",
        assetsAdded: "Assets added",
        lotsCreated: "Lots created",
        underCdReview: "Under CD review",
        feedbackByCd: "Feedback by CD",
        approvedByCd: "Approved by CD",
        live: "Live",
        done: "Done",
        finalized: "Finalized",
        promotion: "Promotion",
      },
    },
    createAuction: {
      title: "Create a new auction",
      unauthorized: {
        title: "Area Reserved",
        description:
          "You are not authorised to Create Auction.<br/>If you need to access our Disposal Services please contact us at <a href='mailto:global.asset@wfp.org'>global.asset@wfp.org</a>",
      },
      error: {
        title: "An error occur",
        description: "Please try again.",
      },
    },
    home: {
      public: {
        title: "Discover The Auction Digital Office",
        description:
          "Welcome in the Auction Digital Office. The final tool to Request, Manage and Analyze your Disposals and Auctions.",
      },
      "my-disposal": {
        title: "My Disposal",
        description:
          "Check your requests, follow the set up of auctions, approve auctions, review and export the outcomes.​",
        subTitle: "Liberia, Monrovia",
      },
      "digital-office": {
        title: "Auction Digital Office",
        description:
          "Discover the new way to do your disposal process for all WFP Asset.",
      },
    },
    manageRequests: {
      status: "Status",
      statuses: {
        open: "Open",
        closed: "Closed",
        inProgress: "In Progress",
        approved: "Approved",
        blocked: "Blocked",
      },
      date: "Date",
      country: "Country",
      city: "City",
      id: "Disposal request id",
      requestedBy: "Request by",
      description: "Description",
      viewDetail: "View Detail",
      current: "Current Requests",
      closed: "Closed Requests",
      error: {
        title: "An error occur",
        description: "Please retry later.",
      },
      unauthorized: {
        title: "Area Reserved",
        description:
          "You are not authorised to Manage Requests.<br/>If you need to access our Disposal Services please contact us at <a href='mailto:global.asset@wfp.org'>global.asset@wfp.org</a>",
      },
      details: {
        title: "Disposal request ID {{id}} detail",
        cancel: "Cancel",
        save: "Save changes",
        history: "History of changes",
        message: "Message",
        communicationHistory: "Communication History",
        infoMessage:
          "We will send an email  to the request author every time you make any changes",
        downloadAssets: "Download Assets List",
        upload: "Upload",
        approve: "Approve",
      },
    },
    whyToDispose: {
      title: "How to dispose the auctions",
      link: "View documentation on wfp.go",
      firstSection: {
        firstParagraph:
          "But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. I am alone, and feel the charm of existence in this spot, which was created for the bliss of souls like mine.",
        subTitle: "New life to your assets",
        secondParagraph:
          "One morning, when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections. <br /><br />But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness.<br />A wonderful serenity has taken possession of my entire soul, like these sweet mornings of spring which I enjoy with my whole heart. I am alone, and feel the charm of existence in this spot, which was created for the bliss of souls like mine.",
      },
      secondSection: {
        images:
          "Photo in order: a) Disposal in Afghanistan b) Auction in Iraq c) asset used after auction in Libya",
      },
    },
    requestDisposal: {
      title: "New Disposal Request",
      firstParagraph:
        "You can create your own disposal. Fill the form, upload the items list and send it to us to review and approve your auction.",
      secondParagraph:
        "We create the items list template to make the process easier. To download the template tap button below.",
      link: "Download the official Asset List Template",
      confirm: {
        title: "Your request has been submitted",
        description:
          "The GAS team will review your request.<br/>The updates on the progress will be shared to the email address associated with this request",
        newRequest: "Submit another request",
        explorePlatform: "Explore our platform",
      },
      error: {
        title: "An error occur trying to create a new disposal request.",
        description: "Please retry later.",
      },
      unauthorized: {
        title: "Area Reserved to Asset Focal Points",
        description:
          "You are not authorised to Request Disposal.<br/>If you need to access our Disposal Services please contact us at <a href='mailto:global.asset@wfp.org'>global.asset@wfp.org</a>",
      },
    },
    mangeBidder: {
      table: {
        name: "Name",
        email: "Email",
        phone: "Phone",
        dateJoined: "Date Joined",
        availableDeposits: "Available deposits",
        blockUser: "Block user",
        manageDeposits: "Manage Deposits",
      },
      modal: {
        titleBlock: "Block user {{email}}",
        titleUnblock: "Unblock user {{email}}",
        unblockText: "The user {{email}} will be unblocked",
      },
    },
    bidderDetails: {
      buttons: {
        blockBidder: "Block Bidder",
        unBlockBidder: "Unblock Bidder",
        addDeposits: "Add deposits",
      },
      header: {
        totalBids: "Total bids",
        totalPurchases: "Total purchases",
        bidsNotPaid: "Bids not paid",
        bidsDeleted: "Bids deleted",
        organisation: "Organisation",
        email: "Email",
        phone: "Phone",
      },
      table: {
        bidder: "Bidder",
        auction: "Auction",
        assetFamily: "Asset family",
        deposits: "Number of deposits",
      },
      depositModal: {
        title: "Add deposits",
        description:
          "Please select the Auction, type and number of Deposits purchased by {{user}}:",
        addButton: "Add deposit",
        removeButton: "Remove deposit",
        saveButton: "Save",
        cancelButton: "Cancel",
      },
    },
  },
  inactive: {
    description:
      "There seems to be an issue with your account.<br/>Please contact us via <a href='mailto:email@domain.com'>email@domain.com</a>",
  },
  retry: "Retry",
};

export default en;
