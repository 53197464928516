/**
 * it creates an array of number with the params `from` and `to` included
 * E.g. from: 4, to: 8 => [4, 5, 6, 7, 8]
 */
export function makeInclusiveNumberRange(from: number, to: number): number[] {
  const result: number[] = [];

  for (let position = from; position <= to; position++) {
    result.push(position);
  }

  return result;
}
