import { Nullable } from "@utils";

export enum Role {
  ADMIN = 10,
  LPE = 20,
  GAS_TM = 40,
  EMPLOYEE = 50,
}

export interface IUser {
  _id: string;
  email: string;
  first_name: string;
  last_name: string;
  role: Role;
  location: Nullable<{
    geoname_id: number;
    name: string;
    subregion: {
      name: string;
    };
    region: {
      name: string;
    };
    country: {
      name: string;
    };
  }>;
}
