import { FC, WheelEvent } from "react";

import { Props, TextField } from "./TextField";

export const NumberField: FC<Props> = (props) => {
  return (
    <TextField
      {...props}
      type="number"
      onWheel={(e: WheelEvent<HTMLInputElement>) => e.currentTarget.blur()}
    />
  );
};
