import { FC, InputHTMLAttributes } from "react";
import { useField } from "formik";
import styled from "styled-components";

import { Asterisk } from "./Asterisk";
import { FormError } from "./FormError";
import { Input } from "./Input";
import { Label } from "./Label";

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${({ theme }) => theme.smallestPadding};
  width: 100%;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TextField: FC<Props> = ({
  name,
  label,
  id,
  required,
  ...props
}) => {
  const [field] = useField(name);

  return (
    <Container>
      {label && (
        <LabelContainer>
          <Label className="mb-6" htmlFor={id ?? name} children={label} />
          {required && <Asterisk />}
        </LabelContainer>
      )}
      <Input id={id ?? name} {...field} {...props} />
      <FormError name={name} />
    </Container>
  );
};
