import Axios from "axios";

import { FileType } from "@utils";

export interface UploadResponse {
  url: string;
  fields: {
    key: string;
    policy: string;
    "x-amz-algorithm": string;
    "x-amx-credential": string;
    "x-amx-date": string;
    "x-amx-signature": string;
  };
}

export async function awsUpload(dataToUpload: UploadResponse, file: any) {
  const data = new FormData();
  Object.entries(dataToUpload.fields).forEach(([key, value]) => {
    data.append(key, value);
  });
  data.append("file", file);

  await fetch(dataToUpload.url, {
    method: "POST",
    body: data,
  });
}

export async function upload(fileType: FileType) {
  const response = await Axios.get<UploadResponse>(
    `/api/files/upload/${fileType}/`
  );
  return response.data;
}

export async function download(fileType: FileType, name: string) {
  const response = await Axios.get<{
    presigned_url: string;
  }>(`/api/files/download/${fileType}/${name}/`);
  return response.data;
}
