import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export function useI18n() {
  const { i18n, ...rest } = useTranslation();
  const changeLanguage = useCallback(
    (lang: string) => {
      i18n.changeLanguage(lang);
    },
    [i18n]
  );

  return {
    i18n,
    ...rest,
    changeLanguage,
  };
}
