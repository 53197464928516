import { FC } from "react";
import styled from "styled-components";

import { device } from "@styles/breakpoints";

export interface Props {
  centered?: boolean;
}

const Article = styled.article<{ centered: boolean }>`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  padding: ${({ theme }) => `${theme.biggerPadding} ${theme.bigPadding}`};

  ${({ centered }) =>
    !centered &&
    `
    justify-content: flex-start;
  `}

  @media ${device.mobileL} {
    padding: ${({ theme }) => theme.triplePadding};
  }
`;

export const Page: FC<Props> = ({ centered = true, children }) => {
  return <Article centered={centered}>{children}</Article>;
};
