import { FC, InputHTMLAttributes } from "react";
import styled from "styled-components";

const Container = styled.label`
  position: relative;
  display: block;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 25px;
  width: 25px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border: 2px solid ${({ theme }) => theme.grey};
    border-radius: ${({ theme }) => theme.borderRadius};
  }

  input:checked ~ .checkmark {
    border-color: ${({ theme }) => theme.primaryColor};
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 2px;
    right: 2px;
    top: 2px;
    bottom: 2px;
    border-radius: 2px;
    background-color: ${({ theme }) => theme.primaryColor};
  }
`;

export type Props = Omit<InputHTMLAttributes<unknown>, "type">;

export const Checkbox: FC<Props> = (props) => (
  <Container>
    <input type="checkbox" {...props} />
    <span className="checkmark"></span>
  </Container>
);
