import styled from "styled-components";

import { device } from "@styles/breakpoints";

export const MainContainer = styled.div<{ column?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: ${({ column }) => (column ? "column" : "row")};
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.bigPadding};

  &::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: ${({ theme }) => theme.opacityBlue};
    z-index: -1;
  }

  @media ${device.mobileL} {
    padding: ${({ theme }) => theme.triplePadding};
  }

  @media ${device.tablet} {
    flex-direction: column;
  }
`;
