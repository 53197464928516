import { FC } from "react";
import { useHistory } from "react-router";
import styled from "styled-components";

import { ApplicationContextValues, useApplication } from "@applications";
import { Button, ButtonTheme, IconName } from "@components";
import { useI18n } from "@i18n";
import { device } from "@styles/breakpoints";

export interface Props {
  links: {
    to: string;
    icon: IconName;
    i18nLabelKey: string;
    disabled: boolean;
  }[];
}

const buttonThemes = {
  [ApplicationContextValues.Public]: [
    ButtonTheme.Secondary,
    ButtonTheme.Primary,
    ButtonTheme.Tertiary,
    ButtonTheme.Quaternary,
  ],
  [ApplicationContextValues.DigitalOffice]: [
    ButtonTheme.Primary,
    ButtonTheme.Tertiary,
    ButtonTheme.Secondary,
    ButtonTheme.Quaternary,
  ],
};

const Container = styled.div<{ length: number }>`
  display: grid;
  grid-template-columns: ${({ length }) =>
    length === 3 ? "repeat(3, 1fr);" : "repeat(2, 1fr);"};
  gap: 25px;

  button {
    padding-left: 0;
    padding-right: 0;
    div {
      white-space: nowrap;
      svg {
        min-width: ${({ theme }) => theme.baseSize};
      }
    }
  }
  @media ${device.tablet} {
    grid-template-columns: auto;
  }
`;

export const CardButtons: FC<Props> = ({ links }) => {
  const { t } = useI18n();
  const { push } = useHistory();
  const { context } = useApplication();

  return (
    <Container length={links.length}>
      {links.map((link, index) => (
        <Button
          onClick={() => push(link.to)}
          icon={link.icon}
          key={link.to}
          theme={buttonThemes[context][index]}
          disabled={link.disabled}
        >
          {t<string>(link.i18nLabelKey)}
        </Button>
      ))}
    </Container>
  );
};
