import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Form as BaseForm, Formik, FormikProps } from "formik";
import styled from "styled-components";

import { API } from "@api";
import {
  IBrand,
  ICity,
  ICountry,
  IFamily,
  IFunctionalLoc,
  IModel,
  IUserStatus,
} from "@api/assets";
import {
  Accordion,
  Button,
  ButtonTheme,
  CheckboxGroupField,
  MultiSelect,
} from "@components";
import { DateField } from "@components/DateField";
import { useI18n } from "@i18n";

export interface Props {
  initialValues: IAssetsFilters;
  onClose: () => void;
  onApply: (values: IAssetsFilters) => void;
  onClear: () => void;
  auctionId?: number;
  withoutHeader?: boolean;
}

export interface IAssetsFilters {
  family?: string[];
  approvalDateFrom?: string;
  approvalDateTo?: string;
  acquisitionDateFrom?: string;
  acquisitionDateTo?: string;
  functionalLoc?: string[];
  brand?: string[];
  countries?: string[];
  cities?: string[];
  model?: string[];
  stateOfUse?: string[];
}

const Form = styled(BaseForm)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .main {
    overflow: scroll;
  }
`;

export const FilterAssetsForm: FC<Props> = ({
  initialValues,
  onClose,
  onClear,
  onApply,
  auctionId,
  withoutHeader,
}) => {
  const { t } = useI18n();
  const formikRef = useRef<FormikProps<IAssetsFilters> | null>(null);
  const [families, setFamilies] = useState<IFamily[]>([]);
  const [brands, setBrands] = useState<IBrand[]>([]);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [cities, setCities] = useState<ICity[]>([]);
  const [models, setModels] = useState<IModel[]>([]);
  const [functionalLocs, setFunctionalLocs] = useState<IFunctionalLoc[]>([]);
  const [userStatuses, setUserStatuses] = useState<IUserStatus[]>([]);

  useEffect(() => {
    async function getFilters() {
      const families = await API.assets.families();
      setFamilies(families);
      const brands = await API.assets.brands(auctionId);
      setBrands(brands);
      const countries = await API.assets.countries();
      setCountries(countries);
      const cities = await API.assets.cities();
      setCities(cities);
      const models = await API.assets.models(auctionId);
      setModels(models);
      const functionalLocs = await API.assets.functionalLocs(auctionId);
      setFunctionalLocs(functionalLocs);
      const userStatuses = await API.assets.userStatuses(auctionId);
      setUserStatuses(userStatuses);
    }

    getFilters();

    return () => {
      setFamilies([]);
      setBrands([]);
      setCountries([]);
      setCities([]);
      setModels([]);
      setFunctionalLocs([]);
      setUserStatuses([]);
    };
  }, [auctionId]);

  const handleMultiSelectOption = (
    id: string,
    field: string,
    existingOptions?: string[]
  ) => {
    if (existingOptions?.some((existingOptionId) => existingOptionId === id)) {
      formikRef.current?.setFieldValue(
        field,
        existingOptions?.filter((c) => c !== id)
      );
    } else {
      formikRef.current?.setFieldValue(field, existingOptions?.concat(id));
    }
  };

  const handleSubmit = useCallback(
    (values) => {
      onApply(values);
      onClose();
    },
    [onApply, onClose]
  );

  const countriesOptions = useMemo(
    () =>
      countries.map(({ country }) => ({
        id: String(country.geoname_id),
        title: country.name,
      })),
    [countries]
  );

  return (
    <Formik<IAssetsFilters>
      innerRef={formikRef}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ handleSubmit, resetForm, setValues, values }) => (
        <Form onSubmit={handleSubmit}>
          {!withoutHeader && (
            <div className="header">
              <span>{t("filters.by")}</span>
              <span className="close" onClick={onClose}>
                &#10005;
              </span>
            </div>
          )}
          <div className="main">
            <Accordion title={t("filters.assetsManagement.family")}>
              <CheckboxGroupField name="family" options={families} />
            </Accordion>
            <Accordion title={t("filters.disposalRequests.country")}>
              <MultiSelect
                noSelectedTitle="Select countries"
                selectedIds={values.countries || []}
                options={countriesOptions}
                toggleOption={(id) =>
                  handleMultiSelectOption(id, "countries", values.countries)
                }
              />
            </Accordion>
            <Accordion title={t("filters.disposalRequests.city")}>
              <MultiSelect
                noSelectedTitle="Select cities"
                disabled={!values.countries?.length}
                selectedIds={values.cities || []}
                options={cities
                  .filter(({ city }) =>
                    values.countries?.includes(String(city.country.geoname_id))
                  )
                  .map(({ city }) => ({
                    id: String(city.geoname_id),
                    title: city.name,
                  }))}
                toggleOption={(id) =>
                  handleMultiSelectOption(id, "cities", values.cities)
                }
              />
            </Accordion>
            <Accordion title={t("filters.assetsManagement.approvalDate")}>
              <DateField
                name="approvalDateFrom"
                label={t("filters.assetsManagement.approvalDateFrom")}
                placeholder={t("filters.assetsManagement.approvalDateFrom")}
              />
              <DateField
                name="approvalDateTo"
                label={t("filters.assetsManagement.approvalDateTo")}
                placeholder={t("filters.assetsManagement.approvalDateTo")}
              />
            </Accordion>
            <Accordion title={t("filters.assetsManagement.acquisitionDate")}>
              <DateField
                name="acquisitionDateFrom"
                label={t("filters.assetsManagement.acquisitionDateFrom")}
                placeholder={t("filters.assetsManagement.acquisitionDateFrom")}
              />
              <DateField
                name="acquisitionDateTo"
                label={t("filters.assetsManagement.acquisitionDateTo")}
                placeholder={t("filters.assetsManagement.acquisitionDateTo")}
              />
            </Accordion>
            <Accordion title={t("filters.assetsManagement.functionalLoc")}>
              <CheckboxGroupField
                name="functionalLoc"
                options={functionalLocs.map(({ functional_loc }) => ({
                  label: functional_loc,
                  value: functional_loc,
                }))}
              />
            </Accordion>
            <Accordion title={t("filters.assetsManagement.brand")}>
              <CheckboxGroupField
                name="brand"
                options={brands.map(({ brand }) => ({
                  label: brand,
                  value: brand,
                }))}
              />
            </Accordion>
            <Accordion title={t("filters.assetsManagement.model")}>
              <CheckboxGroupField
                name="model"
                options={models.map(({ model }) => ({
                  label: model,
                  value: model,
                }))}
              />
            </Accordion>
            <Accordion title={t("filters.assetsManagement.stateOfUse")}>
              <CheckboxGroupField
                name="stateOfUse"
                options={userStatuses.map(({ user_status }) => ({
                  label: user_status,
                  value: user_status,
                }))}
              />
            </Accordion>
          </div>
          <div className="footer">
            <Button
              type="button"
              className="bordered"
              theme={ButtonTheme.Secondary}
              onClick={() => {
                const defaultValues: IAssetsFilters = {
                  acquisitionDateFrom: undefined,
                  acquisitionDateTo: undefined,
                  approvalDateFrom: undefined,
                  approvalDateTo: undefined,
                  brand: undefined,
                  family: undefined,
                  functionalLoc: undefined,
                  model: undefined,
                  stateOfUse: undefined,
                };
                resetForm();
                setValues(defaultValues);
                onApply(defaultValues);
                onClose();
                onClear();
              }}
            >
              {t<string>("filters.clear")}
            </Button>
            <Button
              type="submit"
              theme={ButtonTheme.Secondary}
              onClick={() => null}
            >
              {t<string>("filters.apply")}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
